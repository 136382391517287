import { Timestamp } from "firebase/firestore";

import {
  addDocumentToFirestore,
  createDocumentWithID,
  getDocumentFromFirestore,
  updateDocumentInFirestore,
} from "./Crud";

export interface Project {
  deleted?: boolean;
  pinned?: boolean;
  showDetails?: boolean;
  showReminders?: boolean;
  systemCreated?: boolean;
  done?: boolean;
  name: string;
  owner?: string;
  id?: string;
  created: Timestamp;
  updated: Timestamp;
  home?: boolean;
  order?: any[]; // Specify more precise type here if possible
}

function toJson(project: Project) {
  return {
    deleted: project.deleted || false,
    pinned: project.pinned || false,
    showDetails: project.showDetails === undefined ? true : project.showDetails,
    showReminders:
      project.showReminders === undefined ? true : project.showReminders,
    systemCreated: project.systemCreated || false,
    done: project.done || false,
    name: project.name || "",
    owner: project.owner || "",
    created: project.created ? project.created.toDate() : new Date(),
    updated: project.updated ? project.updated.toDate() : new Date(),
    home: project.home || false,
    order: project.order || [],
  };
}
export function fromJson(json: Project, sDocID: string): Project {
  return {
    name: json["name"],
    done: json["done"],
    home: json["home"],
    deleted: json["deleted"],
    systemCreated: json["systemCreated"] || false,
    showDetails: json["showDetails"] === undefined ? true : json["showDetails"],
    showReminders:
      json["showReminders"] === undefined ? true : json["showReminders"],
    owner: json["owner"],
    order: json["order"] || [""],
    pinned: json["pinned"] || false,
    id: sDocID,
    created: json.created ?? new Date(),
    updated: json.updated ?? new Date(),
  };
}

export type ProjectUpdate = Partial<Project>;

// Function to create a Home Project
export const createHomeProject = async (userID: string): Promise<string> => {
  const p: Project = {
    deleted: false,
    done: false,
    name: "Home",
    home: true,
    created: Timestamp.now(),
    updated: Timestamp.now(),
    owner: userID,
    systemCreated: true,
  };

  return createProject(p, userID);
};

export const createProject = async (
  project: Project,
  userID: string
): Promise<string> => {
  return addDocumentToFirestore(toJson(project), `users/${userID}/projects`);
};

export const verifyCalendarProjectExists = async (
  projectID: string,
  userID: string
): Promise<string> => {
  const document = await getDocumentFromFirestore(
    `users/${userID}/projects`,
    projectID
  );
  if (document) return projectID;
  else {
    console.log("create project");
    let project: Project = {
      name: "",
      done: false,
      home: false,
      deleted: false,
      systemCreated: true,
      owner: userID,
      created: Timestamp.now(),
      updated: Timestamp.now(),
    };
    createDocumentWithID(project, `users/${userID}/projects`, projectID).then(
      (docID) => {
        return projectID;
      }
    );
  }
  return projectID;
};

export const updateProjectOrder = async (
  order: string[],
  userID: string,
  activeProject: string
) => {
  if (activeProject === "calendar") {
    verifyCalendarProjectExists(activeProject, userID).then((projectID) => {
      updateProject(userID, projectID, { order: order });
    });
  }

  if (activeProject !== "calendar") {
    updateProject(userID, activeProject, { order: order });
  }
};

export const updateProjectName = async (
  userID: string,
  activeProjectID: string,
  projectName: string
) => {
  updateProject(userID, activeProjectID, { name: projectName });
};

export const deleteProject = async (userID: string, projectID: string) => {
  updateProject(userID, projectID, { deleted: true });
};

export const updateProjectShowDetails = async (
  userID: string,
  activeProjectID: string,
  showDetails: boolean
) => {
  updateProject(userID, activeProjectID, { showDetails: showDetails });
};

export const updateProjectShowReminders = async (
  userID: string,
  activeProjectID: string,
  showReminders: boolean
) => {
  updateProject(userID, activeProjectID, { showReminders: showReminders });
};

export const updateProjectPinned = async (
  userID: string,
  activeProjectID: string,
  pinned: boolean
) => {
  updateProject(userID, activeProjectID, { pinned: pinned });
};

const updateProject = async (
  userID: string,
  projectID: string,
  doc: ProjectUpdate
) => {
  if (!userID || !projectID) {
    return;
  }

  doc.updated = Timestamp.now();

  try {
    await updateDocumentInFirestore(doc, `users/${userID}/projects`, projectID);
    //   console.log("Order updated in Firestore");
  } catch (error) {
    console.error("Error updating order in Firestore", error);
  }
};
