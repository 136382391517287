export const preferNotToSayName = 1;
export const skipToApp = 2;
export const setupReminders = 3;

export const oneTimeReminder = 4;
export const dailyReminder = 5;
export const weeklyReminder = 6;
export const monthlyReminder = 7;
export const yearlyReminder = 8;

export const today = 9;
export const tomorrow = 10;
export const customDate = 11;

export const nineInMorning = 12;
export const sevenInEvening = 13;
export const customTime = 14;

export const skipReminder = 15;

export const dailyReminderYesWeekends = 16;
export const dailyReminderNoWeekends = 17;

export const sunday = 18;
export const monday = 19;
export const tuesday = 20;
export const wednesday = 21;
export const thursday = 22;
export const friday = 23;
export const saturday = 24;

export const lastDay = 25;

export const reminderExample = 26;

export interface Suggestion {
  text: string;
  suggestionID: number;
  prominent?: boolean;
  backButton?: boolean;
  exampleText?: string;
}

export class ChatSuggestion implements Suggestion {
  text: string;
  suggestionID: number;
  prominent?: boolean;
  backButton?: boolean;
  exampleText?: string;

  constructor({
    text,
    suggestionID,
    exampleText,
    prominent,
    backButton,
  }: {
    text: string;
    suggestionID: number;
    exampleText?: string;
    prominent?: boolean;
    backButton?: boolean;
  }) {
    this.text = text;
    this.suggestionID = suggestionID;
    this.exampleText = exampleText;
    this.prominent = prominent;
    this.backButton = backButton;
  }
}
