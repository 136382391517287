import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../Context/Color";
import { Project, createProject } from "../../../Data/Project";
import ProjectRow from "../../Molecules/ProjectRow/ProjectRow";
import { TaskCounts } from "../../../Util/Count";
import { Timestamp } from "firebase/firestore";

import "./Style.css";

import UserDetails from "../UserDetails/UserDetails";

import Icon from "../../Atoms/Icon/Icon";
import Button from "../../Atoms/Button/Button";
import { useOverlay } from "../../../Context/OverlayProvider";
import EditDlg from "../EditDlg/EditDlg";
import { useNavigate } from "react-router-dom";

type SideBarProps = {
  onProjectClick: () => void;
  onListCreate: () => void;
  hiddenMode: boolean;
  isAdmin: boolean;
  showUpcoming: boolean;
  showToday: boolean;
  userID: string;
  onUserClick: () => void;
  projects: Project[];
  isMobile: boolean;
  userPicture?: string | null;
};

const Sidebar = (props: SideBarProps) => {
  const { addOverlay, removeOverlay } = useOverlay();
  const navigate = useNavigate();

  const [hiddenMode, setHiddenMode] = useState(props.hiddenMode);

  useEffect(() => {
    setHiddenMode(props.hiddenMode);
  }, [props.hiddenMode]);

  const taskCounts = TaskCounts.instance;

  const themeContext = useContext(ThemeContext);

  if (!themeContext) {
    throw new Error("ThemeContext not provided from a ThemeProvider component");
  }

  const addProject = async (name: string) => {
    const p: Project = {
      name: name,
      created: Timestamp.now(),
      updated: Timestamp.now(),
      owner: props.userID,
    };
    let listID = await createProject(p, props.userID);
    removeOverlay();
    navigate(`/list/${listID}`);
    props.onListCreate();
  };

  const onNewList = () => {
    addOverlay(
      <EditDlg
        header="List Name"
        placeHolder=""
        isMobile={props.isMobile}
        saveBtnText="Add List"
        allowEmptyValue={false}
        maxLength={150}
        expandVertical={true}
        initialValue={""}
        cancelBtnText="Cancel"
        emailValidation={false}
        headerSize={24}
        onSave={(listName: string) => {
          addProject(listName);
        }}
        onCancel={() => {
          removeOverlay();
        }}
      />,
      {
        closeOnClickOutside: false,
        centerX: true,

        width: "100%",
        marginTop: 8,
      }
    );
  };

  return (
    <div
      style={{
        width: hiddenMode ? "0%" : props.isMobile ? "100%" : "400px",

        backgroundColor: themeContext.sidbarBackground,
        borderRight: "1px solid " + themeContext?.deviderColor,
        transition: "width 0.1s ease",
        color: themeContext.sidebarRows,
      }}
      className="sidebar"
    >
      {props.isMobile && !hiddenMode && (
        <div className="user_div">
          <UserDetails
            width={"100%"}
            fontSize={"20px"}
            onUserClick={props.onUserClick}
            picture={props.userPicture}
          />
        </div>
      )}
      <div
        style={{
          display: "flex",
          marginTop: props.isMobile ? "6px" : "10px",
          marginBottom: "10px",
          padding: hiddenMode ? "10px" : "10px",
        }}
      >
        <Button
          id="new_list_btn"
          backgroundColor={
            themeContext.isDarkTheme ? themeContext.activeItemColor : ""
          }
          padding="12px"
          fontSize="20px"
          actionTip="Create a new list"
          actionTipPosition="right"
          iconComponent={<Icon fontSize={24} editIcon={true} />}
          textButton={false}
          onClick={() => {
            onNewList();
          }}
          label={hiddenMode ? "" : "New List"}
        />
      </div>
      <div className="sidebar_btns">
        <div className="projects">
          {props.projects.map((project, index) => {
            if (project.home) {
              return (
                <ProjectRow
                  marginBottom={false}
                  icon={<Icon fontSize={24} homeIcon={true} />}
                  onProjectClick={props.onProjectClick}
                  projectID={project.id!}
                  projectName={hiddenMode ? "" : project.name}
                  count={taskCounts.getCount(project.id!)}
                  showCount={!hiddenMode}
                  key={project.id}
                  isHomeProject={true}
                  isUpcomingProject={false}
                  isTodayProject={false}
                />
              );
            }
            return null;
          })}
          {props.showToday && (
            <ProjectRow
              marginBottom={!props.showUpcoming}
              icon={<Icon fontSize={24} todayIcon={true} />}
              projectID={"calendar"}
              projectName={"Today"}
              count={taskCounts.getCount("today")}
              showCount={true}
              onProjectClick={props.onProjectClick}
              isHomeProject={false}
              isUpcomingProject={false}
              isTodayProject={true}
            />
          )}

          {props.showUpcoming && (
            <ProjectRow
              marginBottom={true}
              icon={<Icon fontSize={24} dateIcon={true} />}
              projectID={"calendar"}
              projectName={"Upcoming"}
              count={taskCounts.getCount("upcoming")}
              showCount={true}
              onProjectClick={props.onProjectClick}
              isHomeProject={false}
              isUpcomingProject={true}
              isTodayProject={false}
            />
          )}

          {props.projects.map((project, index) => {
            if (!project.home && project.id !== "calendar") {
              return (
                <ProjectRow
                  marginBottom={false}
                  icon={
                    project.pinned ? (
                      <Icon fontSize={24} pinIcon={true} />
                    ) : (
                      <Icon fontSize={24} listIcon={true} />
                    )
                  }
                  onProjectClick={props.onProjectClick}
                  projectID={project.id!}
                  projectName={hiddenMode ? "" : project.name}
                  count={taskCounts.getCount(project.id!)}
                  showCount={!hiddenMode}
                  key={project.id}
                  isHomeProject={false}
                  isUpcomingProject={false}
                  isTodayProject={false}
                />
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
