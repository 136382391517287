import { useOverlay } from "../../../Context/OverlayProvider";

import Dialog from "../Dialog/Dialog";
import { Project } from "../../../Data/Project";

import Icon from "../../Atoms/Icon/Icon";
import { TaskCounts } from "../../../Util/Count";
import Button from "../../Atoms/Button/Button";
import { ThemeContext } from "../../../Context/Color";
import { useContext } from "react";
type MoveDlgProps = {
  projects: Project[];
  taskName: String;
  listSelect: boolean;
  taskID: String;
  projectID: String;
  projectName: String;
  isMobile: boolean;
  onProjectClick: (projectID: string, projectName: string) => void;
};

const MoveDlg = (props: MoveDlgProps) => {
  const taskCounts = TaskCounts.instance;
  const themeContext = useContext(ThemeContext);

  const { removeOverlay } = useOverlay();

  let all_projects = [...props.projects];

  all_projects = all_projects.filter(
    (project) => project.id !== "calendar"
  );

  const orderedProjects = all_projects.sort((a, b) =>
    a.id === props.projectID ? -1 : b.id === props.projectID ? 1 : 0
  );

  return (
    <Dialog
      closeBar={true}
      isMobile={props.isMobile}
      saveCancel={false}
      onClose={() => {
        removeOverlay();
      }}
    >
      <div
        style={{
          display: "flex",
          fontWeight: "bold",
          flexDirection: "column",
          textAlign: "center",
          color: themeContext?.textColor,
          fontSize: "20px",
          fontFamily: "Calibri",
          marginBottom: "14px",
        }}
      >
        <div>{props.listSelect ? "Select List" : "Move to..."}</div>
      </div>
      <div
        style={{ fontSize: "18px", fontFamily: "Calibri" }}
        className="projects"
      >
        {orderedProjects.map((project, index) => (
          <div key={project.id} style={{ padding: "12px" }}>
            {" "}
            <Button
              id={project.id!}
              iconComponent={
                <Icon
                  listIcon={
                    project.id !== props.projectID &&
                    !project.home &&
                    !project.pinned
                  }
                  pinIcon={project.pinned && project.id !== props.projectID}
                  doneIcon={project.id === props.projectID}
                  homeIcon={project.home}
                />
              }
              label={
                project.name + " (" + taskCounts.getCount(project.id!) + ")"
              }
              textButton={true}
              onClick={() => {
                props.onProjectClick(project.id!, project.name);
              }}
            />
          </div>
        ))}
      </div>
    </Dialog>
  );
};

export default MoveDlg;
