import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import Overlay from "../UI/Organizm/Ovleray/Overlay";

interface OverlayContextType {
  addOverlay: (
    component: ReactNode,
    props: OverylayCustomProps,
    append?: boolean,
    update?: boolean,
    overlayID?: string
  ) => void;
  removeOverlay: () => void;
  isActiveOverlay: () => boolean;
}

export type OverylayCustomProps = {
  centerX: boolean;
  width?: string;
  height?: string;
  maxHeight?: string;
  hideScrollbar?: boolean;
  closeOnClickOutside: boolean;
  marginTop?: number;
  marginBottom?: number;
  onClose?: () => void;
};

const OverlayContext = createContext<OverlayContextType | undefined>(undefined);

export const useOverlay = () => {
  const context = useContext(OverlayContext);
  if (!context) {
    throw new Error("useOverlay must be used within an OverlayProvider");
  }
  return context;
};

export const OverlayProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [overlays, setOverlays] = useState<
    { id: string; component: ReactNode; props: OverylayCustomProps }[]
  >([]);

  useEffect(() => {
    // Check if we should be listening to "Escape" at all
    if (overlays.length === 0) return;

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape" && overlays.length > 0) {
        const frontmostOverlay = overlays[overlays.length - 1];
        if (frontmostOverlay.props.closeOnClickOutside) {
          removeOverlay();
          if (frontmostOverlay.props.onClose) frontmostOverlay.props.onClose();
        }
      }
    };

    // Only add the listener if there are overlays present
    document.addEventListener("keydown", onKeyDown);

    // Cleanup: remove the listener when the component is unmounted or when there are no overlays
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overlays]);

  const addOverlay = (
    component: ReactNode,
    props: OverylayCustomProps,
    append: boolean = false,
    update: boolean = false,
    overlayID: string = ""
  ) => {
    if (update) {
      updateOverlay(component, props, overlayID);
      return;
    } else {
      const newOverlay = { id: overlayID, component, props };
      if (append) {
        setOverlays((currentOverlays) => [...currentOverlays, newOverlay]);
      } else {
        setOverlays([newOverlay]);
      }
    }
  };

  const isActiveOverlay = () => {
    return overlays.length > 0;
  };

  const removeOverlay = () => {
    setOverlays((currentOverlays) => {
      if (currentOverlays.length > 0) {
        return currentOverlays.slice(0, currentOverlays.length - 1);
      }
      return currentOverlays;
    });
  };

  const updateOverlay = (
    component: ReactNode,
    props: OverylayCustomProps,
    overlayID: string
  ) => {
    setOverlays((currentOverlays) => {
      const updatedOverlays = [...currentOverlays];
      const indexToUpdate = updatedOverlays.findIndex(
        (overlay) => overlay.id === overlayID
      );
      if (indexToUpdate !== -1) {
        updatedOverlays[indexToUpdate] = { id: overlayID, component, props };
      }
      return updatedOverlays;
    });
  };

  return (
    <OverlayContext.Provider
      value={{ addOverlay, removeOverlay, isActiveOverlay }}
    >
      {children}
      {overlays.map((overlay, index) => (
        <Overlay
          key={index}
          open={true}
          {...overlay.props}
          onClose={() => {
            removeOverlay();
            if (overlay.props.onClose) overlay.props.onClose();
          }}
        >
          {overlay.component}
        </Overlay>
      ))}
    </OverlayContext.Provider>
  );
};
