import React from "react";

interface DayPickerProps {
  selectedDay: number | string; // Allow selectedDay to be number or string
  onChange: (day: number, lastDay: boolean) => void;
}

const DayPicker = (props: DayPickerProps) => {
  // Create an array of days as numbers and add "Last Day" as a string
  const days: (number | string)[] = Array.from({ length: 31 }, (_, i) => i + 1);
  days.push("Last Day"); // Push "Last Day" as a string

  return (
    <select
      value={props.selectedDay}
      onChange={(e) => {
        props.onChange(
          parseInt(e.target.value, 10),
          e.target.value === "Last Day"
        );
      }}
    >
      {days.map((day, index) => (
        <option key={index} value={day}>
          {day}
        </option>
      ))}
    </select>
  );
};

export default DayPicker;
