import React from "react";

import "./App.css";
import { AuthProvider } from "./Context/Auth";

import { ThemeProvider } from "./Context/Color";
import Background from "./Pages/Background/Background";
import Router from "./Routing/Router";
import { DataProvider } from "./Context/Data";
import { OverlayProvider } from "./Context/OverlayProvider";
import { SnackbarProvider } from "./Context/SnackbarProvider";

function App() {
  return (
    <AuthProvider>
      <DataProvider>
        <ThemeProvider>
          <SnackbarProvider>
            <OverlayProvider>
              <Background>
                <Router></Router>
              </Background>
            </OverlayProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </DataProvider>
    </AuthProvider>
  );
}

export default App;
