import { ChatSuggestion } from "../../../Data/Suggestion";
import { ChatColors } from "../../../Util/chatColors";
import Button from "../../Atoms/Button/Button";

type TipProps = {
  suggestion: ChatSuggestion;
  colors: ChatColors;
  onClick: (suggestion: ChatSuggestion) => void;
};

const ChatTip = (props: TipProps) => {
  return (
    <div
      style={{
        border: "1px solid lightgray",
        borderRadius: "8px",
        color: props.suggestion.prominent
          ? props.colors.prominentTextColor
          : "",
        backgroundColor: props.suggestion.prominent
          ? props.colors.prominentColor
          : "",
      }}
    >
      <Button
        padding="8px"
        textButtonColor={
          props.suggestion.prominent
            ? props.colors.prominentTextColor
            : undefined
        }
        id={props.suggestion.text}
        label={props.suggestion.text}
        subLabel={
          props.suggestion.exampleText
            ? props.suggestion.exampleText
            : undefined
        }
        textButton={true}
        onClick={() => {
          props.onClick(props.suggestion);
        }}
      />
    </div>
  );
};

export default ChatTip;
