import React, { useContext } from "react";
import { Reminder, formatReminderString } from "../../../Data/Reminder";
import Icon from "../../Atoms/Icon/Icon";
import { ThemeContext } from "../../../Context/Color";
import { Task } from "../../../Data/Task";

type RemindersRowProps = {
  reminders: Reminder[];
  userTimeZone: string;
  task: Task;
};

const RemindersRow = (props: RemindersRowProps) => {
  const themeContext = useContext(ThemeContext);

  let reminders = props.reminders;

  reminders.sort(function (a, b) {
    return a.time.toMillis() - b.time.toMillis();
  });

  const hasTaskPassed = (): boolean => {
    const today = new Date();
    const taskDate = props.task.dueDate?.toDate();
    if (!taskDate) return true;
    if (!props.task.dueDateWithTime) {
      today.setHours(0, 0, 0, 0);
      taskDate?.setHours(0, 0, 0, 0);
    }
    return taskDate < today;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        fontSize: "16px",
        color: themeContext?.textColor,
        marginTop: "6px",
      }}
    >
      {!hasTaskPassed() &&
        props.task.dueDate &&
        props.task.dueDateWithTime &&
        props.task.dueDateReminderOnTime && (
          <div style={{ display: "flex" }}>
            <Icon alarmIcon={true} />
            <div style={{ marginLeft: "6px" }}>Exactly on time</div>
          </div>
        )}
      {!hasTaskPassed() &&
        props.task.dueDate &&
        props.task.dueDateWithTime &&
        props.task.dueDateReminderMinutesBefore && (
          <div style={{ display: "flex" }}>
            <Icon alarmIcon={true} />
            <div style={{ marginLeft: "6px" }}>
              {props.task.dueDateReminderMinutes} minutes before
            </div>
          </div>
        )}
      {!hasTaskPassed() &&
        props.task.dueDate &&
        props.task.dueDateWithTime &&
        props.task.dueDateReminderHoursBefore && (
          <div style={{ display: "flex" }}>
            <Icon alarmIcon={true} />
            <div style={{ marginLeft: "6px" }}>
              {props.task.dueDateReminderHours} hours before
            </div>
          </div>
        )}
      {!hasTaskPassed() &&
        props.task.dueDate &&
        props.task.dueDateReminderDaysBefore && (
          <div style={{ display: "flex" }}>
            <Icon alarmIcon={true} />
            <div style={{ marginLeft: "6px" }}>
              {props.task.dueDateReminderDays} days before
            </div>
          </div>
        )}
      {reminders.map((reminder, index) => (
        <div style={{ display: "flex" }} key={reminder.id}>
          <Icon alarmIcon={true} />
          <div style={{ marginLeft: "6px" }} key={index}>
            {formatReminderString(reminder, true, props.userTimeZone)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default RemindersRow;
