import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";

type TableProps = {
  headers: string[];
  data: any[][];
  selection: boolean;
  onRowSelected?: (rowIndex: number) => void;
};

export default function BasicTable(props: TableProps) {
  const [selectedRows, setSelectedRows] = React.useState<number[]>([]);
  const [maxHeight, setMaxHeight] = React.useState<string | undefined>(
    undefined
  );

  const tableRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function handleResize() {
      if (tableRef.current) {
        const tableRect = tableRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const tableTopOffset = tableRect.top;
        const newMaxHeight = viewportHeight - tableTopOffset - 50; // Adjust this offset as needed
        setMaxHeight(`${newMaxHeight}px`);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleRowSelect = (rowIndex: number) => {
    const selectedIndex = selectedRows.indexOf(rowIndex);
    const newSelected = [...selectedRows];

    if (selectedIndex === -1) {
      newSelected.push(rowIndex);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelectedRows(newSelected);
    props.onRowSelected && props.onRowSelected(rowIndex);
  };

  React.useEffect(() => {
    setSelectedRows([]);
  }, [props.data.length]);

  const isSelected = (rowIndex: number) => {
    return selectedRows.indexOf(rowIndex) !== -1;
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: maxHeight, maxWidth: "100%", marginBottom: "100px" }}
      ref={tableRef}
    >
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {props.selection && <TableCell padding="checkbox"></TableCell>}
            {props.headers.map((header, index) => (
              <TableCell
                align="left"
                key={index}
                style={{ fontWeight: "bold" }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              selected={isSelected(rowIndex)}
              onClick={() => handleRowSelect(rowIndex)}
              style={{ cursor: "pointer" }}
            >
              {props.selection && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={isSelected(rowIndex)}
                    inputProps={{
                      "aria-label": "select dessert",
                    }}
                  />
                </TableCell>
              )}
              {row.map((cell, cellIndex) => (
                <TableCell align="left" key={cellIndex}>
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
