import React, {
  ReactNode,
  useState,
  useContext,
  useRef,
  useEffect,
} from "react";
import {
  DocumentData,
  QueryDocumentSnapshot,
  clearIndexedDbPersistence,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  terminate,
  where,
} from "firebase/firestore";
import {
  Task,
  createTrainingTasks,
  fromJson as fromJsonTask,
} from "../Data/Task";
import { db, getFCMToken } from "../firebase";
import {
  Project,
  fromJson as fromJsonProject,
  createHomeProject,
  deleteProject,
  verifyCalendarProjectExists,
} from "../Data/Project";
import {
  UserData,
  fromJson as fromJsonUser,
  getPlatform,
  updateUserLastActivity,
  updateUserPlatform,
} from "../Data/User";
import {
  Reminder,
  createDummyReminder,
  fromJson as fromJsonReminder,
} from "../Data/Reminder";
import { TaskCounts } from "../Util/Count";
import { initFS } from "../firebase";
import { useAuth } from "./Auth";
import { LogData } from "../Data/Log";
import { DeviceData, createDevice } from "../Data/Device";

import { updateProjectOrder } from "../Data/Project";
import { DueDateReminder } from "../Data/DueDateReminder";

declare global {
  function gtag_report_conversion(): void;
}

type UserDB = {
  tasks: Task[];
  projects: Project[];
  reminders: Reminder[];
  devices: DeviceData[];
};

const DataContext = React.createContext<{
  getTasks: () => Task[];
  getAllTasks: () => Task[];
  getAllReminders: () => Reminder[];
  addTempTask: (t: Task) => void;
  getTempTask: () => Task | null;
  removeTempTask: () => void;
  isTodayView: () => boolean;
  isUpcomingView: () => boolean;
  setCancelDailyAgenda: (cancel: boolean) => void;
  getCancelDailyAgenda: () => boolean;
  isCalendarTaskOnTop: (taskDate: Date, dueDateWithTime: boolean) => boolean;
  getCompletedTasks: () => Task[];
  getUser: () => UserData | undefined;
  getUsers: () => Promise<UserData[]>; // Change the return type to a Promise
  getLogs: (daysBack: number) => Promise<LogData[]>; // Change the return type to a Promise
  getTaskReminders: (taskID: String) => Reminder[];
  getTaskDueDateReminders: (taskID: String) => DueDateReminder;
  checkUserPlatform: (userID: string) => Promise<String>;
  getUserLastActivity: (userID: string) => Promise<Date>;
  getProjectName: () => string;
  getTaskName: () => string;
  getHomeProjectID: () => string;
  onSignOut: () => void;
  moveTaskToTop: (taskID: string, userID: string) => void;
  getUserCounts: (
    userID: string
  ) => Promise<{ remindersSize: number; listsSize: number; tasksSize: number }>;
  getToken: () => string;
  getActiveProjectOrder: () => any[];
  getProjectOrder: (id: string) => any[];
  isDataReady: () => boolean;
  isNewSession: () => boolean;
  getLists: () => Project[];
  getActiveProjectObject: () => Project | null;
  fetchLists: (userID: string) => Promise<String>;
  getTaskOnline: (userID: string, taskID: string) => Promise<Task | null>;
  getUserData: (userID: string) => Promise<UserDB>;
  getActiveProject: () => String; // Added this line
  getActiveTask: () => String; // Added this line
  getActiveTaskRecord: () => Task | null;
  setActiveTaskRecord: (t: Task) => void;
  getDebugMsg: () => String;
  setActiveProject: (projectID: String) => void; // Added this line
  setActiveTask: (taskID: String) => void;
}>({
  getTasks: () => [],
  setCancelDailyAgenda: () => [],
  getAllTasks: () => [],
  getAllReminders: () => [],
  getCompletedTasks: () => [],
  getTaskReminders: () => [],
  getActiveProjectOrder: () => [],
  getProjectOrder: () => [],
  onSignOut: () => {},
  isCalendarTaskOnTop: (taskDate: Date) => {
    return false;
  },
  moveTaskToTop: () => {},
  isTodayView: () => {
    return false;
  },
  getCancelDailyAgenda: () => {
    return false;
  },
  isUpcomingView: () => {
    return false;
  },
  addTempTask: () => {},
  setActiveTaskRecord: () => {},
  getActiveTaskRecord: () => {
    return null;
  },
  getTempTask: () => {
    return null;
  },
  getTaskDueDateReminders: () => {
    return new DueDateReminder();
  },
  getToken: () => "",
  getUsers: async () => [],
  getLogs: async () => [],
  removeTempTask: () => {},
  getLists: () => [],
  getActiveProjectObject: () => null,
  fetchLists: () => Promise.resolve(""),
  checkUserPlatform: () => Promise.resolve(""),
  getUserCounts: (userID: string) =>
    Promise.resolve({ remindersSize: 0, listsSize: 0, tasksSize: 0 }),
  getUserLastActivity: () => Promise.resolve(new Date()),
  getTaskOnline: () => {
    return Promise.reject("getTaskOnline method not implemented");
  },
  getUserData: async (userID: string) => {
    // You should replace this with actual logic to fetch user data
    // For now, let's assume you have some mock data
    const mockUserData: UserDB = {
      tasks: [],
      projects: [],
      reminders: [],
      devices: [],
    };

    return Promise.resolve(mockUserData);
  },
  getActiveProject: () => "",
  getActiveTask: () => "",
  getDebugMsg: () => "",
  getProjectName: () => "",
  getTaskName: () => "",
  getHomeProjectID: () => "",
  getUser: () => undefined, // provide a dummy value
  isDataReady: () => false,
  isNewSession: () => false,
  setActiveProject: () => {},
  setActiveTask: () => {},
});

export function useData() {
  return useContext(DataContext);
}

export function DataProvider({ children }: { children: ReactNode }) {
  const [tasks, setTasks] = useState<Task[] | null>(null);
  const [reminders, setReminders] = useState<Reminder[]>([]);
  const [userID, setUserID] = useState("");
  const [todayView, setTodayView] = useState(false);
  const [upcomingView, setUpcomingView] = useState(false);
  const [homeProjectID, setHomeProjectID] = useState("");
  const [userData, setUserData] = useState<UserData>();
  const [dataReady, setDataReady] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [noProjects, setNoProjects] = useState(false);
  const unsubscribeTasksRef = useRef<(() => void) | null>(null);
  const unsubscribeProjectsRef = useRef<(() => void) | null>(null);
  const unsubscribeReminderRef = useRef<(() => void) | null>(null);
  const unsubscribeUserRef = useRef<(() => void) | null>(null);

  const [dataFetched, setDataFetched] = useState(false);
  const [cancelAgenda, setCancelAgenda] = useState(false);

  const homeProjecFound = useRef(false);
  const sessionStarted = useRef(false);

  const auth = useAuth();

  const [token, setToken] = useState("");

  const [tempTask, setTempTask] = useState<Task | null>(null);

  const [activeTaskObject, setActiveTaskObject] = useState<Task | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [debugMsg, setDebugMsg] = useState("");

  const [activeProject, setProject] = useState<String | null>(null);
  const [activeTask, setTask] = useState<String | null>(null);

  const getActiveTaskRecord = (): Task | null => {
    return activeTaskObject;
  };

  const setActiveTaskRecord = (t: Task): void => {
    setActiveTaskObject(t);
  };

  const isCalendarTaskOnTop = (
    taskDate: Date,
    dueDateWithTime: boolean
  ): boolean => {
    let taskOnTop: number = 0;
    if (!dueDateWithTime) taskDate.setHours(0, 0, 0, 0);
    tasks?.forEach((task) => {
      if (task.dueDate && task.done === false && task.deleted === false) {
        let currentDate = task.dueDate.toDate();
        if (!task.dueDateWithTime) {
          currentDate.setHours(0, 0, 0, 0);
        }
        if (taskDate > task.dueDate.toDate()) taskOnTop++;
      }
    });

    return taskOnTop < 3;
  };

  const setActiveProject = (projectID: String) => {
    if (projectID === "upcoming") {
      setTodayView(false);
      setUpcomingView(true);
      projectID = "calendar";
    } else if (projectID === "today") {
      setTodayView(true);
      setUpcomingView(false);
      projectID = "calendar";
    } else {
      setTodayView(false);
      setUpcomingView(false);
    }
    setProject(projectID);
  };

  const setActiveTask = (taskID: String) => {
    setTask(taskID);
  };

  useEffect(() => {
    const fetchData = async () => {
      setUserID(auth.authGetUID());
      if (auth.authGetUID() !== "") {
        getFCMToken()
          .then((token) => {
            if (token !== "" && token !== null) {
              setToken(token);
              createDevice(token, auth.authGetUID());
            }
          })
          .catch((error) => {
            // Handle error if needed
            console.error("Error getting FCM token:", error);
          });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.authGetUID]);

  useEffect(() => {
    if (
      tasks !== null &&
      projects.length > 0 &&
      userData !== null &&
      dataFetched
    ) {
      if (activeProject === "home") setActiveProject(getHomeProjectID());
      else if (activeProject !== "home" && activeProject !== "")
        setDataReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, projects, userData, reminders, activeProject, dataFetched]);

  useEffect(() => {
    if (userID !== "") {
      sessionStarted.current = true;
      // Start all three fetch functions
      const taskPromise = fetchTasks(userID);
      const reminderPromise = fetchReminders(userID);
      const userPromise = fetchUser(userID);
      const listPromose = fetchLists(userID);

      let dataPromises: Promise<any>[] = [
        taskPromise,
        reminderPromise,
        userPromise,
        listPromose,
      ];

      // Once all three are complete, set dataReady to true
      Promise.all(dataPromises)
        .then(() => {
          updateUserLastActivity(new Date(), userID);
          updateUserPlatform(getPlatform(), userID);
          setDataFetched(true);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID]);

  useEffect(() => {
    if (!tasks) return;
    if (activeTaskObject !== null) {
      const active_task = tasks.filter(
        (task) => task.id === activeTaskObject.id
      );

      if (active_task.length === 1 && active_task[0])
        setActiveTaskObject(active_task[0]);
      else if (activeTaskObject !== null && !activeTaskObject.deleted) {
        let t = { ...activeTaskObject };
        t.deleted = true;

        setActiveTaskObject(t);
      }
    }
  }, [dataReady, activeTaskObject, tasks]);

  useEffect(() => {
    if (
      (noProjects || projects.length > 0) &&
      userID !== "" &&
      userID !== null
    ) {
      if (homeProjecFound.current === false) findHomeProject();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noProjects, projects, userID]);

  const getActiveProject = (): String => {
    if (activeProject === null) return "";
    return activeProject;
  };

  const getActiveTask = (): String => {
    if (activeTask === null) return "";
    return activeTask;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const adminFindTask = async (
    taskID: string,
    userID: string
  ): Promise<string> => {
    //await getDocumentFromFirestore();
    return Promise.resolve("");
  };

  const addTempTask = (t: Task) => {
    if (!todayView && !upcomingView) setTempTask(t);
  };

  const getTempTask = (): Task | null => {
    return tempTask;
  };

  const removeTempTask = (): void => {
    setTempTask(null);
  };

  useEffect(() => {
    // Cleanup function

    return () => {
      if (unsubscribeTasksRef.current) {
        unsubscribeTasksRef.current();
      }

      if (unsubscribeProjectsRef.current) {
        unsubscribeProjectsRef.current();
      }

      if (unsubscribeReminderRef.current) {
        unsubscribeReminderRef.current();
      }

      if (unsubscribeUserRef.current) {
        unsubscribeUserRef.current();
      }
    };
  }, []);

  const fetchLists = (userID: string): Promise<String> => {
    setUserID(userID);
    return new Promise<String>((resolve, reject) => {
      // const db = getFirestore();

      const userRef = doc(db, "users", userID);
      const colRef = collection(userRef, "projects");
      const q = query(
        colRef,
        orderBy("name", "asc"),
        where("deleted", "==", false)
      );

      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const newProjects = querySnapshot.docs.map((doc) => {
            return fromJsonProject(doc.data() as Project, doc.id);
          });

          if (newProjects.length === 0) {
            setNoProjects(true);
          }

          setProjects(newProjects);

          resolve(""); // Resolve the promise when the tasks are fetched and set
        },
        (error) => {
          console.log(error);
          reject(error); // Reject the promise if there is an error during the fetch
        }
      );

      // Optional: Clean up subscription on unmount
      unsubscribeProjectsRef.current = unsubscribe;
    });
  };

  const getTaskOnline = (
    userID: string,
    taskID: string
  ): Promise<Task | null> => {
    // Create a reference to the task document
    const docRef = doc(db, "users", userID, "tasks", taskID);

    // Return the Promise created by getDoc
    return getDoc(docRef)
      .then((docSnapshot) => {
        if (!docSnapshot.exists()) {
          // If the document does not exist, resolve the Promise with null
          console.log("does not exist");
          return null;
        } else {
          // If the document exists, ensure the data conforms to the Task type
          const taskData = docSnapshot.data();
          if (taskData) {
            // You can perform additional validation here if necessary
            return taskData as Task;
          } else {
            // Handle the undefined data case without throwing an error, return null
            console.log("does not exist");
            return null;
          }
        }
      })
      .catch((error) => {
        // Handle errors or rejections
        // Instead of re-throwing the error, you could return null or handle it in some other way
        console.error(error);
        return null; // or handle the error as appropriate for your application
      });
  };
  const getLogs = async (daysBack: number): Promise<LogData[]> => {
    try {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - daysBack); // Calculate the date based on daysBack parameter

      const logsCollection = collection(db, "logs");
      const querySnapshot = await getDocs(
        query(
          logsCollection,
          where("time", ">=", startDate), // Logs after or equal to the specified date
          where("time", "<=", new Date()), // Logs before or equal to the current date
          orderBy("time", "desc")
        )
      );

      const logsData = querySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) => {
          return { id: doc.id, ...doc.data() } as LogData;
        }
      );

      return logsData;
    } catch (error) {
      console.error("Error fetching logs:", error);
      throw error;
    }
  };

  const getUsers = async (): Promise<UserData[]> => {
    try {
      const usersCollection = collection(db, "users");
      const querySnapshot = await getDocs(
        query(usersCollection, orderBy("created", "desc"))
      ); // Change "asc" to "desc" for descending order

      const usersData = querySnapshot.docs.map((doc) => {
        return doc.data() as UserData;
      });

      return usersData;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  };

  const fetchReminders = (userID: string): Promise<void> => {
    if (userID === "")
      return new Promise<void>((resolve, reject) => {
        reject("no user id");
      });

    return new Promise<void>((resolve, reject) => {
      // const db = getFirestore();
      const userRef = doc(db, "users", userID);
      const colRef = collection(userRef, "reminders");
      const q = query(colRef, orderBy("created", "desc"));

      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const reminders = querySnapshot.docs.map((doc) => {
            return fromJsonReminder(doc.data() as Reminder, doc.id);
          });

          //  setDataReady(true);
          setReminders(reminders);

          resolve(); // Resolve the promise when the tasks are fetched and set
        },
        (error) => {
          reject(error); // Reject the promise if there is an error during the fetch
        }
      );

      // Optional: Clean up subscription on unmount
      unsubscribeReminderRef.current = unsubscribe;
    });
  };

  const fetchUser = (userID: string): Promise<void> => {
    if (userID === "") return Promise.reject("no user id");

    return new Promise<void>((resolve, reject) => {
      //const db = getFirestore();
      const userRef = doc(db, "users", userID);

      const unsubscribe = onSnapshot(
        userRef,
        (docSnapshot) => {
          if (!docSnapshot.exists()) {
            // reject(`No user found for ID: ${userID}`);
            return;
          }

          const user = fromJsonUser(docSnapshot.data() as UserData);

          setUserData(user);

          resolve(); // Resolve the promise with the fetched user
        },
        (error) => {
          reject(error); // Reject the promise if there is an error during the fetch
        }
      );

      // Optional: Clean up subscription on unmount
      unsubscribeUserRef.current = unsubscribe;
    });
  };

  const fetchTasks = (userID: string): Promise<void> => {
    if (userID === "")
      return new Promise<void>((resolve, reject) => {
        reject("no user id");
      });

    return new Promise<void>((resolve, reject) => {
      //const db = getFirestore();
      const userRef = doc(db, "users", userID);
      const colRef = collection(userRef, "tasks");
      const q = query(
        colRef,
        orderBy("created", "desc"),
        where("deleted", "==", false)
      );

      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const newTasks = querySnapshot.docs.map((doc) => {
            return fromJsonTask(doc.data() as Task, doc.id);
          });

          // setDataReady(true);
          setTasks(newTasks);

          resolve(); // Resolve the promise when the tasks are fetched and set
        },
        (error) => {
          reject(error); // Reject the promise if there is an error during the fetch
        }
      );

      // Optional: Clean up subscription on unmount
      unsubscribeTasksRef.current = unsubscribe;
    });
  };

  const getLists = (): Project[] => {
    const homeProjects = projects.filter(
      (project) =>
        project.home === true && !project.done && project.deleted === false
    );

    const pinnedProjects = projects.filter(
      (project) =>
        project.home === false &&
        project.pinned &&
        !project.done &&
        !project.deleted
    );

    const otherProjects = projects.filter(
      (project) =>
        !project.home &&
        !project.done &&
        project.deleted === false &&
        !project.pinned
    );

    return [...homeProjects, ...pinnedProjects, ...otherProjects];
  };

  async function removeDuplicateHomes(projects: Project[]): Promise<boolean> {
    const projectsCopy = [...projects]; // make a copy of the original list

    const homeProjects = projectsCopy.filter((p) => p.home && !p.deleted);

    if (homeProjects.length < 2) {
      return false;
    }

    homeProjects.sort((a, b) => a.created.toMillis() - b.created.toMillis());

    let index = 0;

    for (const project of homeProjects) {
      if (index !== 0) deleteProject(userID, project.id!);
      index++;
    }

    return true;
  }

  const findHomeProject = async (): Promise<void> => {
    homeProjecFound.current = true;

    if (projects.length > 1) {
      let remove: boolean = await removeDuplicateHomes(projects);
      if (remove) return;
    }
    //createHomeProject(userID);
    const homeProjects = projects.filter(
      (project) =>
        project.home === true && !project.done && project.deleted === false
    );

    if (homeProjects.length > 0) {
      let home: Project = homeProjects[0];
      if (activeProject === "home") {
        setProject(home.id!);
      }
      setHomeProjectID(home.id!);

      return;
    }

    if (activeProject === "home") {
      let id = await createHomeProject(userID);
      setHomeProjectID(id);
      if (id !== "") {
        reportConversion();
        createDummyReminder(userID);
        createTrainingTasks(id, userID);
        verifyCalendarProjectExists("calendar", userID);
        setProject(id);
        setNoProjects(false);
      }
    }
  };

  const isDataReady = (): boolean => {
    return dataReady;
  };

  const onSignOut = () => {
    setActiveProject("");
    setDataReady(false);
    setHomeProjectID("");
    setUserID("");

    setDataFetched(false);
    localStorage.clear();
    setProjects([]);
    setTasks(null);
    setReminders([]);
    setUserData(undefined);
    homeProjecFound.current = false;
    //const db = getFirestore();

    terminate(db);
    clearIndexedDbPersistence(db).then(() => {
      initFS();
    });
  };

  const getDebugMsg = (): String => {
    return debugMsg;
  };

  const getTaskDueDateReminders = (taskID: String): DueDateReminder => {
    let object: DueDateReminder = new DueDateReminder();

    const task = tasks?.find((task) => task.id === taskID);
    if (!task || !task.dueDate || !task.dueDateReminderTime) return object;

    object.dueDateReminderChanged = task.dueDateReminderChanged ?? false;
    object.selectedTime = task.dueDateReminderTime
      ? timeStringToDate(task.dueDateReminderTime)
      : new Date();

    object.exactlyOnTime = task.dueDateReminderOnTime ?? false;

    // Set the boolean flags based on task properties
    object.minutesBefore = task.dueDateReminderMinutesBefore ?? false;
    object.hoursBefore = task.dueDateReminderHoursBefore ?? false;
    object.daysBefore = task.dueDateReminderDaysBefore ?? false;
    object.customDueDateReminder = task.customDueDateReminder ?? false;

    // Set the selected values if provided
    object.selectedMinutesBefore =
      task.dueDateReminderMinutes ?? object.selectedMinutesBefore;
    object.selectedHoursBefore =
      task.dueDateReminderHours ?? object.selectedHoursBefore;
    object.selectedDaysBefore =
      task.dueDateReminderDays ?? object.selectedDaysBefore;

    return object;
  };

  const getTaskReminders = (taskID: String): Reminder[] => {
    const currentTime = new Date();

    const filteredReminders = reminders.filter(
      (reminder) =>
        !reminder.deleted &&
        reminder.taskID === taskID &&
        (reminder.recurring || new Date(reminder.time.toMillis()) > currentTime)
    );

    return filteredReminders;
  };

  const getUser = (): UserData | undefined => {
    return userData;
  };

  const getActiveProjectObject = (): Project => {
    return projects.find((project) => project.id === activeProject) as Project;
  };

  const getActiveProjectOrder = (): any[] => {
    const project = projects.filter((project) => project.id === activeProject);

    if (project.length < 1) return [];

    return project[0].order!;
  };

  const getProjectOrder = (id: string): any[] => {
    const project = projects.filter((project) => project.id === id);

    if (project.length < 1) return [];

    return project[0].order!;
  };

  const getProjectName = (): string => {
    const project = projects.filter((pro) => pro.id === activeProject);
    if (project.length !== 1) return "";
    return project[0].name;
  };

  const getTaskName = (): string => {
    if (!tasks) return "";
    const task = tasks.filter((task) => task.id === activeTask);
    if (task.length !== 1) return "";
    return task[0].name;
  };

  const getCompletedTasks = (): Task[] => {
    if (!tasks) return [];
    const filteredTasks = tasks
      .filter(
        (task) =>
          task.project === activeProject && task.deleted === false && task.done
      )
      .sort(
        (a, b) => b.updated.toDate().getTime() - a.updated.toDate().getTime()
      );

    return filteredTasks;
  };

  const getHomeProjectID = () => {
    return homeProjectID;
  };

  const getUserTasks = async (userID: string): Promise<Task[]> => {
    const userRef = doc(db, "users", userID);
    const colRef = collection(userRef, "tasks");

    try {
      const querySnapshot = await getDocs(query(colRef));

      const userTasks = querySnapshot.docs.map((doc) => {
        return doc.data() as Task;
      });

      return userTasks;
    } catch (error) {
      console.error("Error fetching user tasks:", error);
      throw error; // You can choose to handle or rethrow the error as needed
    }
  };

  const getUserProjects = async (userID: string): Promise<Project[]> => {
    const userRef = doc(db, "users", userID);
    const colRef = collection(userRef, "projects");

    try {
      const querySnapshot = await getDocs(query(colRef));

      const userProjects = querySnapshot.docs.map((doc) => {
        return doc.data() as Project;
      });

      return userProjects;
    } catch (error) {
      console.error("Error fetching user projects:", error);
      throw error; // You can choose to handle or rethrow the error as needed
    }
  };

  const getUserDevices = async (userID: string): Promise<DeviceData[]> => {
    const userRef = doc(db, "users", userID);
    const colRef = collection(userRef, "devices");

    try {
      const querySnapshot = await getDocs(query(colRef));

      const userDevices = querySnapshot.docs.map((doc) => {
        return doc.data() as DeviceData;
      });

      return userDevices;
    } catch (error) {
      console.error("Error fetching user tasks:", error);
      throw error; // You can choose to handle or rethrow the error as needed
    }
  };

  const getUserReminders = async (userID: string): Promise<Reminder[]> => {
    const userRef = doc(db, "users", userID);
    const colRef = collection(userRef, "reminders");

    try {
      const querySnapshot = await getDocs(query(colRef));

      const userReminders = querySnapshot.docs.map((doc) => {
        return doc.data() as Reminder;
      });

      return userReminders;
    } catch (error) {
      console.error("Error fetching user tasks:", error);
      throw error; // You can choose to handle or rethrow the error as needed
    }
  };

  const getUserData = async (userID: string) => {
    try {
      // Assuming you have a function to fetch user data by userID
      const tasks = await getUserTasks(userID);
      const projects = await getUserProjects(userID);
      const reminders = await getUserReminders(userID);
      const devices = await getUserDevices(userID);

      let userDB: UserDB = {
        tasks: tasks,
        projects: projects,
        reminders: reminders,
        devices: devices,
      };
      return userDB;
    } catch (error) {
      // Handle any errors that may occur during the data fetching process
      console.error("Error fetching user data:", error);
      throw error; // You can choose to handle or rethrow the error as needed
    }
  };

  const getUserCounts = async (
    userID: string
  ): Promise<{
    remindersSize: number;
    listsSize: number;
    tasksSize: number;
  }> => {
    let data: UserDB = await getUserData(userID);
    let remindersSize = 0;
    let listsSize = data.projects.length;
    let tasks = data.tasks;
    let tasksSize: number = 0;

    tasks.forEach((task) => {
      if (!task.systemCreated) tasksSize++;
    });

    data.reminders.forEach((reminder) => {
      if (reminder.taskID !== "0") remindersSize++;
    });

    // Return an object containing all the counts
    return {
      remindersSize,
      listsSize,
      tasksSize,
    };
  };

  const getUserLastActivity = async (userID: string) => {
    let data: UserDB = await getUserData(userID);
    let dev = data.devices;
    let pro = data.projects;
    let tasks = data.tasks;

    let newestActivityDate: Date | null = null; // Variable to track the newest activity date

    // Loop through devices and update newestActivityDate if needed
    for (const device of dev) {
      if (
        device.android === true ||
        device.apple === true ||
        device.web === true
      ) {
        if (
          !newestActivityDate ||
          (device.lastLogin && device.lastLogin.toDate() > newestActivityDate)
        ) {
          newestActivityDate = device.lastLogin?.toDate() || null;
        }
      }
    }

    // Loop through projects and update newestActivityDate if needed
    for (const project of pro) {
      if (
        !newestActivityDate ||
        (project.created && project.created.toDate() > newestActivityDate)
      ) {
        newestActivityDate = project.created?.toDate() || null;
      }
    }

    // Loop through tasks and update newestActivityDate if needed
    for (const task of tasks) {
      if (
        !newestActivityDate ||
        (task.created && task.created.toDate() > newestActivityDate)
      ) {
        newestActivityDate = task.created?.toDate() || null;
      }
    }

    // Return the formatted newest activity date or default value if not found
    return newestActivityDate!;
  };

  const moveTaskToTop = (taskID: string, userID: string) => {
    if (tasks === null) return;
    let task = tasks.find((task) => task.id === taskID);
    if (!task || !task.project) return;

    let order = getProjectOrder(task.project);

    let all_tasks = getProjectTasks(task.project);

    const no_order = all_tasks.filter(
      (taskItem) => !order.some((taskId) => taskId === taskItem.id)
    );

    no_order.sort(
      (a, b) =>
        new Date(b.created.toDate()).getTime() -
        new Date(a.created.toDate()).getTime()
    );

    // Extract the IDs of the sorted tasks
    const sortedTaskIDs = no_order.map((task) => task.id);

    // Insert the sorted task IDs into the order array
    order.unshift(...sortedTaskIDs);

    order = order.filter((currentTaskID) => currentTaskID !== taskID);

    order.unshift(taskID);

    updateProjectOrder(order, userID, task.project);
  };

  const getProjectTasks = (projectID: string): Task[] => {
    if (!tasks) return [];
    return tasks.filter((task) => task.project === projectID);
  };

  const checkUserPlatform = async (userID: string) => {
    let data: UserDB = await getUserData(userID);
    let dev = data.devices;
    let tasks = data.tasks;

    let swipeTaskExist = false;

    for (const task of tasks) {
      if (task.name === "👈🏼 👉🏼Swipe left or right to complete a task") {
        swipeTaskExist = true;
      }
    }

    for (const device of dev) {
      if (device.android === true) {
        return "mobile-android";
      } else if (device.apple === true) {
        return "mobile-apple";
      } else if (device.web === true) {
        return swipeTaskExist ? "Web-flutter" : "web";
      }
    }

    return swipeTaskExist ? "Web-flutter" : "web";
  };

  const isNewSession = () => {
    return !sessionStarted.current;
  };

  const getToken = () => {
    return token;
  };

  const isTodayView = () => {
    return todayView;
  };

  const isUpcomingView = () => {
    return upcomingView;
  };

  function isToday(date: Date): boolean {
    if (!date) return false;
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  const getAllTasks = (): Task[] => {
    if (!tasks) return [];
    return tasks;
  };

  function timeStringToDate(time: string): Date {
    const [hours, minutes] = time.split(":").map(Number);

    const now = new Date();
    const date = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hours,
      minutes
    );

    return date;
  }

  const setCancelDailyAgenda = (cancel: boolean) => {
    setCancelAgenda(cancel);
  };

  const getCancelDailyAgenda = () => {
    return cancelAgenda;
  };

  const getAllReminders = (): Reminder[] => {
    if (!reminders) return [];
    return reminders;
  };

  const getTasks = (): Task[] => {
    if (!tasks) return [];

    const counts: { [key: string]: number } = {};

    let filteredTasks: Task[] = [];
    let initialTasks: Task[] = [];

    if (todayView) {
      // Get today's date at midnight
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      // Filter tasks that are due today or overdue
      initialTasks = tasks.filter((task) => {
        if (!task.dueDate) return false;
        const dueDate = new Date(task.dueDate.toDate());
        dueDate.setHours(0, 0, 0, 0);
        return dueDate <= today;
      });
    } else if (upcomingView) {
      initialTasks = tasks.filter((task) => task.dueDate !== null);
    } else {
      initialTasks = tasks.filter((task) => task.project === activeProject);
    }

    // Apply common filters
    initialTasks = initialTasks.filter(
      (task) => task.deleted === false && !task.done
    );

    if (tempTask !== null) {
      tempTask.id = "temp_task";

      // Check if a task with the same timestamp as tempTask already exists
      const doesTaskExist = initialTasks.some(
        (task) =>
          task.created.seconds === tempTask.created.seconds &&
          task.created.nanoseconds === tempTask.created.nanoseconds &&
          task.name === tempTask.name
      );

      if (
        !doesTaskExist &&
        ((activeProject !== "today" && activeProject !== "upcoming") ||
          (activeProject === "upcoming" && tempTask.dueDate) ||
          (activeProject === "today" &&
            tempTask.dueDate &&
            isToday(tempTask.dueDate.toDate())))
      ) {
        initialTasks.unshift(tempTask);
      }
    }

    filteredTasks = [...initialTasks];

    let countToday = 0;
    let countUpcoming = 0;

    tasks.forEach((task) => {
      if (task.deleted === false && !task.done && task.project) {
        if (task.dueDate) {
          countUpcoming++;
          const dueDate = new Date(task.dueDate.toDate());
          dueDate.setHours(0, 0, 0, 0);
          const today = new Date();

          if (dueDate <= today) countToday++;
        }
        if (!counts[task.project]) {
          counts[task.project] = 0;
        }
        counts[task.project]++;
      }
    });

    counts["today"] = countToday;
    counts["upcoming"] = countUpcoming;

    // Use TaskCounts class to set the counts
    const taskCounts = TaskCounts.instance;
    taskCounts.setCounts(counts);

    return filteredTasks;
  };

  return (
    <DataContext.Provider
      value={{
        getTasks,
        isCalendarTaskOnTop,
        setCancelDailyAgenda,
        getCancelDailyAgenda,
        getAllTasks,
        getAllReminders,
        addTempTask,
        getTempTask,
        moveTaskToTop,
        getUserCounts,
        isTodayView,
        isUpcomingView,
        getLogs,
        getToken,
        removeTempTask,
        getTaskOnline,
        checkUserPlatform,
        getUserLastActivity,
        getProjectOrder,
        isNewSession,
        getUsers,
        getUserData,
        getCompletedTasks,
        getHomeProjectID,
        getUser,
        getTaskReminders,
        getTaskDueDateReminders,
        getActiveProjectObject,
        getActiveTaskRecord,
        setActiveTaskRecord,
        getDebugMsg,
        isDataReady,
        getProjectName,
        getTaskName,
        getActiveProjectOrder,
        onSignOut,
        fetchLists,
        getLists,
        getActiveProject,
        setActiveProject,
        setActiveTask,
        getActiveTask,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}
function reportConversion() {
  window.gtag_report_conversion();
}
