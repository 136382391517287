import { useOverlay } from "../../../Context/OverlayProvider";
import Dialog from "../Dialog/Dialog";
import { useContext, useState } from "react";
import { ThemeContext } from "../../../Context/Color";
import Icon from "../../Atoms/Icon/Icon";
import Button from "../../Atoms/Button/Button";

type NavOptions = {
  showToday: boolean;
  showUpcoming: boolean;

  userID: string;
  isMobile: boolean;
  onSave: (showToday: boolean, showUpcoming: boolean) => void;
  onCancel: () => void;
};

const NavOptionsDlg = (props: NavOptions) => {
  const themeContext = useContext(ThemeContext);

  const { removeOverlay } = useOverlay();

  const [showToday, setShowToday] = useState(props.showToday);
  const [showUpcoming, setShowUpcoming] = useState(props.showUpcoming);

  return (
    <Dialog
      widthDesktop="400px"
      widthMobile=""
      closeBar={true}
      isMobile={props.isMobile}
      onSave={() => {
        props.onSave(showToday, showUpcoming);
      }}
      saveCancel={true}
      onCancel={() => {
        removeOverlay();
      }}
      onClose={() => {
        props.onSave(showToday, showUpcoming);
      }}
    >
      <div
        style={{
          display: "flex",
          color: themeContext?.textColor,
          flexDirection: "column",
        }}
      >
        <div
          style={{
            fontFamily: "Calibri",
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "20px",
          }}
        >
          Navigation Menu
        </div>
        <div style={{ display: "flex" }}>
          <div>What items to show? </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
            fontSize: "18px",
          }}
        >
          <Button
            iconComponent={<Icon todayIcon={true} />}
            id={"today_menu"}
            label={"Today Menu"}
            textButton={true}
            checkBoxRight={true}
            checkBoxRightChecked={showToday}
            onClick={() => {
              setShowToday(!showToday);
            }}
          />
          <Button
            iconComponent={<Icon dateIcon={true} />}
            id={"upcoming_menu"}
            label={"Upcoming Menu"}
            textButton={true}
            checkBoxRight={true}
            checkBoxRightChecked={showUpcoming}
            onClick={() => {
              setShowUpcoming(!showUpcoming);
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default NavOptionsDlg;
