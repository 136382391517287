import Sortable from "../../UI/Organizm/Sortable/Sortable";

import { restoreAllTasks, Task } from "../../Data/Task";
import "./Style.css";

import NoTasks from "../../UI/Molecules/NoTasks/NoTasks";
import ListTitle from "../../UI/Molecules/ListTitle/ListTitle";
import { Project } from "../../Data/Project";
import { useContext, useEffect, useRef, useState } from "react";
import { useOverlay } from "../../Context/OverlayProvider";
import TaskPage from "../TaskPage/TaskPage";
import { useNavigate } from "react-router-dom";
import { useData } from "../../Context/Data";

import { Reminder } from "../../Data/Reminder";
import { ThemeContext } from "../../Context/Color";

import { Timestamp } from "firebase/firestore";
import Spinner from "../../UI/Atoms/Spinner/Spinner";
import { DueDateReminder } from "../../Data/DueDateReminder";

type ListPageProps = {
  tasks: Task[];
  show: boolean;
  onShowCompleted: (show: boolean) => void;
  listObject: Project;
  sidebarVisible: boolean;
  userTimeZone: string;
  userID: string;
  listCount: number;
  activeTask?: string;
  listID: string;
  isMobile: boolean;
  todayView: boolean;
  upcomingView: boolean;
};

const List = (props: ListPageProps): JSX.Element => {
  const navigate = useNavigate();
  const dataProvider = useData();
  const { isActiveOverlay, addOverlay, removeOverlay } = useOverlay();
  const [showCompleted, setShowCompleted] = useState(false);

  const [completedTasks, setCompletedTasks] = useState(props.tasks);

  const [currentTask, setCurrentTask] = useState(props.activeTask || "");

  const [taskEditMode, setTaskEditMode] = useState(false);

  const themeContext = useContext(ThemeContext);

  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCurrentTask(props.activeTask!);
  }, [props.activeTask]);

  useEffect(() => {
    if (currentTask !== "") showTaskPage();
    else removeOverlay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTask]);

  useEffect(() => {
    if (props.activeTask !== "") showTaskPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskEditMode, props.isMobile]);

  const onShowCompleted = (show: boolean) => {
    setShowCompleted(show);
    props.onShowCompleted(show);
  };

  const onTaskClick = (taskID: string) => {
    setCurrentTask(taskID);
    navigate(`/list/${props.listID}/task/${taskID}`); // Add this line
  };

  const getTaskReminders = (taskID: string): Reminder[] => {
    return dataProvider.getTaskReminders(taskID);
  };

  const getTaskDueDateReminders = (taskID: string): DueDateReminder => {
    return dataProvider.getTaskDueDateReminders(taskID);
  };

  const showTaskPage = () => {
    /*if (!getCurrentTask()) {
      dataProvider.getTaskOnline(props.userID, currentTask);
      addSnackbar(
        "😕 Task not found. Maybe it was deleted?",
        10,
        "",
        undefined,
        true
      );
      setCurrentTask("");
      navigate(`/list/${props.listID}`);
      return;
    }*/
    let taskID = null;
    if (getCurrentTask()) taskID = getCurrentTask().id;

    addOverlay(
      <TaskPage
        dueDateReminders={getTaskDueDateReminders(taskID!)}
        userTimeZone={props.userTimeZone}
        onEdit={(edit) => {
          setTaskEditMode(edit);
        }}
        projectID={props.listID}
        projectName={props.listObject ? props.listObject.name : ""}
        taskReminders={
          taskID !== null ? getTaskReminders(getCurrentTask().id!) : []
        }
        onChangeList={(projectID: string, taskID: string) => {
          navigate("/list/" + projectID + "/task/" + taskID);
        }}
        onMoveToTop={moveToTop}
        isMobile={props.isMobile}
        todayView={props.todayView}
        upcomingView={props.upcomingView}
        Object={getCurrentTask()}
        userID={props.userID}
        onOpenTask={() => {
          onTaskCompleted(false, getCurrentTask().id!);
        }}
        onClose={() => {
          removeOverlay();
          setTaskEditMode(false);
          navigate(`/list/${props.listID}`);
        }}
      />,
      {
        closeOnClickOutside: !taskEditMode,
        centerX: true,

        hideScrollbar: true,
        height: "100%",
        width: props.isMobile ? "100%" : "",
        marginTop: 30,
        marginBottom: !props.isMobile ? 30 : 0,
        onClose: onTaskPageClose,
      },
      false,
      isActiveOverlay(),
      "TASK_PAGE_OVERLAY"
    );
  };

  const onTaskPageClose = () => {
    setTaskEditMode(false);
    navigate(`/list/${props.listID}`);
  };

  const onRestoreTasks = () => {
    if (!showCompleted) return;
    restoreAllTasks(props.userID, props.tasks);
    setCompletedTasks([]);
  };

  const onTaskCompleted = (completed: boolean, taskID: string) => {
    if (!completed) {
      const updatedData = completedTasks.filter((item) => item.id !== taskID);
      setCompletedTasks(updatedData);
    }
  };

  const moveToTop = async (taskID: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      dataProvider.moveTaskToTop(taskID, props.userID);
    });
  };

  const getCurrentTask = (): Task => {
    const foundTask = props.tasks.find((task) => task.id === currentTask);
    if (foundTask) dataProvider.setActiveTaskRecord(foundTask);
    else {
      let t: Task = {
        name: "",
        dueDate: null,
        id: currentTask,
        created: Timestamp.fromDate(new Date()),
        updated: Timestamp.fromDate(new Date()),
      };

      dataProvider.setActiveTaskRecord(t);
    }
    return foundTask!;
  };

  useEffect(() => {
    setCompletedTasks(showCompleted ? props.tasks : []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCompleted]);

  useEffect(() => {
    setShowCompleted(false);
    props.onShowCompleted(false);
    //setCompletedTasks([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.listID]);

  // sort tasks according to the order of task ids in props.order, keeping the original order for those not in props.order
  let sortedTasks =
    !props.listObject ||
    !props.listObject.order ||
    props.listObject.order.length === 0
      ? props.todayView || props.upcomingView
        ? [...props.tasks].sort((a, b) => {
            // Sort only by dueDate when no order is defined
            const aDate = a.dueDate
              ? a.dueDate.toDate().setHours(0, 0, 0, 0)
              : 0; // Use 0 as a fallback timestamp
            const bDate = b.dueDate
              ? b.dueDate.toDate().setHours(0, 0, 0, 0)
              : 0;
            if (aDate !== bDate) {
              return aDate - bDate; // Sort by dueDate in ascending order
            } else {
              // If dueDates are the same, sort by createdAt in descending order
              const aCreated = a.created ? a.created.toDate().getTime() : 0;
              const bCreated = b.created ? b.created.toDate().getTime() : 0;
              return bCreated - aCreated; // Note: b - a for descending order
            } // Use numeric timestamps for sorting
          })
        : [...props.tasks]
      : [...props.tasks].sort((a, b) => {
          if (a.id === "temp_task") {
            return -1; // `a` should always come before `b`
          }
          if (b.id === "temp_task") {
            return 1; // `b` should always come after `a`
          }
          if (!props.todayView && !props.upcomingView) {
            const aIndex = props.listObject!.order!.indexOf(a.id);
            const bIndex = props.listObject!.order!.indexOf(b.id);

            if (aIndex === -1 && bIndex !== -1) {
              return -1; // `a` not in order list, move to top
            } else if (bIndex === -1 && aIndex !== -1) {
              return 1; // `b` not in order list, move to top
            }
          }

          // Convert Firestore Timestamps to JavaScript Date objects for comparison
          const aDate = a.dueDate
            ? a.dueDate.toDate().setHours(0, 0, 0, 0)
            : new Date(0); // Defaulting to a base date if null
          const bDate = b.dueDate
            ? b.dueDate.toDate().setHours(0, 0, 0, 0)
            : new Date(0);

          // Primary sort by dueDate
          if (props.todayView || props.upcomingView) {
            if (aDate < bDate) {
              return -1;
            } else if (aDate > bDate) {
              return 1;
            }
          }

          // Secondary sort by manual order if dueDates are equal
          const aIndex = props.listObject!.order!.indexOf(a.id);
          const bIndex = props.listObject!.order!.indexOf(b.id);

          if (aIndex === -1 && bIndex === -1) {
            // Neither task is in the order array, keep their original order
            return 0;
          } else if (aIndex === -1) {
            // 'a' is not in the order array, it should come before 'b'
            return -1; // Changed from return 1 to return -1
          } else if (bIndex === -1) {
            // 'b' is not in the order array, it should come after 'a'
            return 1; // Changed from return -1 to return 1
          } else {
            // Both tasks are in the order array, sort them according to their order
            return aIndex - bIndex;
          }
        });

  if (props.listObject == null && !props.todayView && !props.upcomingView) {
    //   navigate(`/list/` + dataProvider.getHomeProjectID());

    return (
      <div
        style={{
          backgroundColor: themeContext?.sidbarBackground,
          width: "100%",
        }}
      >
        <Spinner
          centered={true}
          backgroundColor={themeContext?.sidbarBackground}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        height: props.isMobile || !props.sidebarVisible ? "100%" : "98%",
        borderRadius: props.isMobile || !props.sidebarVisible ? "" : "30px",
        marginTop: props.isMobile || !props.sidebarVisible ? "" : "6px",
        marginLeft: props.isMobile || !props.sidebarVisible ? "" : "6px",
        marginRight: props.isMobile || !props.sidebarVisible ? "" : "10px",

        backgroundColor: themeContext?.listBackground,
        alignItems: !props.isMobile ? "center" : "",
        display: props.show ? "flex" : "none",
      }}
      className="list_data"
    >
      <ListTitle
        selectList={props.todayView || props.upcomingView}
        todayView={props.todayView}
        upcomingView={props.upcomingView}
        userID={props.userID}
        userTimeZone={props.userTimeZone}
        listRef={listRef}
        onRestoreTasks={onRestoreTasks}
        width={props.isMobile ? "" : "50%"}
        color={themeContext?.activeItemColor!}
        isMobile={props.isMobile}
        list={props.listObject}
        onShowCompleted={onShowCompleted}
        count={showCompleted ? completedTasks.length : props.listCount}
        id={props.listID}
        fontSize="24px"
      />
      {props.tasks.length > 0 && (
        <Sortable
          userTimeZone={props.userTimeZone}
          todayView={props.todayView}
          upcomingView={props.upcomingView}
          onMoveToTop={moveToTop}
          listRef={listRef}
          onTaskClick={onTaskClick}
          onTaskCompleted={onTaskCompleted}
          completedMode={showCompleted}
          showDescription={props.listObject.showDetails!}
          showReminders={props.listObject.showReminders!}
          listID={props.listID}
          data={!showCompleted ? sortedTasks : completedTasks}
          isMobile={props.isMobile}
        />
      )}
      {props.tasks.length === 0 && (
        <NoTasks
          listID={props.listID}
          completedMode={showCompleted}
          todayView={props.todayView}
          upcomingView={props.upcomingView}
        />
      )}
    </div>
  );
};

export default List;
