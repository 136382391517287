import Button from "../../Atoms/Button/Button";

import { ThemeContext } from "../../../Context/Color";
import { useContext } from "react";

import "./Style.css";

import Dialog from "../Dialog/Dialog";

type WarnDlgProps = {
  applyBtnRed: boolean;
  icon?: React.ReactNode;
  cancelBtnText: string;
  approveBtnText: string;
  isMobile: boolean;
  title: string;
  subTitle: string;
  onApprove: () => void;
  onCancel: () => void;
};

const WarnDlg = (props: WarnDlgProps) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Dialog
      backgroundColor={themeContext?.overlayBackground}
      closeBar={false}
      padding="20px"
      isMobile={props.isMobile}
      saveCancel={false}
      onClose={() => {}}
    >
      <div className="warn_dlg">
        <div
          style={{
            fontSize: "28px",
            display: "flex",
            color: themeContext?.textColor,
            fontWeight: "bold",
          }}
          className="warn_title"
        >
          {props.icon && (
            <div style={{ marginRight: "8px" }} className="warn_title_icon">
              {props.icon}
            </div>
          )}
          <div className="warn_title_text">{props.title}</div>
        </div>
        <div
          style={{
            fontSize: "20px",
            marginTop: "18px",
            color: themeContext?.textColor,
          }}
          className="warn_sub_title"
        >
          {props.subTitle}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",

            marginTop: "18px",
          }}
          className="warn_btns"
        >
          <Button
            textButton={true}
            id={"cancel"}
            label={props.cancelBtnText}
            onClick={props.onCancel}
          ></Button>

          <Button
            textButton={true}
            textButtonColor={props.applyBtnRed ? "red" : ""}
            id={"save"}
            label={props.approveBtnText}
            onClick={props.onApprove}
          ></Button>
        </div>
      </div>
    </Dialog>
  );
};

export default WarnDlg;
