import React, { useContext } from "react";
import { ThemeContext } from "../../../Context/Color";
import Tooltip from "@mui/material/Tooltip";

type HeaderProps = {
  tooltip?: string;
  text: string;
  fontSize: number; // Correct type for numeric values in TypeScript
};

const Header: React.FC<HeaderProps> = ({ text, fontSize, tooltip }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <h1
      style={{ color: themeContext?.textTheme.text, fontSize: `${fontSize}px` }}
    >
      <Tooltip arrow placement="top" enterDelay={1000} title={tooltip}>
        <span>{text}</span>
      </Tooltip>
    </h1>
  );
};

export default Header;
