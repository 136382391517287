import React, { useState, useRef, useEffect, useContext } from "react";
import { ThemeContext } from "../../../Context/Color";
import Tooltip from "@mui/material/Tooltip/Tooltip";

type TimePickProps = {
  isMobile: boolean;
  reminder: boolean;
  title: string;
  textAlign?: "left" | "right" | "center" | "justify" | "start" | "end";
  parentDivBorder: number;
  tooltip: string;
  currentTime?: Date;
  width: number;
  onTimeSelected: (time: Date) => void;
  onFocus: (focus: boolean) => void;
  onInvalidTime: () => void;
  focus: boolean;
};

const generateTimes = () => {
  const now = new Date();
  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();
  const roundedHour = currentMinute >= 30 ? currentHour + 1 : currentHour;
  const times = [];

  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 30) {
      const hour = (roundedHour + i) % 24;
      const minute = j;
      const displayHour = hour < 10 ? `0${hour}` : `${hour}`;
      const displayMinute = minute < 10 ? `0${minute}` : `${minute}`;
      times.push(`${displayHour}:${displayMinute}`);
    }
  }

  return times;
};

const TimePick = (props: TimePickProps) => {
  const themeContext = useContext(ThemeContext);

  const getSelectedTime = (): string => {
    if (props.currentTime === null || props.currentTime === undefined)
      return "";

    let minute =
      props.currentTime!.getMinutes() === 0
        ? "00"
        : props.currentTime!.getMinutes().toString();

    if (minute.length === 1) minute = "0" + minute;

    return props.currentTime!.getHours() + ":" + minute;
  };
  const [isFocused, setIsFocused] = useState(false);

  const [inputHeight, setInputHeight] = useState(0);
  const [filterValue, setFilterValue] = useState("");
  const [hoveredTime, setHoveredTime] = useState<string | null>(null);

  const timeListRef = useRef<HTMLDivElement | null>(null);

  const timeOptionRef = useRef<HTMLDivElement | null>(null);

  const [inputValue, setInputValue] = useState(getSelectedTime());
  const inputRef = useRef<HTMLInputElement | null>(null);
  const times = generateTimes();
  const filteredTimes = times.filter((time) => time.startsWith(filterValue));

  const [titleTop, setTitleTop] = useState(-10.5); // Initialize with a default value

  const titleRef = useRef<HTMLDivElement>(null);

  let blurTimeout: NodeJS.Timeout;

  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);

  const handleFocus = () => {
    props.onFocus(true);
    setIsFocused(true);
  };

  const onTimeHover = (hoverTime: string) => {
    setHoveredTime(hoverTime);
  };

  const onTimeLeave = () => {
    setHoveredTime(null);
  };

  const handleBlur = () => {
    blurTimeout = setTimeout(() => {
      props.onFocus(false);

      setIsFocused(false);
    }, 200); // delay hiding for 100ms
  };

  const isValidTime = (time: string): boolean => {
    const [hours] = time.split(":").map(Number);
    if (hours < 10 && time.length === 4) time = "0" + time;

    // Remove optional colon
    const cleanedTime = time.replace(":", "");

    // Check if the cleaned string has exactly 4 characters
    if (cleanedTime.length !== 4) return false;

    // Check if all characters are digits
    if (!/^\d+$/.test(cleanedTime)) return false;

    // Extract hours and minutes
    const hour = parseInt(cleanedTime.substring(0, 2), 10);
    const minute = parseInt(cleanedTime.substring(2, 4), 10);

    // Check if hours and minutes are valid
    if (hour < 0 || hour > 23) return false;
    if (minute < 0 || minute > 59) return false;

    return true;
  };

  const stringToTime = (time: string): Date => {
    const [hours] = time.split(":").map(Number);
    if (hours < 10 && time.length === 4) time = "0" + time;

    const now = new Date();
    const cleanedTime = time.replace(":", "");

    // Extract hours and minutes
    const hour = parseInt(cleanedTime.substring(0, 2), 10);
    const minute = parseInt(cleanedTime.substring(2, 4), 10);

    const selectedTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hour,
      minute
    );

    selectedTime.setSeconds(0);

    return selectedTime;
  };

  const isTimeInserted = (value: string): boolean => {
    if (value.length === 4 && !value.includes(":")) return true;
    if (value.length === 5 && value.includes(":")) return true;

    return false;
  };

  useEffect(() => {
    if (titleRef.current) {
      const fontSize = titleRef.current.offsetHeight;

      const calculatedTop = -(fontSize / 2) - props.parentDivBorder - 1;

      setTitleTop(calculatedTop);
    }
  }, [props.parentDivBorder]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (value.length === 5 && !value.includes(":")) return;
    // Only allow digits to be entered.
    if (/^[0-9:]*$/.test(value)) {
      // Automatically add a colon after two digits
      setFilterValue(value);

      setInputValue(value);
      if (isValidTime(value)) {
        props.onTimeSelected(stringToTime(value));
      } else {
        if (isTimeInserted(value)) {
          setFilterValue("");
        }
        props.onInvalidTime();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && inputValue.endsWith(":")) {
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      if (highlightedIndex !== null && highlightedIndex > 0) {
        setHighlightedIndex(highlightedIndex - 1);
      }
    } else if (e.key === "ArrowDown") {
      e.preventDefault();

      if (
        highlightedIndex === null ||
        highlightedIndex < filteredTimes.length - 1
      ) {
        setHighlightedIndex((highlightedIndex ?? -1) + 1);
      }
    } else if (e.key === "Enter" && highlightedIndex !== null) {
      // If Enter is pressed, select the highlighted time
      onTimeClick(filteredTimes[highlightedIndex]);
    }
  };

  const onTimeClick = (clickedTime: string) => {
    clearTimeout(blurTimeout); // clear the timeout when a time is clicked
    setInputValue(clickedTime);
    setIsFocused(false);
    setFilterValue("");
    setHoveredTime(null);
    const now = new Date();
    const [hour, minute] = clickedTime.split(":").map(Number);
    const selectedTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hour,
      minute
    );
    props.onTimeSelected(selectedTime);
    if (inputRef.current) inputRef.current.blur();
  };
  useEffect(() => {
    if (inputRef.current) {
      setInputHeight(inputRef.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    setHighlightedIndex(null);
  }, [filteredTimes.length]);

  useEffect(() => {
    if (
      highlightedIndex === null ||
      highlightedIndex === undefined ||
      timeListRef.current === null ||
      timeOptionRef.current === undefined ||
      timeOptionRef.current === null
    )
      return;

    const itemHeight = timeOptionRef.current.offsetHeight;

    const container = timeListRef.current;
    const { scrollTop, clientHeight } = container;

    const itemTop = highlightedIndex * itemHeight;
    const itemBottom = (highlightedIndex + 1) * itemHeight;

    if (itemTop < scrollTop) {
      container.scrollTop = itemTop;
    } else if (itemBottom > scrollTop + clientHeight) {
      container.scrollTop = itemBottom - clientHeight;
    }
  }, [highlightedIndex, inputHeight, timeListRef]);

  useEffect(() => {
    if (!isFocused) {
      setHighlightedIndex(null);
    }
  }, [isFocused]);

  return (
    <Tooltip arrow enterDelay={2000} title={props.tooltip} placement="top">
      <div
        style={{
          display: "flex",
          flexDirection: "column",

          width: "100%",
        }}
      >
        <div style={{ position: "relative" }}>
          <div
            ref={titleRef}
            style={{
              position: "absolute",
              fontSize: "16px",
              top: `${titleTop}px`,
              left: "5px",
              paddingRight: "8px",
              paddingLeft: "8px",
              backgroundColor: themeContext?.overlayBackground,
              color: themeContext?.activeItemColor,
            }}
          >
            {props.reminder ? "Reminder time" : props.title}
          </div>
        </div>
        <div style={{ display: "flex", flex: "1" }}>
          <input
            style={{
              fontSize: "18px",
              padding: "16px",
              border: "none",
              outline: "none",
              backgroundColor: themeContext?.overlayBackground!,
              fontFamily: "Calibri",
              textAlign: props.textAlign || "center",
              color: themeContext?.textColor,
              flex: "1",
              display: "flex",
            }}
            ref={inputRef}
            placeholder="HH:MM"
            type="text"
            autoFocus={
              props.focus &&
              (props.currentTime === undefined || props.currentTime === null)
            }
            value={inputValue}
            maxLength={5}
            onKeyDown={handleKeyDown}
            onInput={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
          ></input>
        </div>
        {isFocused && filteredTimes.length > 0 && (
          <div
            ref={timeListRef}
            style={{
              display: "flex",
              flexDirection: "column",

              position: "absolute",
              backgroundColor: themeContext?.overlayBackground,
              maxHeight: "100px",
              overflow: "auto",
              zIndex: 2,

              marginTop: `${inputHeight + 10}px`,
              width: `${props.width}px`,
            }}
          >
            {filteredTimes.map((time, index) => (
              <div
                ref={timeOptionRef}
                style={{
                  cursor: "pointer",
                  color: themeContext?.textColor,
                  backgroundColor:
                    hoveredTime === time
                      ? themeContext?.taskHoverColor
                      : highlightedIndex === index
                      ? themeContext?.taskHoverColor
                      : themeContext?.overlayBackground,
                }}
                onMouseOver={() => onTimeHover(time)}
                onMouseLeave={onTimeLeave}
                onClick={() => onTimeClick(time)}
                key={index}
              >
                {time}
              </div>
            ))}
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default TimePick;
