export class TaskCounts {
  private static _instance: TaskCounts | null = null;
  private _counts: { [key: string]: number } = {};

  // Private constructor
  private constructor() {}

  // Getter for the instance
  public static get instance() {
    if (this._instance === null) {
      this._instance = new TaskCounts();
    }
    return this._instance;
  }

  // Method to update the count for a project
  public setCounts(counts: { [key: string]: number }) {
    this._counts = { ...counts };
  }

  // Method to get the count for a project
  public getCount(projectId: string): number {
    return this._counts[projectId] || 0;
  }
}
