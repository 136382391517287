import { useContext } from "react";
import Button from "../../Atoms/Button/Button";
import Icon from "../../Atoms/Icon/Icon";
import { ThemeContext } from "../../../Context/Color";

type CloseDlgRowProps = {
  onClose: () => void;
};

const CloseDlgRow = (props: CloseDlgRowProps) => {
  const themeContext = useContext(ThemeContext);

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: themeContext?.overlayBackground,
        color: themeContext?.textColor,
        justifyContent: "flex-end",

        borderRadius: " 15px 15px 0 0",
      }}
    >
      {" "}
      <Button
        textButtonColor={"black"}
        id="close_this"
        label={""}
        onMouseDown={(e) => e.preventDefault()}
        iconComponent={
          <Icon
            fontSize={22}
            color={themeContext?.textColor}
            closeIcon={true}
          />
        }
        textButton={true}
        onClick={() => {
          props.onClose();
        }}
      ></Button>
    </div>
  );
};

export default CloseDlgRow;
