import React, { useContext } from "react";
import { ThemeContext } from "../../../Context/Color";
import { useData } from "../../../Context/Data";

interface SpinnerProps {
  centered?: boolean;
  backgroundColor?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ centered, backgroundColor }) => {
  const themeContext = useContext(ThemeContext);

  const color = themeContext?.sidebarRows;

  const data = useData();

  //spinner

  return (
    <>
      <style>
        {`
          @keyframes rotation {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
      <div
        style={{
          display: "flex",
          alignItems: centered ? "center" : undefined,
          justifyContent: centered ? "center" : undefined,
          height: centered ? "100vh" : undefined,
          backgroundColor: backgroundColor || themeContext?.listBackground,
        }}
      >
        <div
          style={{
            width: "48px",
            height: "48px",
            border: `5px solid ${color}`,
            borderBottomColor: "transparent",
            borderRadius: "50%",
            boxSizing: "border-box",
            animation: "rotation 1s linear infinite",
          }}
        >
          {}
        </div>
        {data.getDebugMsg()}
      </div>
    </>
  );
};

export default Spinner;
