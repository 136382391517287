import { MessageData } from "../../../Data/Message";
import { ChatColors } from "../../../Util/chatColors";

type BubbleProps = {
  message: MessageData;
  colors: ChatColors;
};

const ChatBubble = ({ message, colors }: BubbleProps) => {
  return (
    <div
      style={{
        padding: "8px",
        margin: "5px 0",
        borderRadius: "8px",
        color: message.isSentByUser
          ? colors.userTextColor
          : colors.botTextColor,
        backgroundColor: message.isSentByUser
          ? colors.userBackgroundColor
          : colors.botBackgroundColor,
        alignSelf: message.isSentByUser ? "flex-end" : "flex-start",
        maxWidth: "75%",
      }}
    >
      {message.text}
    </div>
  );
};

export default ChatBubble;
