import React, { useContext, useEffect, useRef, useState } from "react";
import "./Style.css";

import Button from "../../UI/Atoms/Button/Button";
import { useAuth } from "../../Context/Auth";

import { GooglePlayButton, AppStoreButton } from "react-mobile-app-button";

import Spinner from "../../UI/Atoms/Spinner/Spinner";
import Logo from "../../UI/Atoms/Logo/Logo";

import { useOverlay } from "../../Context/OverlayProvider";
import EditDlg from "../../UI/Organizm/EditDlg/EditDlg";
import { useSnackbar } from "../../Context/SnackbarProvider";

import Platform from "../../Util/Platform";

import { isAndroid, isiPhone, promoteMobileApp } from "../../Util/Platform";

import { ThemeContext } from "../../Context/Color";
import Icon from "../../UI/Atoms/Icon/Icon";
import EditBox from "../../UI/Atoms/EditBox/EditBox";

import GoogleBtn from "../../UI/Molecules/GoogleBtn/GoogleBtn";
import AppleBtn from "../../UI/Molecules/AppleBtn/AppleBtn";

type RegistrationProps = {};

const RegistrationPage = (props: RegistrationProps) => {
  const auth = useAuth();
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState(false);
  const { addOverlay, removeOverlay } = useOverlay();
  const { addSnackbar } = useSnackbar();
  const themeContext = useContext(ThemeContext);

  const [editBoxFocused, setEditBoxFocused] = useState(false);
  const { isMobileResolution, isMobile } = Platform();

  const [currentText, setCurrentText] = useState("");

  const [progress, setProgress] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Auth error");
  const [register, setRegister] = useState(false);
  const [login, setLogin] = useState(false);

  const strings = [
    "📝 Take the stress out of life by effortlessly creating tasks anytime, anywhere.",
    "📋 Organize your day-to-day activities with multiple custom lists.",
    "⭐ Don't miss out on the essentials! Flag those super important tasks.",
    "📅 Keep on track by setting due dates for each task at hand.",
    "🔔 Stay ahead of your schedule with timely reminders for every task.",
    "📎 Got a lot on your plate? Bulk add tasks with a simple copy-paste.",
    "🌍 Enjoy a seamless cross-platform experience, wherever you are.",
    "🔍 Search and filter tasks to find what you need in an instant.",
    "✏️ Edit tasks with ease, adding details as they come up.",
    "🔄 Sync your tasks across devices, ensuring you're always up-to-date.",
    "💼 Ace your projects by breaking them down into manageable sub-tasks.",
    "🌙 Embrace night mode for those late-night productivity sessions.",
    "🎁 Discover more features and updates, tailored to enhance your workflow.",
  ];

  const updateError = (hasError: boolean, error: string) => {
    setProgress(false);
    setError(hasError);
    setErrorMsg(error);
  };

  const onDownloadApp = () => {
    let downloadPageURL = "";

    if (isAndroid())
      downloadPageURL =
        "https://play.google.com/store/apps/details?id=com.keeplist";
    else if (isiPhone())
      downloadPageURL = "https://apps.apple.com/sa/app/keeplist/id1626699174";

    window.open(downloadPageURL, "_blank");
  };

  useEffect(() => {
    if (promoteMobileApp()) {
      addSnackbar(
        "🚀 KEEPLIST IS BETTER ON THE MOBILE APP!",
        15,
        "DOWNLOAD",
        onDownloadApp,
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginGoogle = () => {
    //  setProgress(true);
    auth
      .authLoginGoogle()
      .then(() => {
        updateError(false, "");
        console.log("Registration successful");
        // Additional logic after successful registration
      })
      .catch((error) => {
        updateError(true, error.message);
        console.error("Registration error:", error);
        // Additional error handling logic
      });
  };

  const loginApple = () => {
    //  setProgress(true);
    auth
      .authLoginApple()
      .then(() => {
        updateError(false, "");
        console.log("Registration successful");
        // Additional logic after successful registration
      })
      .catch((error) => {
        updateError(true, error.message);
        console.error("Registration error:", error);
        // Additional error handling logic
      });
  };

  const resetPassword = (email: string) => {
    if (email == null || email === "") return;

    setProgress(true);

    auth
      .authResetPass(email)
      .then(() => {
        updateError(false, "");
        //   myToast.showToast(false, "Pass reset OK");
        addSnackbar("Pass reset OK");

        // Additional logic after successful registration
      })
      .catch((error) => {
        console.log(error);
        //  updateError(true, error);
        setProgress(false);
        //  myToast.showToast(true, "Pass reset error: " + error);
        addSnackbar("Pass reset error: " + error);
        // Additional error handling logic
      });
  };

  const loginUser = () => {
    let username = usernameRef.current?.value;
    let password = passwordRef.current?.value;

    username = username?.trim();
    //password = password?.trim();

    if (username == null || password == null) {
      return;
    }

    auth
      .authLogin(username, password)
      .then(() => {
        updateError(false, "");
        console.log("Registration successful");
        // Additional logic after successful registration
      })
      .catch((error) => {
        updateError(true, error);
        console.error("Registration error:", error);
        // Additional error handling logic
      });
  };

  const registerUser = () => {
    let username = usernameRef.current?.value;
    let password = passwordRef.current?.value;

    username = username?.trim();
    // password = password?.trim();

    if (username == null || password == null) {
      return;
    }

    auth
      .register(username, password)
      .then(() => {
        updateError(false, "");
        console.log("Registration successful");
        // Additional logic after successful registration
      })
      .catch((error) => {
        updateError(true, error);
        console.error("Registration error:", error);
        // Additional error handling logic
      });
  };

  // Assuming you've initialized your 'setCurrentText' and 'strings' elsewhere in the component
  // ...

  useEffect(() => {
    let lastString = "";
    let intervalId: ReturnType<typeof setInterval>;
    let timeoutId: ReturnType<typeof setTimeout>;

    const getRandomString = () => {
      let newString = strings[Math.floor(Math.random() * strings.length)];
      while (newString === lastString && strings.length > 1) {
        newString = strings[Math.floor(Math.random() * strings.length)];
      }
      lastString = newString;
      return newString;
    };

    let words = getRandomString().split(" ");
    let wordIndex = 0;
    setCurrentText("");

    const wordInterval = 150;
    const sentenceDelay = 6000;

    const animateWords = () => {
      if (wordIndex < words.length) {
        let word = words[wordIndex];
        setCurrentText((prev) => prev + " " + word);
        wordIndex++;
      } else {
        clearInterval(intervalId);

        timeoutId = setTimeout(() => {
          words = getRandomString().split(" ");
          wordIndex = 0;
          setCurrentText("");
          intervalId = setInterval(animateWords, wordInterval);
        }, sentenceDelay);
      }
    };

    intervalId = setInterval(animateWords, wordInterval);

    return () => {
      clearInterval(intervalId); // Clear any ongoing intervals
      clearTimeout(timeoutId); // Clear any ongoing timeouts
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    setProgress(true);
    if (register) registerUser();
    else loginUser();
  };

  const onEmailChange = (value: string) => {};

  const onShowForgot = () => {
    addOverlay(
      <EditDlg
        isMobile={isMobileResolution}
        header="I forgot my password"
        placeHolder="What is your email?"
        allowEmptyValue={false}
        saveBtnText="Reset"
        cancelBtnText="Cancel"
        emailValidation={true}
        headerSize={24}
        onSave={(email: string) => {
          removeOverlay();
          resetPassword(email);
        }}
        onCancel={() => {
          removeOverlay();
        }}
      />,
      {
        closeOnClickOutside: true,
        centerX: false,
        marginTop: 100,
        width: "100%",
      }
    );
  };

  return (
    <div
      className="main"
      style={{
        height: "100%",
        display: "flex",
        width: isMobileResolution ? "100%" : "",
        flexDirection: isMobileResolution ? "column" : "row",

        overflow: "auto",
        backgroundColor: themeContext?.overlayBackground,
      }}
    >
      {!isMobileResolution && (
        <div
          style={{
            width: "50%",
            backgroundColor: themeContext?.isDarkTheme ? "#343541" : "#ffffdb",
            color: themeContext?.textColor,
            display: "flex",
            padding: "20px",
            flexDirection: "column",
          }}
        >
          <Logo
            alignCenterX={true}
            alignCenterY={true}
            smallLogo={false}
            color={themeContext?.textColor!}
          />
          <div
            style={{
              display: "flex",
              marginTop: "70px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ marginRight: "12px" }}>
              <GooglePlayButton
                width={160}
                url={
                  "https://play.google.com/store/apps/details?id=com.keeplist"
                }
                theme={themeContext?.isDarkTheme ? "dark" : "light"}
              />
            </div>

            <AppStoreButton
              width={155}
              url={"https://apps.apple.com/sa/app/keeplist/id1626699174"}
              theme={themeContext?.isDarkTheme ? "dark" : "light"}
            />
          </div>
          <div
            style={{ marginTop: "200px", fontSize: "40px", fontWeight: "bold" }}
          >
            {currentText}
          </div>
        </div>
      )}
      <div
        style={{
          width: isMobileResolution ? "" : "50%",
          paddingLeft: !login && !register ? "30px" : "30px",

          paddingRight: "30px",
          justifyContent: !isMobileResolution ? "center" : "",
        }}
        className="fields"
      >
        {(!editBoxFocused || !isMobile) && isMobileResolution && (
          <Logo
            alignCenterX={false}
            alignCenterY={true}
            smallLogo={true}
            color={themeContext?.textColor!}
          />
        )}

        {!register && !login && (
          <div>
            <div
              style={{
                color: themeContext?.textColor,
                fontWeight: "bold",
                fontSize: !isMobileResolution ? "50px" : "40px",
                marginTop: isMobileResolution ? "60px" : "20px",
              }}
            >
              The best to do list!
            </div>
            <div
              style={{
                color: themeContext?.textColor,
                fontWeight: "bold",
                fontSize: !isMobileResolution ? "30px" : "24px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              Join today.
            </div>
          </div>
        )}
        {(login || register) && (
          <div style={{ marginTop: "16px" }}>
            <Button
              iconComponent={<Icon fontSize={40} backIcon={true}></Icon>}
              id="cancel_process"
              marginBottom={login ? 16 : 0}
              textButton={true}
              label=""
              onClick={() => {
                setError(false);
                setLogin(false);
                setRegister(false);
              }}
            />
          </div>
        )}

        {!register && (
          <GoogleBtn
            caption={!login ? "Sign up with Google" : "Sign in with Google"}
            onClick={() => {
              loginGoogle();
            }}
          />
        )}
        {!register && (
          <AppleBtn
            caption={!login ? "Sign up with Apple" : "Sign in with Apple"}
            onClick={() => {
              loginApple();
            }}
          />
        )}
        {!progress && (
          <div
            style={{
              marginTop: "10px",
              color: error ? "red" : "",
              display: "flex",

              alignItems: "flex-start",
              flexDirection: isMobileResolution ? "column" : "column",
              fontSize: "22px",
              marginBottom: "",
            }}
          >
            {error && errorMsg}
          </div>
        )}
        {!register && !login && (
          <div
            style={{
              color: themeContext?.textColor,
              display: "flex",
              justifyContent: "center",
              width: "300px",
              marginBottom: "10px",
            }}
          >
            <div>OR</div>
          </div>
        )}

        {!register && !login && (
          <Button
            id="create_account"
            label="Create account"
            fontSize="18px"
            boldText={true}
            width={"300px"}
            centerText={true}
            textButtonColor="white"
            backgroundColor="#1d9bf0"
            textButton={false}
            onClick={() => {
              setRegister(true);
            }}
          />
        )}

        {!register && !login && (
          <div
            style={{
              marginTop: "40px",
              fontWeight: "bold",
              marginBottom: "15px",
              fontSize: "20px",
              color: themeContext?.textColor,
            }}
          >
            Already have an account? Sign in.
          </div>
        )}

        {!register && !login && (
          <Button
            backgroundColor="white"
            id="login_to_account"
            borderStyle="1px solid lightgray"
            label="Sign in"
            textButtonColor={themeContext?.isDarkTheme ? "black" : "#1d9bf0"}
            width={"300px"}
            centerText={true}
            textButton={false}
            onClick={() => {
              setLogin(true);
            }}
          />
        )}

        {isMobileResolution && !login && !register && (
          <div style={{ display: "flex", marginTop: "40px" }}>
            <div style={{ marginRight: "12px" }}>
              <GooglePlayButton
                width={140}
                url={
                  "https://play.google.com/store/apps/details?id=com.keeplist"
                }
                theme={themeContext?.isDarkTheme ? "dark" : "light"}
              />
            </div>
            <AppStoreButton
              width={140}
              url={"https://apps.apple.com/sa/app/keeplist/id1626699174"}
              theme={themeContext?.isDarkTheme ? "dark" : "light"}
            />
          </div>
        )}

        {register && (
          <div
            style={{
              fontSize: "28px",
              padding: "6px",
              fontWeight: "bold",
              color: themeContext?.textColor,
            }}
          >
            Create your account 👋
          </div>
        )}

        {(register || login) && (
          <EditBox
            type="email"
            marginBottom="6px"
            marginTop={"30px"}
            fontSize={20}
            placeholder="email"
            disableBorder={true}
            iconComponent={
              <Icon color={themeContext?.textColor} emailIcon={true} />
            }
            id="username"
            EditRef={usernameRef}
            onInputChange={onEmailChange}
            onFocus={() => setEditBoxFocused(true)}
            readOnly={progress}
            onEnterPress={handleClick}
            focus={register || !isMobile}
          />
        )}

        {(register || login) && (
          <EditBox
            type="password"
            fontSize={20}
            placeholder="password"
            id="password"
            marginBottom="10px"
            iconComponent={<Icon color={themeContext?.textColor} lock={true} />}
            disableBorder={true}
            EditRef={passwordRef}
            readOnly={progress}
            onFocus={() => setEditBoxFocused(true)}
            onEnterPress={handleClick}
            focus={false}
          />
        )}

        {!progress && (register || login) && (
          <Button
            textButton={false}
            boldText={true}
            centerText={true}
            textButtonColor="white"
            backgroundColor="#1d9bf0"
            fontSize="20px"
            iconComponent={<Icon register={register} login={!register} />}
            id={"register"}
            marginBottom={10}
            label={register ? "Create account" : "Sign in"}
            onClick={handleClick}
          ></Button>
        )}
        {login && (
          <Button
            textButton={true}
            centerText={true}
            marginBottom={10}
            backgroundColor={themeContext?.isDarkTheme ? "" : "#f2f2fb"}
            textButtonColor={themeContext?.isDarkTheme ? "" : "#1e1ec2"}
            fontSize="20px"
            id={"forgot"}
            iconComponent={<Icon lock={true} />}
            label={"Forgot your Password?"}
            onClick={() => {
              onShowForgot();
            }}
          ></Button>
        )}
        {progress && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Spinner></Spinner>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegistrationPage;
