import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { db } from "../firebase";

export const addDocumentToFirestore = async (
  document: any, // JSON representation of the document
  collectionPath: string,
  documentId?: string // Optional document ID
): Promise<string> => {
  try {
    if (documentId) {
      const docRef = doc(db, collectionPath, documentId);
      await setDoc(docRef, document);
      return docRef.id;
    } else {
      const docRef = await addDoc(collection(db, collectionPath), document);
      return docRef.id;
    }
  } catch (e) {
    console.error("Error adding document: ", e);
    throw e; // It's important to re-throw the error so that it can be caught and handled by the caller.
  }
};

// Function to add a document to Firestore with a specific document ID
export const createDocumentWithID = async (
  document: any, // JSON representation of the document
  collectionPath: string,
  documentId: string // Required document ID
): Promise<string> => {
  try {
    const docRef = doc(db, collectionPath, documentId);
    await setDoc(docRef, document); // This will overwrite any existing document with the same ID
    return docRef.id; // Returns the ID of the document created
  } catch (error) {
    console.error("Error creating document with ID: ", error);
    throw error; // Re-throw the error for the caller to handle
  }
};

export const setDocumentInFirestore = async (
  document: any, // using 'any' type as you're providing a JSON representation of the document
  collectionPath: string,
  documentID: string
): Promise<void> => {
  try {
    console.log(documentID);
    const docRef = doc(db, collectionPath, documentID);
    await setDoc(docRef, document); // No need to call toJson() as document is already in JSON format
  } catch (e) {
    console.error("Error setting document: ", e);
    throw e; // It's important to re-throw the error so that it can be caught and handled by the caller.
  }
};

export const updateDocumentInFirestore = async (
  document: any, // using 'any' type as you're providing a JSON representation of the document
  collectionPath: string,
  documentId: string
): Promise<void> => {
  try {
    const docRef = doc(db, collectionPath, documentId);
    await updateDoc(docRef, document); // no need to call toJson() as document is already JSON
  } catch (e) {
    console.error("Error updating document: ", e);
    throw e; // It's important to re-throw the error so that it can be caught and handled by the caller.
  }
};

export const getDocumentFromFirestore = async (
  collectionPath: string,
  documentId: string
): Promise<any | null> => {
  try {
    const docRef = doc(db, collectionPath, documentId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null; // Document doesn't exist
    }
  } catch (e) {
    console.error("Error getting document: ", e);
    throw e; // Re-throw the error to be caught and handled by the caller.
  }
};

export const deleteDocumentFromFirestore = async (
  collectionPath: string,
  documentId: string
): Promise<void> => {
  try {
    const docRef = doc(db, collectionPath, documentId);
    await deleteDoc(docRef);
  } catch (e) {
    console.error("Error deleting document: ", e);
    throw e; // Re-throw the error to be caught and handled by the caller.
  }
};

export const addMultipleDocumentsToFirestore = async (
  documents: any[], // using 'any' type as you're providing a JSON representation of the documents
  collectionPath: string
): Promise<string[]> => {
  try {
    const batch = writeBatch(db);
    const docIds: string[] = [];

    for (const document of documents) {
      const docRef = doc(collection(db, collectionPath));
      batch.set(docRef, document); // no need to call toJson() as document is already JSON
      docIds.push(docRef.id);
    }

    await batch.commit();
    return docIds;
  } catch (e) {
    console.error("Error adding documents: ", e);
    throw e; // It's important to re-throw the error so that it can be caught and handled by the caller.
  }
};

export const updateMultipleDocumentsInFirestore = async (
  documents: { id: string; data: any }[], // An array of objects, each containing the ID and data for a document to be updated.
  collectionPath: string
): Promise<void> => {
  try {
    const batch = writeBatch(db);

    for (const document of documents) {
      const docRef = doc(db, collectionPath, document.id);
      batch.update(docRef, document.data);
    }

    await batch.commit();
  } catch (e) {
    console.error("Error updating documents: ", e);
    throw e; // It's important to re-throw the error so that it can be caught and handled by the caller.
  }
};
