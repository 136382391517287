import Button from "../../Atoms/Button/Button";
import { ThemeContext } from "../../../Context/Color";

import { useContext, useEffect, useState } from "react";
import Icon from "../../Atoms/Icon/Icon";
import Platform from "../../../Util/Platform";

type CalendarProps = {
  isMobile: boolean;
  marginTop?: number;
  currentDate?: Date;
  marginBottom?: number;
  onDateSelected: (day: Date) => void;
  preventDefaultMouseDown: boolean;
  allowPastDates: boolean;
};

const Calendar = (props: CalendarProps) => {
  const themeContext = useContext(ThemeContext);

  const { isMobile } = Platform();

  useEffect(() => {
    setCurrentDate(props.currentDate);
  }, [props.currentDate]);

  const dateSelected = (): boolean => {
    return currentDate !== null && currentDate !== undefined;
  };

  const getCurrentMonth = () => {
    if (dateSelected()) return currentDate!.getMonth() + 1;
    var dateNow = new Date();
    return dateNow.getMonth() + 1;
  };

  const getCurrentYear = () => {
    if (dateSelected()) return currentDate!.getFullYear();
    var dateNow = new Date();
    return dateNow.getFullYear();
  };

  const [currentDate, setCurrentDate] = useState<Date | null | undefined>(
    props.currentDate
  );

  const [selectedMonth, setSelecetedMonth] = useState(getCurrentMonth());
  const [selectedYear, setSelectedYear] = useState(getCurrentYear());

  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);

  const onDateSelected = (day: Date) => {
    setCurrentDate(day);
    props.onDateSelected(day);
  };

  const firstDayInMonth = (): number => {
    // Create a new Date object for the first day of the given year and month
    let firstDay = new Date(selectedYear, selectedMonth - 1, 1);

    let dayIndex = firstDay.getDay();

    return dayIndex + 1;
  };

  const oneMonthBackward = () => {
    let newMonth = 0;
    newMonth = selectedMonth - 1;
    if (newMonth === 0) {
      newMonth = 12;
      setSelectedYear(selectedYear - 1);
    }

    setSelecetedMonth(newMonth);
  };

  const oneMonthForward = () => {
    let newMonth = 0;
    newMonth = selectedMonth + 1;
    if (newMonth === 13) {
      newMonth = 1;

      setSelectedYear(selectedYear + 1);
    }

    setSelecetedMonth(newMonth);
  };

  const getMonthName = (): string => {
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[selectedMonth - 1];
  };

  useEffect(() => {
    if (currentDate !== null && currentDate !== undefined) {
      setSelecetedMonth(currentDate.getMonth() + 1);
      setSelectedYear(currentDate.getFullYear());
    }
  }, [currentDate]);

  const isDateHovered = (date: Date): boolean => {
    if (hoveredDate === null || isMobile) return false;
    return (
      date.getDate() === hoveredDate.getDate() &&
      date.getMonth() === hoveredDate.getMonth() &&
      date.getFullYear() === hoveredDate.getFullYear()
    );
  };

  const isDateInPast = (date: Date): boolean => {
    if (props.allowPastDates) return false;
    if (isToday(date)) return false;
    const currentDate = new Date();
    return date < currentDate;
  };

  const isToday = (date: Date): boolean => {
    const today = new Date();

    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const getNumberOfDaysInMonth = (): number => {
    return new Date(selectedYear, selectedMonth, 0).getDate();
  };

  const onMouseDown = (e: React.MouseEvent) => {
    if (props.preventDefaultMouseDown) {
      e.preventDefault();
    }
  };

  const isCurrentDate = (day: Date): boolean => {
    if (!dateSelected()) return false;

    return (
      day.getFullYear() === currentDate!.getFullYear() &&
      day.getMonth() === currentDate!.getMonth() &&
      day.getDate() === currentDate!.getDate()
    );
  };

  const createGrid = (numberOfCells: number) => {
    const gridCells = [];
    let index = 1;
    while (index <= numberOfCells) {
      const dateRow = (
        <div style={{ display: "flex" }} key={index} className="date-row">
          {createDateCells(index)}
        </div>
      );
      gridCells.push(dateRow);
      index += 7;
    }
    return gridCells;
  };

  const getSelectedMonth = (): string => {
    return getMonthName() + " " + selectedYear;
  };

  const createDateCells = (index: number) => {
    //if (index > firstDayInMonth()) index = index - firstDayInMonth() + 1;

    const cells = [];
    let currentDay = index;
    if (currentDay > firstDayInMonth())
      currentDay = currentDay - firstDayInMonth() + 1;

    for (let j = 0; j < 7; j++) {
      let dayIndex: number = 0;
      const current_date = new Date(
        selectedYear,
        selectedMonth - 1,
        currentDay
      );

      if (
        index >= firstDayInMonth() &&
        currentDay <= getNumberOfDaysInMonth()
      ) {
        dayIndex = currentDay;
        currentDay++;
      }

      const isCurrent = isCurrentDate(
        new Date(selectedYear, selectedMonth - 1, dayIndex)
      );

      const cell = (
        <div
          onClick={() => {
            if (isDateInPast(current_date)) return;
            if (dayIndex !== 0) {
              onDateSelected(
                new Date(selectedYear, selectedMonth - 1, dayIndex)
              );
            }
          }}
          className="day_cell"
          onMouseEnter={() => {
            if (dayIndex !== 0) {
              setHoveredDate(
                new Date(selectedYear, selectedMonth - 1, dayIndex)
              );
            }
          }}
          onMouseLeave={() => setHoveredDate(null)}
          style={{
            padding: "10px",
            flex: 1,
            width: "20px",
            color: isCurrent
              ? themeContext?.buttonTheme.text
              : isToday(current_date)
              ? themeContext?.activeItemColor
              : themeContext?.textColor,
            borderRadius: dayIndex !== 0 && isCurrent ? "50px" : "",
            backgroundColor:
              dayIndex !== 0 && isCurrent
                ? themeContext?.calendarActiveDay
                : "",
            opacity:
              isDateInPast(current_date) || isDateHovered(current_date)
                ? "0.5"
                : "",
            fontWeight: isToday(current_date) ? "bold" : "",
            cursor:
              isDateInPast(current_date) || dayIndex === 0 ? "" : "pointer",
            textAlign: "center",
          }}
          key={j}
        >
          {dayIndex === 0 ? "" : dayIndex}
        </div>
      );
      cells.push(cell);
      index++;
    }
    return cells;
  };

  const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

  let numberOfCells: number = 35;

  if (firstDayInMonth() + getNumberOfDaysInMonth() > 36) numberOfCells = 42;
  else if (firstDayInMonth() + getNumberOfDaysInMonth() < 30)
    numberOfCells = 28;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: props.marginTop && `${props.marginTop}px`,
        marginBottom: props.marginBottom && `${props.marginBottom}px`,
      }}
      onMouseDown={(e) => onMouseDown(e)}
    >
      <div
        style={{
          display: "flex",
          fontWeight: "bold",
          padding: "10px",

          alignItems: "center",
        }}
      >
        <div style={{ width: "85px", color: themeContext?.textColor }}>
          {getSelectedMonth()}
        </div>

        <div>
          <Button
            id="month_backward"
            label=""
            textButton={true}
            iconComponent={<Icon backwardIcon={true} />}
            onClick={oneMonthBackward}
          />
        </div>

        <div>
          <Button
            id="month_forward"
            label=""
            iconComponent={<Icon forwardIcon={true} />}
            textButton={true}
            onClick={oneMonthForward}
          />
        </div>
      </div>
      <div style={{ display: "flex" }}>
        {daysOfWeek.map((day, index) => (
          <div
            key={index}
            style={{
              flex: 1,
              color: themeContext?.textColor,
              width: "20px",
              padding: "10px",
              textAlign: "center",
            }}
          >
            {day}
          </div>
        ))}
      </div>
      {createGrid(numberOfCells)}
    </div>
  );
};

export default Calendar;
