import React, { createContext, useState, ReactNode } from "react";
import { LocalStorage } from "../Util/localStorage";

interface ThemeProviderProps {
  children: ReactNode;
}

type textTheme = {
  text: string;
};

type pageBackground = {
  background: string;
};

type ErrorTheme = {
  background: string;
  text: string;
};

type ButtonTheme = {
  background: string;
  text: string;
};

type SnackbarTheme = {
  background: string;
  text: string;
  action: string;
};

type ThemeContextType = {
  errorTheme: ErrorTheme;
  taskHoverColor: string;
  listTaskBorder: string;
  taskDescriptionColor: string;
  activeItemColor: string;
  sidbarBackground: string;
  overlayText: string;
  sectionTextColor: string;
  overdueSectionTextColor: string;
  listNameColor: string;
  listPopupColor: string;
  listPopupTextColor: string;
  overlayBackground: string;
  sidebarRows: string;
  dueDatePassed: string;
  taskDraggingColor: string;
  isDarkTheme: boolean;
  checkBoxBorder: string;
  checkBoxBackground: string;
  deviderColor: string;
  flagColor: string;
  calendarActiveDay: string;
  headerBackground: string;
  sectionColor: string;
  overDueSectionColor: string;
  snackbar: SnackbarTheme;
  headerText: string;
  buttonTheme: ButtonTheme;
  iconColor: string;
  textColor: string;
  pageBackground: pageBackground;
  listBackground: string;
  textTheme: textTheme;
  toggleDarkTheme: () => void;
  setDarkMode: (dark: boolean) => void;

  // Chat-related parameters
  chatNewMsgPlaceholderColor: string;
  chatNewMsgSendButtonColor: string;
  chatTipBackgroundColor: string;
  chatBotTextColor: string;
  chatTipExampleColor: string;
  chatProminentTextColor: string;
  chatProminentColor: string;
  chatTipColorColumn: string;
  chatHighlightColor: string;
  chatUserBackgroundColor: string;
  chatUserTextColor: string;
  chatBotBackgroundColor: string;
  chatTipColor: string;
  chatSnackBarBackground: string;
  chatSnackBarText: string;
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const isDarkOS = () => {
    return (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    );
  };

  const isDarkTheme = (): boolean => {
    if (local_storage.getValue("THEME") === "2") return true;
    else if (local_storage.getValue("THEME") === "1") return false;

    return isDarkOS();
  };
  const local_storage = new LocalStorage();
  const [darkTheme, setDarkTheme] = useState(isDarkTheme()); // default to light theme

  const toggleDarkTheme = () => {
    setDarkTheme(!darkTheme);
  };

  const getBaseColorDark = () => {
    return getListBackground();
  };

  const getErrorTheme = (): ErrorTheme => {
    return {
      background: darkTheme ? "red" : "red",
      text: darkTheme ? "white" : "white",
    };
  };

  const getHeaderBackground = (): string => {
    return darkTheme ? "#171717" : "#f2f5fe";
  };

  const getFlagColor = (): string => {
    return darkTheme ? "red" : "red";
  };

  const getHeaderText = (): string => {
    return darkTheme ? "white" : "black";
  };

  const getCalendarActiveDayBackground = (): string => {
    return "#4CAEE3";
  };

  const getTaskDescriptionColor = (): string => {
    return darkTheme ? getActiveItemColor() : "#4D4D4D";
  };

  const getDueDatePassed = (): string => {
    return darkTheme ? "red" : "red";
  };

  const getOverlayTextColor = (): string => {
    return darkTheme ? "black" : "black";
  };

  const getListNameColor = (): string => {
    return darkTheme ? "cyan" : "blue";
  };

  const getActiveItemColor = (): string => {
    return darkTheme ? "#4CAEE3" : "blue";
  };

  const getPageBackground = (): pageBackground => {
    return {
      background: darkTheme ? getBaseColorDark() : "white",
    };
  };

  const getTextTheme = (): textTheme => {
    return {
      text: darkTheme ? "white" : "black",
    };
  };

  const getSidebarBackground = (): string => {
    return getHeaderBackground();
  };

  const getSidebarRows = (): string => {
    return darkTheme ? "white" : "black";
  };

  const getTaskHoverColor = (): string => {
    return darkTheme ? "#40414f" : "lightgray";
  };

  const getSectionColor = (): string => {
    return darkTheme ? "white" : "azure";
  };

  const getOverdueSectionTextColor = (): string => {
    return darkTheme ? "black" : "black";
  };

  const getOverdueSectionColor = (): string => {
    return darkTheme ? "pink" : "pink";
  };

  const getSectionTextColor = (): string => {
    return darkTheme ? "black" : "black";
  };

  const getDeviderColor = (): string => {
    return darkTheme ? getHeaderBackground() : getHeaderBackground();
  };

  const getTaskDraggedColor = (): string => {
    return darkTheme ? "lightgreen" : "lightgreen";
  };

  const getTextColor = (): string => {
    return darkTheme ? "white" : "black";
  };

  const getOverlayBackground = (): string => {
    return darkTheme ? getListBackground() : "white";
  };

  const getIconColor = (): string => {
    return darkTheme ? "pink" : "blue";
  };

  const getButtonTheme = (): ButtonTheme => {
    return {
      background: darkTheme ? getIconColor() : "#4CAEE3",
      text: darkTheme ? "black" : "black",
    };
  };

  const getSnackbarTheme = (): SnackbarTheme => {
    return {
      action: darkTheme ? getActiveItemColor() : "pink",
      background: darkTheme ? "white" : "#333",
      text: darkTheme ? "black" : "#fff",
    };
  };

  const getCheckBoxBorder = (): string => {
    return darkTheme ? "2px solid white" : "2px solid black";
  };

  const getCheckBoxBackground = (): string => {
    return getListBackground();
  };

  const getListBackground = () => {
    return darkTheme ? "#212121" : "white";
  };

  const getListTaskBorder = () => {
    return darkTheme ? "#40414f" : "lightgray";
  };

  const setDarkMode = (dark: boolean) => {
    setDarkTheme(dark);
  };

  // Chat theme helpers
  const getChatTheme = () => {
    return {
      chatNewMsgPlaceholderColor: darkTheme ? "#aaa" : "#555",
      chatNewMsgSendButtonColor: darkTheme ? "#4CAF50" : "black",
      chatTipBackgroundColor: darkTheme ? "#333" : "#f5f5f5",
      chatBotTextColor: darkTheme ? "#fff" : "#000",
      chatTipExampleColor: darkTheme ? "#ccc" : "#333",
      chatProminentTextColor: darkTheme ? "black" : "#1d1d1d",
      chatProminentColor: darkTheme ? "LimeGreen" : "LimeGreen",
      chatTipColorColumn: darkTheme ? "#333" : "#f0f0f0",
      chatHighlightColor: darkTheme ? "#ff6f61" : "#0044cc",
      chatUserBackgroundColor: darkTheme ? "#F3F2F0" : "#f2f5fe",
      chatUserTextColor: darkTheme ? "black" : "#000",
      chatBotBackgroundColor: darkTheme ? "#5C5FC8" : "#f8d7da",
      chatTipColor: darkTheme ? "#666" : "#aaa",
      chatSnackBarBackground: darkTheme ? "#333" : "#444",
      chatSnackBarText: darkTheme ? "#fff" : "#ccc",
    };
  };

  return (
    <ThemeContext.Provider
      value={{
        deviderColor: getDeviderColor(),
        overlayBackground: getOverlayBackground(),
        textColor: getTextColor(),
        snackbar: getSnackbarTheme(),
        listTaskBorder: getListTaskBorder(),
        activeItemColor: getActiveItemColor(),
        dueDatePassed: getDueDatePassed(),
        sidbarBackground: getSidebarBackground(),
        overlayText: getOverlayTextColor(),
        listNameColor: getListNameColor(),
        sidebarRows: getSidebarRows(),
        sectionColor: getSectionColor(),
        isDarkTheme: darkTheme,
        listPopupColor: getSidebarBackground(),
        listPopupTextColor: getSidebarRows(),
        flagColor: getFlagColor(),
        sectionTextColor: getSectionTextColor(),
        overdueSectionTextColor: getOverdueSectionTextColor(),
        overDueSectionColor: getOverdueSectionColor(),

        headerBackground: getHeaderBackground(),
        headerText: getHeaderText(),
        taskDescriptionColor: getTaskDescriptionColor(),
        taskHoverColor: getTaskHoverColor(),
        taskDraggingColor: getTaskDraggedColor(),
        errorTheme: getErrorTheme(),
        listBackground: getListBackground(),
        calendarActiveDay: getCalendarActiveDayBackground(),
        checkBoxBorder: getCheckBoxBorder(),
        textTheme: getTextTheme(),
        iconColor: getIconColor(),
        buttonTheme: getButtonTheme(),
        pageBackground: getPageBackground(),
        checkBoxBackground: getCheckBoxBackground(),
        toggleDarkTheme,
        setDarkMode,
        ...getChatTheme(), // Spread the chat parameters
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
