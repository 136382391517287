import React, { useContext, useState } from "react";

import { ThemeContext } from "../../../Context/Color";

import Tooltip from "@mui/material/Tooltip";

type HamburgerProps = {
  onClick: () => void;
  isMobileDevice: boolean;
  toolTopTitle: string;
};

const Hamburger = (props: HamburgerProps) => {
  const themeContext = useContext(ThemeContext);

  const [isHovered, setIsHovered] = useState(false);

  if (!themeContext) {
    throw new Error("ThemeContext not provided from a ThemeProvider component");
  }

  const getLineSyle = () => {
    return {
      border: "1px solid " + themeContext.textColor,
      marginBottom: "4px",
      width: "20px",
    };
  };

  return (
    <Tooltip enterDelay={1000} title={props.toolTopTitle} arrow>
      <div
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        onClick={props.onClick}
        style={{
          opacity: isHovered && !props.isMobileDevice ? 0.5 : 1,
          paddingTop: "18px",
          paddingBottom: "12px",
          paddingLeft: "18px",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",

          flexDirection: "column",
        }}
      >
        <div style={getLineSyle()}></div>
        <div style={getLineSyle()}></div>
        <div style={getLineSyle()}></div>
      </div>
    </Tooltip>
  );
};

export default Hamburger;
