import { Timestamp } from "firebase/firestore";
import {
  addDocumentToFirestore,
  addMultipleDocumentsToFirestore,
  updateMultipleDocumentsInFirestore,
  updateDocumentInFirestore,
} from "./Crud";
import { DueDateReminder } from "./DueDateReminder";

export interface Task {
  deleted?: boolean;
  dueDateReminderMinutes?: number;
  dueDateReminderDays?: number;
  dueDateReminderHours?: number;
  dueDateReminderChanged?: boolean;
  customDueDateReminder?: boolean;
  dueDateReminderOnTime?: boolean;
  dueDateReminderHoursBefore?: boolean;
  dueDateReminderMinutesBefore?: boolean;
  dueDateReminderDaysBefore?: boolean;
  dueDateReminderTime?: string;
  onTimeCronID?: string;
  hoursBeforeCronID?: string;
  minutesBeforeCronID?: string;
  daysBeforeCronID?: string;
  daysBeforeCronID2?: string;
  draft?: boolean;
  description?: string;
  systemCreated?: boolean;
  done?: boolean;
  name: string;
  owner?: string;
  priority?: number;
  project?: string;
  desktopNotifications?: boolean;
  pushNotifications?: boolean;
  emailNotifications?: boolean;
  id?: string;
  utcOffset?: number;
  timeZoneName?: string;
  dueDateWithTime?: boolean;
  created: Timestamp;
  updated: Timestamp;
  dueDate?: Timestamp | null; // Nullable Timestamp
}

export function fromJson(json: Task, sDocID: string): Task {
  return {
    name: json.name,
    done: json.done,
    deleted: json.deleted,
    owner: json.owner,
    priority: json.priority,
    dueDateReminderMinutes: json.dueDateReminderMinutes ?? 0,
    dueDateReminderDays: json.dueDateReminderDays ?? 0,
    dueDateReminderHours: json.dueDateReminderHours ?? 0,
    dueDateReminderChanged: json.dueDateReminderChanged ?? false,
    customDueDateReminder: json.customDueDateReminder ?? false,
    dueDateReminderHoursBefore: json.dueDateReminderHoursBefore ?? false,
    dueDateReminderOnTime: json.dueDateReminderOnTime ?? false,
    dueDateReminderDaysBefore: json.dueDateReminderDaysBefore ?? false,
    dueDateReminderMinutesBefore: json.dueDateReminderMinutesBefore ?? false,
    dueDateReminderTime: json.dueDateReminderTime ?? "08:30",
    onTimeCronID: json.onTimeCronID ?? "",
    hoursBeforeCronID: json.hoursBeforeCronID ?? "",
    minutesBeforeCronID: json.minutesBeforeCronID ?? "",
    daysBeforeCronID: json.daysBeforeCronID ?? "",
    daysBeforeCronID2: json.daysBeforeCronID2 ?? "",
    project: json.project,
    description: json.description ?? "",
    timeZoneName: json.timeZoneName ?? "",
    utcOffset: json.utcOffset ?? 0,
    dueDateWithTime: json.dueDateWithTime ?? false,
    desktopNotifications: json.desktopNotifications ?? true,
    emailNotifications: json.emailNotifications ?? true,
    pushNotifications: json.pushNotifications ?? true,
    systemCreated: json.systemCreated ?? false,
    draft: json.draft ?? false,
    id: sDocID,
    created: json.created ?? new Date(),
    updated: json.updated ?? new Date(),
    dueDate: json.dueDate || null, // You need to handle the parsing or setting null in the place where you call this function
  };
}

export function areTasksEqual(task1: Task, task2: Task): boolean {
  // A list of properties to compare.
  // This ensures we're only comparing properties we care about and not potential extraneous ones.
  if (!task1 || !task2) return false;
  const properties: (keyof Task)[] = [
    "deleted",
    "draft",
    "description",
    "systemCreated",
    "done",
    "name",
    "owner",
    "priority",
    "desktopNotifications",
    "emailNotifications",
    "pushNotifications",
    "dueDateReminderChanged",
    "customDueDateReminder",
    "dueDateReminderOnTime",
    "dueDateReminderHoursBefore",
    "dueDateReminderMinutesBefore",
    "dueDateReminderDaysBefore",
    "dueDateReminderMinutes",
    "dueDateReminderDays",
    "dueDateReminderHours",
    "dueDateReminderTime",
    "onTimeCronID",
    "hoursBeforeCronID",
    "minutesBeforeCronID",
    "daysBeforeCronID",
    "daysBeforeCronID2",
    "project",
    "id",
    "utcOffset",
    "timeZoneName",
    "dueDateWithTime",
    "created",
    "updated",
    "dueDate",
  ];

  for (const prop of properties) {
    if (prop === "created" || prop === "updated" || prop === "dueDate") {
      // For Timestamps, we compare their milliseconds representation.
      if (task1[prop]?.toMillis() !== task2[prop]?.toMillis()) {
        return false;
      }
    } else if (task1[prop] !== task2[prop]) {
      // For all other properties, we can use direct comparison.
      return false;
    }
  }

  return true;
}

function toJson(task: Task) {
  return {
    name: task.name ?? "",
    done: task.done ?? false,
    deleted: task.deleted ?? false,
    owner: task.owner ?? "",
    priority: task.priority ?? 3,
    project: task.project ?? "",
    description: task.description ?? "",
    timeZoneName: task.timeZoneName ?? "",
    utcOffset: task.utcOffset ?? 0,
    dueDateWithTime: task.dueDateWithTime ?? false,
    systemCreated: task.systemCreated ?? false,
    customDueDateReminder: task.customDueDateReminder ?? false,
    dueDateReminderChanged: task.dueDateReminderChanged ?? false,
    dueDateReminderOnTime: task.dueDateReminderOnTime ?? false,
    dueDateReminderHoursBefore: task.dueDateReminderHoursBefore ?? false,
    dueDateReminderMinutesBefore: task.dueDateReminderMinutesBefore ?? false,
    dueDateReminderDaysBefore: task.dueDateReminderDaysBefore ?? false,
    dueDateReminderMinutes: task.dueDateReminderMinutes ?? 0,
    dueDateReminderDays: task.dueDateReminderDays ?? 0,
    dueDateReminderHours: task.dueDateReminderHours ?? 0,
    dueDateReminderTime: task.dueDateReminderTime ?? "08:30",
    onTimeCronID: task.onTimeCronID ?? "",
    hoursBeforeCronID: task.hoursBeforeCronID ?? "",
    minutesBeforeCronID: task.minutesBeforeCronID ?? "",
    daysBeforeCronID: task.daysBeforeCronID ?? "",
    daysBeforeCronID2: task.daysBeforeCronID2 ?? "",

    desktopNotifications: task.desktopNotifications ?? true,
    emailNotifications: task.emailNotifications ?? true,
    pushNotifications: task.pushNotifications ?? true,

    draft: task.draft ?? false,
    created: task.created ?? Timestamp.now(),
    updated: task.updated ?? Timestamp.now(),
    dueDate: task.dueDate ?? null,
  }; // You need to handle the parsing or setting null in the place where you call this function
}

export type TaskUpdate = Partial<Task>;

export const createTask = async (
  taskReocrd: Task,
  userID: string
): Promise<string> => {
  return addDocumentToFirestore(toJson(taskReocrd), `users/${userID}/tasks`);
};

export const createMultipleTasks = async (
  tasks: Task[],
  userID: string
): Promise<string[]> => {
  let taskObj: Task[] = [];
  tasks.forEach((task) => {
    taskObj.push(toJson(task));
  });
  return addMultipleDocumentsToFirestore(taskObj, `users/${userID}/tasks`);
};

export const flagTask = async (
  flag: boolean,
  userID: string,
  taskID: string
) => {
  updateTask(userID, taskID, {
    priority: flag ? 1 : 3,
  });
};

export const moveTask = async (
  projectID: string,
  userID: string,
  taskID: string
) => {
  updateTask(userID, taskID, {
    project: projectID,
  });
};

export const updatePushNotifications = async (
  pushNotifications: boolean,
  userID: string,
  taskID: string
) => {
  updateTask(userID, taskID, {
    pushNotifications: pushNotifications,
  });
};

export const updateDesktopNotifications = async (
  desktop: boolean,
  userID: string,
  taskID: string
) => {
  updateTask(userID, taskID, {
    desktopNotifications: desktop,
  });
};

export const updateEmailNotifications = async (
  email: boolean,
  userID: string,
  taskID: string
) => {
  updateTask(userID, taskID, {
    emailNotifications: email,
  });
};

export const updateTaskName = async (
  name: string,

  userID: string,
  taskID: string
) => {
  updateTask(userID, taskID, {
    name: name,
  });
};

export function dateToTimeString(date: Date): string {
  const hours = date.getHours().toString().padStart(2, "0"); // Ensures two digits
  const minutes = date.getMinutes().toString().padStart(2, "0"); // Ensures two digits
  return `${hours}:${minutes}`;
}

export const stringToTime = (time: string): Date => {
  // Create a new Date object for the current date
  const now = new Date();

  // Extract hours and minutes from the time string
  const [hour, minute] = time.split(":").map(Number);

  // Create a new Date object with the extracted hours and minutes
  const selectedTime = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    hour,
    minute
  );

  // Set seconds and milliseconds to zero for precision
  selectedTime.setSeconds(0);
  selectedTime.setMilliseconds(0);

  return selectedTime;
};

export const updateDueDate = async (
  hasTime: boolean,
  dueDate: Timestamp | null,
  userID: string,
  taskID: string,
  dueDateReminders: DueDateReminder
) => {
  updateTask(userID, taskID, {
    dueDateWithTime: hasTime,
    dueDate: dueDate,
    dueDateReminderMinutes: dueDateReminders.selectedMinutesBefore,
    dueDateReminderHours: dueDateReminders.selectedHoursBefore,
    dueDateReminderDays: dueDateReminders.selectedDaysBefore,
    dueDateReminderChanged: dueDateReminders.dueDateReminderChanged,
    customDueDateReminder: dueDateReminders.customDueDateReminder,
    dueDateReminderOnTime: dueDateReminders.exactlyOnTime,
    dueDateReminderHoursBefore: dueDateReminders.hoursBefore,
    dueDateReminderMinutesBefore: dueDateReminders.minutesBefore,
    dueDateReminderDaysBefore: dueDateReminders.daysBefore,
    dueDateReminderTime: dateToTimeString(dueDateReminders.selectedTime),
  });
};

export const taskCompleted = async (
  completed: boolean,
  userID: string,
  taskID: string
) => {
  updateTask(userID, taskID, {
    done: completed,
  });
};

export const deleteTask = async (userID: string, taskID: string) => {
  updateTask(userID, taskID, {
    deleted: true,
  });
};

export const updateTaskDetails = async (
  taskName: string,
  description: string,
  userID: string,
  taskID: string
) => {
  updateTask(userID, taskID, {
    name: taskName,
    description: description,
  });
};

export const restoreAllTasks = async (userID: string, tasks: Task[]) => {
  // Prepare tasks data for updateMultipleDocumentsInFirestore
  const tasksToUpdate = tasks
    .filter((task) => task.id !== undefined) // filter out tasks without id
    .map((task) => ({
      id: task.id as string, // since we filtered out undefined ids, we can now assert that id is a string
      data: { ...task, done: false }, // the rest of the task data, with 'done' set to false
    }));

  try {
    await updateMultipleDocumentsInFirestore(
      tasksToUpdate,
      `users/${userID}/tasks`
    );
    // console.log("Tasks restored successfully");
  } catch (e) {
    console.error("Error restoring tasks", e);
  }
};

export const createTrainingTasks = (projectID: string, userID: string) => {
  let training: string[] = [];
  let tasks: Task[] = [];

  training.push("⏰ Click on a task to add reminders");
  training.push("🖱️ Right click on a task for more options");
  training.push(
    "⏰ Add a recurring reminder to get email alerts and mobile push notifications"
  );
  training.push("🚩 flag a task to move it to top of the list");
  training.push(
    "🤗 To reorder tasks, drag and drop each task to its new position"
  );
  training.push("📱 Install the Mobile Application");
  training.push("📝 Click on a list name to edit it");
  training.push("🗒️ Click on a task to add description");
  training.push("📋 Copy and paste a few lines to add a few tasks at once!");
  training.push("👈 Click the button on the top left side to add new lists");
  training.push("☝️ Click the 3 dots above to view completed tasks");
  training.push("📋 Click on a task to copy it's content to the clipboard");

  for (let name of training) {
    const t: Task = {
      created: Timestamp.now(),
      updated: Timestamp.now(),
      systemCreated: true,
      project: projectID,
      name: name,
    };

    if (
      t.name ===
      "⏰ Add a recurring reminder to get email alerts and mobile push notifications"
    )
      t.description = "Example:Pick up the laundry every Tuesday on 18:00";
    else if (t.name === "📱 Install the Mobile Application") {
      t.description =
        "🤖Android App: https://play.google.com/store/apps/details?id=com.keeplist\n\n🍎 iPhone App: https://apps.apple.com/sa/app/keeplist/id1626699174?platform=iphone";
    }

    tasks.push(toJson(t));

    console.log(tasks);
  }

  addMultipleDocumentsToFirestore(tasks, `users/${userID}/tasks`);
};

const updateTask = async (userID: string, taskID: string, doc: TaskUpdate) => {
  if (!userID || !taskID) {
    return;
  }

  doc.updated = Timestamp.now();

  try {
    await updateDocumentInFirestore(doc, `users/${userID}/tasks`, taskID);
    //   console.log("Order updated in Firestore");
  } catch (error) {
    console.error("Error updating order in Firestore", error);
  }
};
