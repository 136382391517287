import React, {
  forwardRef,
  FocusEventHandler,
  useState,
  useEffect,
  useContext,
} from "react";
import "./Style.css";
import { ThemeContext } from "../../../Context/Color";
import Icon from "../Icon/Icon";

type EditBoxProps = {
  type: string;
  maxLength?: number;
  marginTop?: string;
  marginBottom?: string;
  resetValue?: boolean;
  placeholder: string;
  initialValue?: string;
  disableBorder?: boolean;
  iconComponent?: React.ReactNode;
  padding?: number;
  id: string;
  focus: boolean;
  readOnly?: boolean;
  expandVertical?: boolean;
  backgroundWhenClosed?: boolean;
  onEnterPress?: () => void;
  onLengthExceeded?: () => void;
  onInputChange?: (value: string) => void;
  onLengthOK?: () => void;
  onPaste?: (event: React.ClipboardEvent) => void;
  EditRef: any;
  fontSize?: number;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>; // Update to FocusEventHandler
};

const EditBox = forwardRef<HTMLInputElement, EditBoxProps>((props, ref) => {
  const inputRef = props.EditRef || ref || React.createRef<HTMLInputElement>();

  const themeContext = useContext(ThemeContext);

  const [focus, setFocus] = useState(props.focus);

  const [inputType, setInputType] = useState(props.type);
  const [showPass, setShowPass] = useState(false);

  const [editValue, setEditValue] = useState(props.initialValue);

  useEffect(() => {
    if (props.resetValue) {
      setEditValue(""); // Reset the value
      if (inputRef.current) {
        inputRef.current.focus(); // Focus the input
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.resetValue]);

  const handleChange = (event: any) => {
    // Create a change handler
    if (props.maxLength != null && event.target != null) {
      if (event.target.value.length === props.maxLength) {
        if (props.onLengthExceeded) {
          props.onLengthExceeded();
          return;
        }
      } else {
        if (props.onLengthOK) props.onLengthOK();
      }
    }

    if (props.onInputChange) props.onInputChange(event.target.value);

    setEditValue(event.target.value); // Update the state when the input changes
  };

  const handleKeyDown = (event: any) => {
    if (!props.onEnterPress) return;

    if (event.key === "Enter") {
      event.preventDefault();
      if (props.onEnterPress) {
        props.onEnterPress();
      }
    }
  };

  useEffect(() => {
    if (props.type === "password") setInputType(showPass ? "text" : "password");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPass]);

  useEffect(() => {
    if (props.EditRef.current && props.expandVertical) {
      if (props.focus) props.EditRef.current.focus(); // Focus the textarea when the component mounts
      const length = props.EditRef.current.value.length;
      props.EditRef.current.setSelectionRange(length, length); // Set cursor to the end of the text
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditBoxFocus = (e: any) => {
    if (props.onFocus) props.onFocus(e);
    setFocus(true);
  };

  const onEditBoxBlur = (e: any) => {
    setFocus(false);
  };

  const onTextAreaFocus = (e: any) => {
    if (props.onFocus) props.onFocus(e);
  };

  const onTextAreaBlur = (e: any) => {
    if (props.onBlur) props.onBlur(e);
  };

  const onIconClick = () => {
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the input
    }
  };

  const onShowPassword = () => {
    setShowPass(!showPass);
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the input
    }
  };

  useEffect(() => {
    if (props.expandVertical && props.EditRef.current) {
      const computedStyle = window.getComputedStyle(props.EditRef.current);
      const verticalPadding =
        parseFloat(computedStyle.getPropertyValue("padding-top")) +
        parseFloat(computedStyle.getPropertyValue("padding-bottom"));

      props.EditRef.current.style.height = "auto"; // Reset height to 'auto'
      const scrollHeight = props.EditRef.current.scrollHeight;
      props.EditRef.current.style.height = `${
        scrollHeight - verticalPadding
      }px`;
    }
  }, [editValue, props.EditRef, props.expandVertical]);

  return props.expandVertical ? (
    <textarea
      className={
        props.disableBorder
          ? themeContext?.isDarkTheme
            ? props.backgroundWhenClosed
              ? "no_borders_dark_with_background"
              : "no_borders_dark"
            : props.backgroundWhenClosed
            ? "no_borders_with_background"
            : " no_borders"
          : "normal"
      }
      id={props.id}
      ref={inputRef}
      onFocus={onTextAreaFocus}
      onChange={handleChange}
      onPaste={props.onPaste && props.onPaste}
      onBlur={onTextAreaBlur}
      autoFocus={props.focus}
      readOnly={props.readOnly}
      onKeyDown={handleKeyDown}
      placeholder={props.placeholder}
      maxLength={props.maxLength}
      rows={1}
      value={editValue}
      style={{
        marginTop: props.marginTop && props.marginTop,
        marginBottom: props.marginBottom && props.marginBottom,
        fontFamily: "Calibri",
        padding: props.padding && `${props.padding}px`,
        fontSize: props.fontSize && `${props.fontSize}px`,
        outline: "none",
        resize: "none",
        //  border: props.disableBorder ? "none" : "1px solid black",
      }}
    ></textarea>
  ) : (
    <div
      style={{
        marginTop: props.marginTop && props.marginTop,
        marginBottom: props.marginBottom && props.marginBottom,
        display: "flex",
      }}
    >
      {props.iconComponent && (
        <div
          onClick={onIconClick}
          style={{
            cursor: "text",
            padding: props.padding || "8px",
            borderBottom: focus
              ? "3px solid " + themeContext?.iconColor
              : "3px solid gray",
            marginBottom: "2px",
          }}
        >
          {props.iconComponent}
        </div>
      )}
      <input
        onFocus={onEditBoxFocus}
        onBlurCapture={onEditBoxBlur}
        autoFocus={props.focus}
        className={
          props.disableBorder
            ? themeContext?.isDarkTheme
              ? "no_borders_dark"
              : "no_borders"
            : "normal"
        }
        value={editValue}
        type={inputType}
        placeholder={props.placeholder}
        id={props.id}
        readOnly={props.readOnly}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        maxLength={props.maxLength}
        style={{
          width: "100%",
          fontSize: props.fontSize ? `${props.fontSize}px` : "16px",
          padding: props.padding || "8px",
          outline: "none",
          fontFamily: "Calibri",
        }}
        onBlur={props.onBlur}
        onChange={handleChange}
      />
      {props.type === "password" && (
        <div
          onClick={onShowPassword}
          style={{
            cursor: "pointer",
            padding: props.padding || "8px",
            borderBottom: focus
              ? "3px solid " + themeContext?.iconColor
              : "3px solid gray",
            marginBottom: "2px",
          }}
        >
          <Icon
            color={themeContext?.textColor}
            showIcon={!showPass}
            hideIcon={showPass}
          />
        </div>
      )}
    </div>
  );
});

export default EditBox;
