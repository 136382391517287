import React from "react";
import logoImage from "../../../assets/login_page_logo.png";
import logoNoText from "../../../assets/header_logo.png";

type LogoProps = {
  alignCenterY: boolean;
  alignCenterX: boolean;
  smallLogo: boolean;
  color: string;
};

const Logo = (props: LogoProps) => {
  // Set either width or height to 25% and the other to 'auto' to maintain proportions
  const imageStyle = props.smallLogo ? { width: "15%", height: "auto" } : {};

  const textStyle = {
    fontSize: "20px",
    color: props.color,
    fontFamily: "Calibri, sans-serif", // Calibri font
    letterSpacing: "1px", // Slightly increased letter spacing
    fontWeight: "bold", // Bold font
    marginLeft: "10px", // Space between logo and text
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)", // Text shadow for depth

    padding: "5px", // Padding for better spacing
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: props.alignCenterY ? "center" : "initial",
        justifyContent: props.alignCenterX ? "center" : "initial",
      }}
    >
      {!props.smallLogo && (
        <img src={logoImage} alt="Logo" style={imageStyle} />
      )}
      {props.smallLogo && (
        <img src={logoNoText} alt="Logo" style={imageStyle} />
      )}
      {props.smallLogo && (
        <div style={textStyle}> Keeplist - Good Day. Every Day. 🚀</div>
      )}
    </div>
  );
};

export default Logo;
