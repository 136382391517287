import Tooltip from "@mui/material/Tooltip";
import logoImage from "../../../assets/header_logo.png";

type LogoProops = {
  width: string;
  height: string;
  textColor: string;
  onClick: () => void;
};

const LogoHeader = (props: LogoProops) => {
  return (
    <Tooltip arrow placement="right" enterDelay={1000} title="Navigate Home">
      <div
        onClick={() => {
          props.onClick();
        }}
        style={{
          marginLeft: "8px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <img
            width={props.width}
            height={props.height}
            src={logoImage}
            alt="Logo"
          />
        </div>
        <div
          style={{
            display: "flex",
            color: props.textColor,
            fontSize: "24px",
            alignItems: "center",
          }}
        >
          Keeplist
        </div>
      </div>
    </Tooltip>
  );
};

export default LogoHeader;
