import Button from "../../Atoms/Button/Button";
import { ThemeContext } from "../../../Context/Color";
import { useContext } from "react";
import Icon from "../../Atoms/Icon/Icon";

type SaveCancelProps = {
  onSave: () => void;
  onCancel: () => void;
  saveLabel?: string;
  cancelLabel?: string;
};

const SaveCancel = (props: SaveCancelProps) => {
  const themeContext = useContext(ThemeContext);

  return (
    <div
      style={{
        flex: 1,
        alignItems: "flex-end",
        justifyContent: "flex-end",
        display: "flex",
      }}
    >
      <Button
        id="REMINDERS_CANCEL"
        label={props.cancelLabel || "Cancel"}
        marginRight={8}
        textButtonColor={themeContext?.textColor}
        textButton={true}
        onClick={() => {
          props.onCancel();
        }}
      />
      <Button
        id="REMINDERS_SAVE"
        iconComponent={<Icon sendIcon={true} />}
        label={props.saveLabel || "Save"}
        textButton={false}
        onClick={() => {
          props.onSave();
        }}
      />
    </div>
  );
};

export default SaveCancel;
