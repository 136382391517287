import "./Style.css";
import { ThemeContext } from "../../../Context/Color";
import { useContext, useEffect, useState } from "react";

import UserDetails from "../UserDetails/UserDetails";

import Hamburger from "../../Atoms/Hamburger/Hamburger";
import LogoHeader from "../../Atoms/LogoHeader/LogoHeader";
import Button from "../../Atoms/Button/Button";
import { useNavigate } from "react-router-dom";
import ThemePicker from "../../Molecules/ThemePicker/ThemePicker";
import Icon from "../../Atoms/Icon/Icon";

type HeaderProps = {
  onUserClick: () => void; // Define the prop function type
  onDrawer: () => void;
  isDrawerOpended: boolean;
  userID: string;
  sidebarVisible: boolean;
  isMobile: boolean;
  isAdmin?: boolean;
  isAdminBoard: boolean;
  isMobileDevice: boolean;
  userPicture?: string | null;
  onHideSideBar: () => void;
  onGoHome: () => void;
};

const AppHeader = (props: HeaderProps) => {
  const themeContext = useContext(ThemeContext);

  const navigate = useNavigate();

  const [drawerOpen, setDrawerOpen] = useState(props.isDrawerOpended);

  const onDrawer = () => {
    if (props.isMobile) {
      setDrawerOpen(!drawerOpen);
      props.onDrawer();
    } else {
      props.onHideSideBar();
    }
  };

  useEffect(() => {
    setDrawerOpen(props.isDrawerOpended);
    // setDataReady(dataProvider.isDataReady());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isDrawerOpended]);

  return (
    <div
      style={{
        backgroundColor:
          props.isMobile && !props.isDrawerOpended
            ? themeContext?.listBackground
            : themeContext?.headerBackground,
        color: themeContext?.headerText,
        justifyContent: "space-between",
        borderBottom: props.isMobile
          ? ""
          : "1px solid " + themeContext?.deviderColor,
        alignItems: "center",
      }}
      className="head"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        {!props.isAdminBoard && (
          <Hamburger
            isMobileDevice={props.isMobileDevice}
            onClick={onDrawer}
            toolTopTitle={
              props.isMobile
                ? drawerOpen
                  ? "Hide navigation menu"
                  : "Show navigation menu"
                : props.sidebarVisible
                ? "Hide navigation menu"
                : "Show navigation menu"
            }
          />
        )}
        {!props.isMobile && (
          <LogoHeader
            onClick={props.onGoHome}
            width={"64px"}
            height={"51px"}
            textColor={themeContext?.textColor!}
          />
        )}
      </div>

      {(props.isDrawerOpended || !props.isMobile) && (
        <div
          style={{
            display: "flex",
            fontSize: "24px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {" "}
        </div>
      )}

      <div style={{ display: "flex" }}>
        {props.isAdmin && (
          <Button
            id="admin_data"
            marginRight={16}
            label={props.isAdminBoard ? "Exit Admin Board" : "Admin Board"}
            textButton={true}
            onClick={() => {
              if (props.isAdminBoard) navigate("/list/home");
              else navigate(`/admin`);
            }}
          />
        )}
        <Button
          id={"BTN_CHAT"}
          label={"CHAT"}
          fontSize={"22px"}
          marginRight={12}
          iconComponent={<Icon chatIcon={true} />}
          textButton={true}
          onClick={() => {
            navigate(`/chat`);
          }}
        />
        {!props.isMobile && <ThemePicker />}
        {!props.isMobile && (
          <UserDetails
            textColor={themeContext?.headerText!}
            onUserClick={props.onUserClick}
            pictureSize="40px"
            padding="6px"
            picture={props.userPicture}
          />
        )}
      </div>
    </div>
  );
};

export default AppHeader;
