import Icon from "../../Atoms/Icon/Icon";
import { useSnackbar } from "../../../Context/SnackbarProvider";

import PopupMenu from "../PopupMenu/PopupMenu";
import { ThemeContext } from "../../../Context/Color";
import React, { useContext, useEffect, useState } from "react";
import {
  updateProjectPinned,
  deleteProject,
  Project,
  updateProjectShowDetails,
  updateProjectShowReminders,
} from "../../../Data/Project";

import EditDlg from "../../Organizm/EditDlg/EditDlg";
import { updateProjectName } from "../../../Data/Project";
import WarnDlg from "../../Organizm/WarnDlg/WarnDlg";
import { useNavigate } from "react-router-dom";
import Button from "../../Atoms/Button/Button";

import {
  createMultipleTasks,
  createTask,
  dateToTimeString,
  Task,
} from "../../../Data/Task";
import { Timestamp } from "firebase/firestore";
import { useOverlay } from "../../../Context/OverlayProvider";
import NewTask from "../../../Pages/NewTask/NewTask";
import { createReminder, Reminder } from "../../../Data/Reminder";
import { useData } from "../../../Context/Data";
import Tooltip from "@mui/material/Tooltip";
import { DueDateReminder } from "../../../Data/DueDateReminder";

type ListTitleProps = {
  id: string;
  userID: string;
  selectList: boolean;
  userTimeZone: string;
  count: number;
  color: string;
  onRestoreTasks: () => void;
  onShowCompleted: (show: boolean) => void;
  fontSize: string;
  isMobile: boolean;
  todayView: boolean;
  upcomingView: boolean;
  width: string;
  list: Project;
  listRef: React.RefObject<HTMLDivElement>;
};

const List = (props: ListTitleProps): JSX.Element => {
  // sort tasks according to the order of task ids in props.order, keeping the original order for those not in props.order
  const themeContext = useContext(ThemeContext);

  const dataProvider = useData();

  const [completedMode, setCompletedMode] = useState(false);

  const { addOverlay, removeOverlay } = useOverlay();
  const { addSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const addTask = async (t: Task, reminders: Reminder[]) => {
    dataProvider.addTempTask(t);

    try {
      let taskID = await createTask(t, props.userID);
      if (taskID !== null) dataProvider.removeTempTask();

      if (taskID && reminders.length > 0) {
        // Add taskID to each reminder object
        reminders.forEach((reminder) => {
          reminder.taskID = taskID; // Assuming that the Reminder type has a taskID field
        });

        // Now create each reminder (assuming createReminder function returns a Promise)
        await Promise.all(
          reminders.map((reminder) => {
            return createReminder(reminder, props.userID); // Replace with your actual reminder creation function
          })
        );
      }

      const isScrolledDown = props.listRef.current
        ? props.listRef.current.scrollTop > 0
        : false;

      if (props.todayView || props.upcomingView) {
        if (
          t.dueDate !== null &&
          !dataProvider.isCalendarTaskOnTop(
            t.dueDate!.toDate(),
            t.dueDateWithTime!
          )
        ) {
          if (!props.todayView || isToday(t.dueDate!.toDate())) {
            addSnackbar("Task added 👇", 8);
          }
        }
      }

      if (isScrolledDown) {
        // Show the snackbar message if the scrollbar is not at the top
        addSnackbar("Task added ☝️", 8, "Show", () => {
          if (props.listRef.current) {
            props.listRef.current.scrollTop = 0;
          }
        });
      }
    } catch (error) {
      dataProvider.removeTempTask();
    }

    // If the function reaches this point, it means a task has been created, so return true.
    return true;
  };

  function isToday(date: Date): boolean {
    if (!date) return false;
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  const getListName = (listID: string) => {
    const projects = dataProvider.getLists();

    const project = projects.find((p) => p.id === listID);

    return project ? project.name : "";
  };

  const handleSaveTask = async (
    TaskName: string,
    selectedList: string,
    flagged: boolean,
    description: string,
    mobileNotification: boolean,
    desktopNotifications: boolean,
    emailNotifications: boolean,
    dueDateHasTime: boolean,
    reminders: Reminder[],
    dueDateReminder: DueDateReminder,
    dueDate?: Date,
    multiple?: boolean
  ): Promise<boolean> => {
    let val: string = TaskName;
    val = val.trim();

    description = description.trim();

    // If the task name is empty or only whitespace, return false.
    if (val === "") return false;

    const t: Task = {
      created: Timestamp.now(),
      updated: Timestamp.now(),
      deleted: false,
      description: description,
      desktopNotifications: desktopNotifications,
      emailNotifications: emailNotifications,
      pushNotifications: mobileNotification,
      dueDate:
        dueDate !== null && dueDate !== undefined
          ? Timestamp.fromDate(dueDate)
          : null,
      dueDateWithTime: dueDateHasTime,
      systemCreated: false,
      priority: flagged ? 1 : 3,
      project: props.todayView || props.upcomingView ? selectedList : props.id,
      name: val,
      dueDateReminderMinutes: dueDateReminder.selectedMinutesBefore,
      dueDateReminderHours: dueDateReminder.selectedHoursBefore,
      dueDateReminderDays: dueDateReminder.selectedDaysBefore,
      dueDateReminderChanged: false,
      customDueDateReminder: dueDateReminder.customDueDateReminder,
      dueDateReminderOnTime: dueDateReminder.exactlyOnTime,
      dueDateReminderHoursBefore: dueDateReminder.hoursBefore,
      dueDateReminderMinutesBefore: dueDateReminder.minutesBefore,
      dueDateReminderDaysBefore: dueDateReminder.daysBefore,
      dueDateReminderTime: dateToTimeString(dueDateReminder.selectedTime),
    };

    let emptyLines = 0;

    if (multiple) {
      const arr = val.split(/\r?\n/);
      let tasks: Task[] = [];

      let delay = 0; // Start with no delay

      for (let i = arr.length - 1; i >= 0; i--) {
        const line = arr[i];
        if (line !== "") {
          const currentDate = new Date(); // Get the current date
          currentDate.setSeconds(currentDate.getSeconds() + delay / 1000); // Add the delay in seconds

          const ts = Timestamp.fromDate(currentDate); // Convert the Date object to a Timestamp

          const t: Task = {
            created: ts,
            updated: ts,
            description: description,
            dueDate:
              dueDate !== null && dueDate !== undefined
                ? Timestamp.fromDate(dueDate)
                : null,
            dueDateWithTime: dueDateHasTime,
            systemCreated: false,
            priority: flagged ? 1 : 3,
            project: props.id,
            desktopNotifications: desktopNotifications,
            emailNotifications: emailNotifications,
            pushNotifications: mobileNotification,
            deleted: false,
            name: line,
          };
          tasks.push(t);

          delay += 1000; // Increase the delay by 2 seconds for the next iteration
        } else emptyLines++;
      }

      createMultipleTasks(tasks, props.userID);
      addSnackbar(`😊 ${arr.length - emptyLines} tasks added`);
      return true;
    }

    if (props.upcomingView && !dueDate) {
      addSnackbar(
        "Task does not have a due date - added to " + getListName(selectedList)
      );
    } else if (props.todayView && !isToday(dueDate!)) {
      addSnackbar(
        "Task is not due today - added to " + getListName(selectedList)
      );
    }

    return addTask(t, reminders);
  };

  useEffect(() => {
    setCompletedMode(false);
  }, [props.id]);

  if (props.list == null && !props.todayView && !props.upcomingView)
    return <></>;

  let poupUpTextSize: number = 24;

  const onEditList = () => {
    addOverlay(
      <EditDlg
        isMobile={props.isMobile}
        header="List Name"
        placeHolder=""
        saveBtnText="Save"
        allowEmptyValue={false}
        maxLength={150}
        expandVertical={true}
        initialValue={props.list.name}
        cancelBtnText="Cancel"
        emailValidation={false}
        headerSize={24}
        onSave={(listName: string) => {
          removeOverlay();
          updateProjectName(props.userID, props.id, listName);
        }}
        onCancel={() => {
          removeOverlay();
        }}
      />,
      {
        closeOnClickOutside: false,
        centerX: true,
        width: "100%",
        marginTop: 8,
      }
    );
  };
  const onDeleteList = () => {
    addOverlay(
      <WarnDlg
        onCancel={() => {
          removeOverlay();
        }}
        onApprove={() => {
          navigate(`/list/` + dataProvider.getHomeProjectID());
          removeOverlay();
          deleteProject(props.userID, props.id);
        }}
        icon={<Icon deleteIcon={true} />}
        isMobile={props.isMobile}
        applyBtnRed={true}
        title="Are you sure?"
        subTitle={`"${props.list.name}" will be deleted forever.`}
        cancelBtnText="CANCEL"
        approveBtnText="YES, DELETE IT"
      />,
      {
        closeOnClickOutside: true,
        centerX: true,
        marginTop: 100,

        width: "",
      }
    );

    //setDeleteList(true);
  };

  const onRestoreAllCompleted = () => {
    if (props.count < 1) {
      addSnackbar("No Completed Tasks");

      return;
    }
    addOverlay(
      <WarnDlg
        onCancel={() => {
          removeOverlay();
        }}
        onApprove={() => {
          props.onRestoreTasks();
          removeOverlay();
          let msg: string = props.count.toString() + " tasks restored";
          addSnackbar(msg);
        }}
        applyBtnRed={false}
        isMobile={props.isMobile}
        icon={<Icon restoreIcon={true} />}
        title="Open all completed tasks?"
        subTitle={`Are you sure you want to reopen ${props.count} tasks?`}
        cancelBtnText="CANCEL"
        approveBtnText="OPEN ALL"
      />,
      {
        closeOnClickOutside: true,
        centerX: true,

        marginTop: 100,
      }
    );
  };
  const onPinList = () => {
    updateProjectPinned(props.userID, props.id, !props.list.pinned);

    addSnackbar(
      props.list.pinned ? "List is no longer pinned" : "List is pinned"
    );
  };
  const onHideDescription = () => {
    updateProjectShowDetails(props.userID, props.id, !props.list.showDetails);
  };
  const onHideReminders = () => {
    updateProjectShowReminders(
      props.userID,
      props.id,
      !props.list.showReminders
    );
  };
  const onComplete = () => {
    setCompletedMode(true);
    props.onShowCompleted(true);
  };

  const popupOptionsCompleted = [
    {
      label: "Restore All",
      onClick: onRestoreAllCompleted,
      color: themeContext?.textColor!,
      iconComponent: <Icon restoreIcon={true} />,
    },
  ];

  const viewOptions = [
    {
      label: "Show Description",
      onClick: onHideDescription,
      color: themeContext?.textColor!,
      iconComponent: <Icon detailsIcon={true} />,
    },
    {
      label: "Show Reminders Details",
      onClick: onHideReminders,
      color: themeContext?.textColor!,
      iconComponent: <Icon alarmIcon={true} />,
    },
  ];

  const popupOptions =
    props.todayView || props.upcomingView
      ? []
      : [
          {
            label: "Edit list",
            onClick: onEditList,
            color: themeContext?.textColor!,
            iconComponent: <Icon editIcon={true} />,
          },
          ...(!props.list.home
            ? [
                {
                  label: props.list.pinned ? "Unpin" : "Pin list",
                  onClick: onPinList,
                  color: themeContext?.textColor!,
                  iconComponent: <Icon pinIcon={true} />,
                },
              ]
            : []),

          {
            label: "Completed",
            onClick: onComplete,
            color: "green",
            iconComponent: <Icon doneIcon={true} />,
          },
          ...(!props.list.home
            ? [
                {
                  label: "Delete list",
                  onClick: onDeleteList,
                  color: "red",
                  iconComponent: <Icon deleteIcon={true} />,
                },
              ]
            : []),
        ];

  return (
    <div
      style={{
        display: "flex",

        flexDirection: "column",

        width: props.width,
      }}
    >
      <div
        style={{
          fontSize: props.fontSize,
          color: props.color,
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          flex: 1,
          marginLeft: props.isMobile ? "10px" : "",
          marginRight: props.isMobile ? "10px" : "",
          marginTop: "10px",
        }}
        className="list_title"
      >
        <div style={{ display: "flex" }} className="title_name_icon">
          {completedMode && (
            <div
              style={{
                display: "flex",
              }}
            >
              <Button
                id="close_completed"
                textButtonColor={""}
                label={""}
                padding={"0px"}
                iconComponent={
                  <Icon color={themeContext?.iconColor} backIcon={true} />
                }
                textButton={true}
                onClick={() => {
                  props.onShowCompleted(false);
                  setCompletedMode(false);
                }}
              ></Button>
            </div>
          )}
          <Tooltip
            placement="top"
            title="Edit list name"
            enterDelay={1000}
            arrow
          >
            <div
              onClick={onEditList}
              style={{
                fontWeight: "bold",

                paddingLeft: props.isMobile ? "10px" : "",
                paddingRight: props.isMobile ? "10px" : "",
              }}
              className="list_name_name"
            >
              {!completedMode && !props.todayView && !props.upcomingView && (
                <>{`${props.list.name} (${props.count.toString()})`}</>
              )}
              {completedMode && (
                <>
                  ({props.count}) Completed - {props.list.name}
                </>
              )}
            </div>
          </Tooltip>
          {(props.todayView || props.upcomingView) && (
            <div
              style={{
                fontWeight: "bold",

                paddingLeft: props.isMobile ? "10px" : "",
                paddingRight: props.isMobile ? "10px" : "",
              }}
            >
              {props.todayView
                ? `Today (${props.count})`
                : `Upcoming (${props.count})`}
            </div>
          )}
        </div>

        <div
          style={{
            marginRight: props.isMobile ? "0px" : "8px",
            display: "flex",
          }}
          className="list_more_options"
        >
          {!completedMode && (props.todayView || props.upcomingView) && (
            <div style={{ visibility: "hidden" }}>
              <PopupMenu
                iconComponent={<Icon fontSize={32} moreIcon={true} />}
                textColor={themeContext?.listPopupTextColor}
                items={popupOptions}
                backgroundColor={themeContext?.listPopupColor!}
                buttonColor={themeContext?.activeItemColor}
                textSize={poupUpTextSize}
              />
            </div>
          )}
          {!completedMode && (
            <div style={{ marginRight: "12px" }}>
              <PopupMenu
                iconComponent={<Icon fontSize={24} view={true} />}
                textColor={themeContext?.listPopupTextColor}
                textSize={20}
                marginTop="6px"
                checkBoxMenu={true}
                items={viewOptions}
                displayBelowParent={true}
                keepOpenAfterClick={true}
                checkBoxValue={[
                  props.list.showDetails!,
                  props.list.showReminders!,
                ]}
                buttonFontSize="20px"
                buttonLabel="View"
                backgroundColor={themeContext?.listPopupColor!}
                buttonBackgroundColor={"transparent"}
                buttonColor={themeContext?.textColor!}
              />
            </div>
          )}
          {!completedMode && !props.todayView && !props.upcomingView && (
            <PopupMenu
              iconComponent={<Icon fontSize={32} moreIcon={true} />}
              textColor={themeContext?.listPopupTextColor}
              items={popupOptions}
              backgroundColor={themeContext?.listPopupColor!}
              buttonColor={themeContext?.activeItemColor}
              textSize={poupUpTextSize}
            />
          )}

          {completedMode && (
            <PopupMenu
              iconComponent={<Icon fontSize={32} moreIcon={true} />}
              textColor={themeContext?.listPopupTextColor}
              items={popupOptionsCompleted}
              backgroundColor={themeContext?.listPopupColor!}
              buttonColor={props.color}
              textSize={poupUpTextSize}
            />
          )}
        </div>
      </div>
      {!completedMode && (
        <NewTask
          todayView={props.todayView}
          upcomingView={props.upcomingView}
          selectList={props.selectList}
          isMobile={props.isMobile}
          onTaskSave={handleSaveTask}
          userTimeZone={props.userTimeZone}
        />
      )}
    </div>
  );
};

export default List;
