import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import "firebase/auth"; // If you want to use Firebase Auth
import "firebase/firestore"; // If you want to use Firestore database
import {
  enableIndexedDbPersistence,
  getFirestore,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from "firebase/firestore";
import "firebase/storage"; // If you want to use Firebase storage
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDRWpBaRzqiGFLVV1LIexFYJqBO3XKxcdQ",
  authDomain: "keeplist.com",
  projectId: "keeplist-4b3e3",
  storageBucket: "keeplist-4b3e3.appspot.com",
  messagingSenderId: "500327925220",
  appId: "1:500327925220:web:c8730b2055af2b0a1c73f1",
  measurementId: "G-M9QVVWPQ9B",
};

const checkIfMobile = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobileDevice =
    /mobile|android|iphone|ipad|iemobile|wpdesktop|windows phone|blackberry|tablet|nokia/i.test(
      userAgent
    );

  return isMobileDevice;
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

let db = initFirestore();

export function initFS() {
  db = initFirestore();
}

function initFirestore() {
  return initializeFirestore(app, {
    localCache: persistentLocalCache(/*settings*/ {}),
  });
  // if (start) db = getFirestore();
}

// Retrieve the FCM token
export async function getFCMToken() {
  let fcmToken = null;

  // Register service worker
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        "/firebase-messaging-sw.js",
        { type: "module" }
      );
      //  console.log("Service worker registration successful:", registration);
    } catch (error) {
      console.error("Service worker registration failed:", error);
    }
  } else {
    console.error("Service workers are not supported in this browser.");
    return null;
  }

  // Initialize Firebase Messaging
  const messaging = getMessaging();

  // Retrieve FCM token
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BNJfKgctJk4VOt-sIHr-S5OF4Wg5pnfwJl1lTtwB04lXD2mvOZt8tpjcUxxp_jTzNXaBkAtIei_Zpe-GOvP12hw",
    });
    if (currentToken) {
      // Send the token to your server for later use
      //  console.log("FCM Token:", currentToken);
      fcmToken = currentToken;
    } else {
      console.log("No FCM token available.");
    }
  } catch (err) {
    console.error("An error occurred while retrieving FCM token:", err);
  }

  return fcmToken;
}

//db = getFirestore(app);

// Export it so it can be used throughout your application
export { db, auth };
