import React, { useContext } from 'react';
import './Style.css';
import { ThemeContext } from '../../../Context/Color';

type ErrorBoxProps = {
  text: string;
};

const ErrorBox: React.FC<ErrorBoxProps> = ({ text }) => {
  const themeContext = useContext(ThemeContext);

  if (!themeContext) {
    throw new Error("ThemeContext not provided from a ThemeProvider component");
  }



  return (
    <div className='error' style={{ backgroundColor: themeContext.errorTheme.background, color: themeContext.errorTheme.text }}>
      {text}
    </div>
  );
};

export default ErrorBox;
