export class ChatColors {
  newMsgPlaceholderColor: string;
  newMsgSendButtonColor: string;
  tipColor: string;
  botBackgroundColor: string;
  botTextColor: string;
  userBackgroundColor: string;
  tipBackgroundColor?: string | null;
  userTextColor: string;
  tipExampleColor: string;
  tipColorColumn: string;
  highlightColor: string;
  prominentColor: string;
  prominentTextColor: string;
  snackBarBackground: string;
  snackBarText: string;

  // Constructor with named parameters and optional tipBackgroundColor
  constructor({
    newMsgPlaceholderColor,
    newMsgSendButtonColor,
    tipBackgroundColor = null,
    botTextColor,
    tipExampleColor,
    prominentTextColor,
    prominentColor,
    tipColorColumn,
    highlightColor,
    userBackgroundColor,
    userTextColor,
    botBackgroundColor,
    tipColor,
    snackBarBackground,
    snackBarText,
  }: {
    newMsgPlaceholderColor: string;
    newMsgSendButtonColor: string;
    tipBackgroundColor?: string | null;
    botTextColor: string;
    tipExampleColor: string;
    prominentTextColor: string;
    prominentColor: string;
    tipColorColumn: string;
    highlightColor: string;
    userBackgroundColor: string;
    userTextColor: string;
    botBackgroundColor: string;
    tipColor: string;
    snackBarBackground: string;
    snackBarText: string;
  }) {
    this.newMsgPlaceholderColor = newMsgPlaceholderColor;
    this.newMsgSendButtonColor = newMsgSendButtonColor;
    this.tipBackgroundColor = tipBackgroundColor;
    this.botTextColor = botTextColor;
    this.tipExampleColor = tipExampleColor;
    this.prominentTextColor = prominentTextColor;
    this.prominentColor = prominentColor;
    this.tipColorColumn = tipColorColumn;
    this.highlightColor = highlightColor;
    this.userBackgroundColor = userBackgroundColor;
    this.userTextColor = userTextColor;
    this.botBackgroundColor = botBackgroundColor;
    this.tipColor = tipColor;
    this.snackBarBackground = snackBarBackground;
    this.snackBarText = snackBarText;
  }
}
