import React, { useContext, useEffect, useState } from "react";

import Button from "../../UI/Atoms/Button/Button";

import Icon from "../../UI/Atoms/Icon/Icon";

import Calendar from "../../UI/Organizm/Calendar/Calendar";
import TimePick from "../../UI/Organizm/TimePick/TimePick";
import closeNewTask from "../../Util/closeNewTask";
import Dialog from "../../UI/Organizm/Dialog/Dialog";
import { ThemeContext } from "../../Context/Color";
import DayPicker from "../../UI/Atoms/DayPicker/DayPicker";
import { Reminder, formatReminderString } from "../../Data/Reminder";
import { Timestamp } from "firebase/firestore";
import { useOverlay } from "../../Context/OverlayProvider";
import DueDateReminderDlg from "../../UI/Organizm/DueDateReminderDlg/DueDateReminderDlg";
import { DueDateReminder } from "../../Data/DueDateReminder";

enum Schedule {
  Daily = 1,
  Weekly,
  Monthly,
  Yearly,
}

type DueDateProps = {
  onSave: (
    date: Date,

    withTime: boolean,
    recurring: boolean,
    daily: boolean,
    weekly: boolean,
    monthly: boolean,
    yearly: boolean,
    dayOfWeek: number,
    dayOfMonth: number,
    lastDayOfMonth: boolean,
    excludeWeekends: boolean,
    dueDateReminders: DueDateReminder
  ) => void;
  currentDate?: Date;
  firstDayOfWeek: number;
  dueDateReminders: DueDateReminder;
  currentDateWithTime: boolean;
  onClose: () => void;
  isMobile: boolean;
  reminder: boolean;
};

const DueDate = (props: DueDateProps): JSX.Element => {
  useEffect(() => {
    closeNewTask();
  }, []);

  const [recurringReminder, setRecurringReminder] = useState(false);

  const [dueDateReminders, setDueDateReminders] = useState<DueDateReminder>(
    props.dueDateReminders
  );

  const [dayOfMonth, setDayOfMonth] = useState(1);

  const [monthLastDay, setMothLastDay] = useState(false);

  const [showDueDateReminders, setShowDueDateReminders] = useState(true);

  const [recurringType, setRecurringType] = useState(Schedule.Daily);

  const [dayOfWeek, setDayOfWeek] = useState(props.firstDayOfWeek);

  const [includeWeekends, setIncludeWeekends] = useState(false);

  const [lastDaySuggestion, setLastDaySuggestion] = useState(false);

  const [dueDateRemindersText, setDueDateRemindersText] = useState(
    getReminderSettings()
  );

  const themeContext = useContext(ThemeContext);

  const parentDivRef = React.useRef<HTMLDivElement>(null);
  const [parentDivWidth, setParentDivWidth] = useState(0);

  useEffect(() => {
    setDueDateRemindersText(getReminderSettings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dueDateReminders]);

  const findDateNextWeek = (): Date => {
    const today = new Date();
    const nextMonday = new Date(today);

    // Find how many days to go until the next Monday (0-based index; 0 is Sunday, 1 is Monday)
    let daysUntilNextMonday = props.firstDayOfWeek - 1 - nextMonday.getDay();
    if (daysUntilNextMonday <= 0) {
      daysUntilNextMonday += 7; // If today is Monday or any day later, go to the next Monday
    }

    nextMonday.setDate(nextMonday.getDate() + daysUntilNextMonday);

    return nextMonday;
  };

  function getReminderSettings(): string {
    if (
      !dueDateReminders.exactlyOnTime &&
      !dueDateReminders.hoursBefore &&
      !dueDateReminders.minutesBefore &&
      !dueDateReminders.daysBefore
    ) {
      return "No Reminders";
    }

    const reminders: string[] = [];

    // Add reminders based on the settings
    if (dueDateReminders.exactlyOnTime) {
      reminders.push("Exactly On Time");
    }
    if (
      dueDateReminders.minutesBefore &&
      dueDateReminders.selectedMinutesBefore > 0
    ) {
      reminders.push(
        `${dueDateReminders.selectedMinutesBefore} Minutes Before`
      );
    }
    if (
      dueDateReminders.hoursBefore &&
      dueDateReminders.selectedHoursBefore > 0
    ) {
      reminders.push(`${dueDateReminders.selectedHoursBefore} Hours Before`);
    }

    if (
      dueDateReminders.daysBefore &&
      dueDateReminders.selectedDaysBefore > 0
    ) {
      reminders.push(
        `${dueDateReminders.selectedDaysBefore} ${
          dueDateReminders.selectedDaysBefore === 1
            ? "Day Before"
            : "Days Before"
        }`
      );
    }

    // Join all the reminders with a comma
    return reminders.join(", ");
  }

  const updateOption = (selected: Date) => {
    const now = new Date();
    const nextWeekDate = findDateNextWeek();

    if (
      selected.getDate() === now.getDate() &&
      selected.getFullYear() === now.getFullYear() &&
      selected.getMonth() === now.getMonth()
    )
      setOptionSelected(1);
    else if (
      selected.getDate() === now.getDate() + 1 &&
      selected.getFullYear() === now.getFullYear() &&
      selected.getMonth() === now.getMonth()
    )
      setOptionSelected(2);
    else if (
      selected.getDate() === nextWeekDate.getDate() &&
      selected.getFullYear() === nextWeekDate.getFullYear() &&
      selected.getMonth() === nextWeekDate.getMonth()
    )
      setOptionSelected(3);
    else setOptionSelected(0);
  };

  const getOption = (): number => {
    const now = new Date();
    const nextWeekDate = findDateNextWeek();

    if (
      selectedDate.getDate() === now.getDate() &&
      selectedDate.getFullYear() === now.getFullYear() &&
      selectedDate.getMonth() === now.getMonth()
    )
      return 1;
    else if (
      selectedDate.getDate() === now.getDate() + 1 &&
      selectedDate.getFullYear() === now.getFullYear() &&
      selectedDate.getMonth() === now.getMonth()
    )
      return 2;
    else if (
      selectedDate.getDate() === nextWeekDate.getDate() &&
      selectedDate.getFullYear() === nextWeekDate.getFullYear() &&
      selectedDate.getMonth() === nextWeekDate.getMonth()
    )
      return 3;

    return 0;
  };

  const { addOverlay, removeOverlay } = useOverlay();

  const [selectedDate, setSelectedDate] = useState<Date>(
    props.currentDate ? props.currentDate : new Date()
  );

  const [timeSelection, setTimeSelection] = useState(
    props.currentDateWithTime || props.reminder
  );
  const [validTime, setValidTime] = useState(props.currentDateWithTime);
  const [timeSelected, setTimeSelected] = useState(props.currentDateWithTime);
  const [timeError, setTimeError] = useState(false);
  const [pastTimeError, setPastTimeError] = useState(false);

  const [optionSelected, setOptionSelected] = useState(getOption());
  const [timeHovered, setTimeHovered] = useState(false);

  const [timeSelectionBorder] = useState(1);

  useEffect(() => {
    if (parentDivRef.current)
      setParentDivWidth(parentDivRef.current.offsetWidth);
  }, [timeSelection]);

  useEffect(() => {
    setLastDaySuggestion(
      recurringReminder &&
        recurringType === Schedule.Monthly &&
        (dayOfMonth === 31 || dayOfMonth === 30)
    );
  }, [dayOfMonth, recurringType, recurringReminder]);

  const onToday = () => {
    onDateSelected(new Date());
    setOptionSelected(1);
  };

  const onTomorrow = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    onDateSelected(tomorrow);
    setOptionSelected(2);
  };

  const onNextWeek = () => {
    onDateSelected(findDateNextWeek());
    setOptionSelected(3);
  };

  const isToday = () => {
    return optionSelected === 1;
  };

  const isPastTime = () => {
    const currentDate = new Date();
    return selectedDate.getTime() < currentDate.getTime();
  };

  const isTomorrow = () => {
    return optionSelected === 2;
  };

  const isNextWeek = () => {
    return optionSelected === 3;
  };

  const onClose = () => {
    props.onClose();
  };

  const isValidTime = (): boolean => {
    let valid = !timeSelection || validTime;

    setTimeError(!valid);
    return valid;
  };

  const onSave = () => {
    if (!isValidTime()) {
      return;
    }

    if (props.reminder && isPastTime() && !recurringReminder) {
      setPastTimeError(true);
      return;
    }

    props.onSave(
      selectedDate!,
      timeSelected,
      recurringReminder,
      recurringReminder && recurringType === Schedule.Daily,
      recurringReminder && recurringType === Schedule.Weekly,
      recurringReminder && recurringType === Schedule.Monthly,
      recurringReminder && recurringType === Schedule.Yearly,
      recurringReminder && recurringType === Schedule.Monthly ? dayOfMonth : 0,
      recurringReminder && recurringType === Schedule.Weekly ? dayOfWeek : 0,
      recurringReminder && recurringType === Schedule.Monthly && monthLastDay,
      !includeWeekends,
      dueDateReminders
    );
  };

  const calculateDueDateReminder = (
    selectedDate: Date,
    timeSelected: boolean,
    selectedTime?: { hour: number; minute: number }
  ) => {
    if (dueDateReminders.customDueDateReminder) {
      if (!timeSelected) {
        dueDateReminders.exactlyOnTime = false;
        dueDateReminders.hoursBefore = false;
        dueDateReminders.minutesBefore = false;
        setDueDateReminders(dueDateReminders);
        return;
      }
      return;
    }

    console.log(timeSelected, selectedTime);

    const reminderSettings = new DueDateReminder();

    const today = new Date();
    const todayWithoutTime = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );

    const selectedDateWithoutTime = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate()
    );

    const differenceInDays = Math.floor(
      (selectedDateWithoutTime.getTime() - todayWithoutTime.getTime()) /
        (1000 * 60 * 60 * 24)
    );

    if (differenceInDays > 0) {
      reminderSettings.daysBefore = true;
      reminderSettings.selectedDaysBefore =
        differenceInDays >= 3 ? 3 : differenceInDays;
    }

    if (timeSelected && selectedTime) {
      const combinedDateTime = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        selectedTime.hour,
        selectedTime.minute
      );

      const fullDuration = combinedDateTime.getTime() - today.getTime();

      const differenceInHours = Math.floor(fullDuration / (1000 * 60 * 60));
      const differenceInMinutes = Math.floor(fullDuration / (1000 * 60));

      if (differenceInHours > 0) {
        reminderSettings.hoursBefore = true;
        reminderSettings.selectedHoursBefore =
          differenceInHours >= 2 ? 2 : differenceInHours;
      }

      if (differenceInMinutes >= 15) {
        reminderSettings.minutesBefore = true;
        reminderSettings.selectedMinutesBefore = 15;
      }
    }

    // Replace this with your state update logic
    setDueDateReminders(reminderSettings);
  };

  const onDateSelected = (day: Date) => {
    const newDate = updateDate(day);

    setSelectedDate(newDate);

    updateOption(newDate);

    const time = !timeSelected
      ? undefined
      : { hour: newDate.getHours(), minute: newDate.getMinutes() };

    calculateDueDateReminder(newDate, timeSelected, time);

    // props.onSave(newDate, timeSelected);
  };

  const updateDate = (datePicked: Date): Date => {
    //This function is supposed to conserve the time the user selected

    let newDate = new Date();
    if (selectedDate !== null) {
      newDate = new Date(selectedDate.getTime()); // clone the date
    }
    newDate.setFullYear(
      datePicked.getFullYear(),
      datePicked.getMonth(),
      datePicked.getDate()
    );

    setSelectedDate(newDate);

    return newDate;
  };

  const updateTime = (time: Date): Date => {
    let newDate = new Date();
    if (selectedDate !== null) {
      newDate = new Date(selectedDate.getTime()); // clone the date
    }

    newDate.setHours(time.getHours(), time.getMinutes(), 0, 0);

    setValidTime(true);

    setSelectedDate(newDate);
    setTimeSelected(true);

    return newDate;
  };

  const getFirstDayOfWeekName = (): string => {
    if (props.firstDayOfWeek === 1) return "Sun";
    else if (props.firstDayOfWeek === 2) return "Mon";
    else if (props.firstDayOfWeek === 3) return "Tue";
    else if (props.firstDayOfWeek === 4) return "Wed";
    else if (props.firstDayOfWeek === 5) return "Thu";
    else if (props.firstDayOfWeek === 6) return "Fri";
    else if (props.firstDayOfWeek === 7) return "Sat";

    return "";
  };

  const onClearSelection = () => {
    props.onSave(
      null!,
      false,
      false,
      false,
      false,
      false,
      false,
      0,
      0,
      false,
      true,
      new DueDateReminder()
    );
  };

  const formatDate = (date: Date) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract the day of the week, month, day, and year from the date object
    const dayName = daysOfWeek[date.getDay()];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    // Format the date as "Day Name, Mon DD, YYYY"
    const formattedDate = `${dayName}, ${month} ${day}, ${year}`;

    return formattedDate;
  };

  const getScheduleWarnings = (): string => {
    if (!recurringReminder) {
      return "";
    }

    if (
      recurringType === Schedule.Monthly &&
      !monthLastDay &&
      dayOfMonth === 31
    ) {
      return "Not all months have this day";
    } else if (
      recurringType === Schedule.Monthly &&
      !monthLastDay &&
      dayOfMonth === 30
    ) {
      return "This day is not valid in February";
    } else if (
      recurringType === Schedule.Yearly &&
      selectedDate.getDate() === 29 &&
      selectedDate.getMonth() === 1
    ) {
      return "Feb 29 happens every 4 years";
    }

    return "";
  };
  const getTomorrowString = (): string => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Get the current date
    const currentDate = new Date();

    // Calculate tomorrow's date
    currentDate.setDate(currentDate.getDate() + 1);

    // Extract the day of the week, month, day, and year
    const dayName = daysOfWeek[currentDate.getDay()];
    const month = months[currentDate.getMonth()];
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();

    // Format the date as "Day Name, Mon DD, YYYY"
    const formattedDate = `${dayName}, ${month} ${day}, ${year}`;

    // Return the string with tomorrow's date and day name
    return formattedDate;
  };

  const onDueDateRemindersSave = (reminders: DueDateReminder) => {
    setDueDateReminders(reminders);
    removeOverlay();
  };

  const onDueDateRemindersCancel = () => {
    removeOverlay();
  };

  const onDueDateReminders = () => {
    addOverlay(
      <DueDateReminderDlg
        dueDateHasTime={timeSelected}
        dueDateReminder={dueDateReminders}
        isMobile={props.isMobile}
        onSave={onDueDateRemindersSave}
        onCancel={onDueDateRemindersCancel}
      />,
      {
        closeOnClickOutside: false,
        centerX: true,
        marginTop: 100,
      },
      true
    );
  };

  const handleRoutineChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const dayMap = {
      daily: 1,
      weekly: 2,
      monthly: 3,
      yearly: 4,
    };
    setRecurringType(dayMap[event.target.value as keyof typeof dayMap]);
  };

  const handleDayChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const dayMap = {
      sunday: 1,
      monday: 2,
      tuesday: 3,
      wednesday: 4,
      thursday: 5,
      friday: 6,
      saturday: 7,
    };
    setDayOfWeek(dayMap[event.target.value as keyof typeof dayMap]);
  };

  const onResolutionChange = (mobile: boolean) => {};

  const scheduleWarnings = getScheduleWarnings();

  const getRepeatDescription = () => {
    const newTimestamp = Timestamp.fromDate(selectedDate);

    if (!recurringReminder) return "";
    const newReminder: Reminder = {
      time: newTimestamp,
      recurring: recurringReminder,
      weekly: recurringType === Schedule.Weekly,
      monthly: recurringType === Schedule.Monthly,
      yearly: recurringType === Schedule.Yearly,
      cronJobID: "",
      daily: recurringType === Schedule.Daily,
      dayOfMonth: dayOfMonth,
      dayOfWeek: dayOfWeek,
      excludeWeekends: !includeWeekends,
      recurringMonthLastDay: recurringType === Schedule.Monthly && monthLastDay,
      created: Timestamp.now(),
      done: false,
      taskID: "",
      deviceToken: "",
      reminderID: 0,
      deleted: false,
      utcOffset: -new Date().getTimezoneOffset(),
      timeZoneName: "",
    };
    return formatReminderString(newReminder, timeSelected, "");
  };

  return (
    <Dialog
      closeBar={true}
      onResolutionChange={onResolutionChange}
      isMobile={props.isMobile}
      saveCancel={false}
      onClose={props.onClose}
    >
      {props.reminder && (
        <div
          style={{
            display: "flex",
            marginBottom: recurringReminder ? "20px" : "8px",
          }}
        >
          <Button
            id="one_time"
            label="One time"
            width="50%"
            fontSize="18px"
            iconComponent={<Icon alarmIcon={true} />}
            marginRight={10}
            textButton={recurringReminder}
            onClick={() => {
              setRecurringReminder(false);
            }}
          />
          <Button
            id="one_time"
            width="50%"
            fontSize="18px"
            iconComponent={<Icon repeat={true} />}
            label="Recurring"
            textButton={!recurringReminder}
            onClick={() => {
              setRecurringReminder(true);
            }}
          />
        </div>
      )}
      {!props.reminder && (
        <div
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            color: themeContext?.textColor,
          }}
        >
          {props.reminder
            ? "⏰ Select reminder time"
            : "📅 When is the task due?"}
        </div>
      )}

      {!props.reminder && (
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            fontSize: "18px",

            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          <Button
            id="add_date_today"
            textButtonColor={isToday() ? themeContext?.activeItemColor : ""}
            label={"😰 Today"}
            padding={"0px"}
            onMouseDown={(e) => e.preventDefault()}
            textButton={true}
            onClick={onToday}
          ></Button>

          <Button
            id="add_date_tomorrow"
            actionTip={getTomorrowString()}
            actionTipPosition="bottom"
            textButtonColor={isTomorrow() ? themeContext?.activeItemColor : ""}
            label={"🌞 Tomorrow"}
            padding={"0px"}
            onMouseDown={(e) => e.preventDefault()}
            textButton={true}
            onClick={onTomorrow}
          ></Button>
          <Button
            id="add_date_next_week"
            actionTip={formatDate(findDateNextWeek())}
            actionTipPosition="bottom"
            textButtonColor={isNextWeek() ? themeContext?.activeItemColor : ""}
            label={`😌 Next Week (${getFirstDayOfWeekName()})`}
            padding={"0px"}
            onMouseDown={(e) => e.preventDefault()}
            textButton={true}
            onClick={onNextWeek}
          ></Button>
        </div>
      )}

      {recurringReminder && scheduleWarnings !== "" && (
        <div style={{ display: "flex", marginTop: "10px" }}>
          <div style={{ color: themeContext?.activeItemColor, padding: "6px" }}>
            {scheduleWarnings}
          </div>
          {lastDaySuggestion && (
            <div style={{ marginLeft: "8px" }}>
              <Button
                textButton={true}
                id="change_to_last"
                label="Change to last day"
                onClick={() => {
                  setMothLastDay(true);
                }}
              />
            </div>
          )}
        </div>
      )}

      {recurringReminder && (
        <div
          style={{
            color: themeContext?.isDarkTheme
              ? "pink"
              : themeContext?.activeItemColor,
            textAlign: "center",
            marginBottom: "12px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div style={{ marginLeft: "8px" }}>{getRepeatDescription()}</div>
        </div>
      )}

      {recurringReminder && (
        <div
          style={{ display: "flex", marginTop: "0px", marginBottom: "10px" }}
        >
          {" "}
          <div style={{ display: "flex", color: themeContext?.textColor }}>
            Repeat every:
          </div>
          <select
            onChange={handleRoutineChange}
            style={{ marginLeft: "8px" }}
            name="days"
            id="daysOfWeek"
          >
            <option selected={recurringType === Schedule.Daily} value="daily">
              Day
            </option>
            <option selected={recurringType === Schedule.Weekly} value="weekly">
              Week
            </option>
            <option
              selected={recurringType === Schedule.Monthly}
              value="monthly"
            >
              Month
            </option>
            <option selected={recurringType === Schedule.Yearly} value="yearly">
              Year
            </option>
          </select>
        </div>
      )}

      {recurringReminder && recurringType === Schedule.Daily && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "8px",
            marginBottom: "8px",
          }}
        >
          <input
            style={{ marginRight: "6px", cursor: "pointer" }}
            type="checkbox"
            id="include_weekends"
            checked={includeWeekends}
            onChange={() => {
              setIncludeWeekends(!includeWeekends);
            }}
          />
          <label
            onClick={(e) => {
              //   setIncludeWeekends(!includeWeekends);
              e.stopPropagation();
            }}
            style={{ cursor: "pointer", color: themeContext?.textColor }}
            htmlFor="include_weekends"
          >
            Include Weekends{" "}
          </label>
        </div>
      )}

      {recurringReminder && recurringType === Schedule.Monthly && (
        <div style={{ display: "flex", marginBottom: "8px", marginTop: "8px" }}>
          <div style={{ color: themeContext?.textColor, marginRight: "6px" }}>
            On what day:
          </div>
          <DayPicker
            selectedDay={monthLastDay ? "Last Day" : dayOfMonth}
            onChange={(day, isLastDay) => {
              setDayOfMonth(day);
              setMothLastDay(isLastDay);
            }}
          />
        </div>
      )}

      {recurringReminder && recurringType === Schedule.Weekly && (
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            marginBottom: "10px",
            color: themeContext?.textColor,
          }}
        >
          {" "}
          On:
          <select
            onChange={handleDayChange}
            style={{ marginLeft: "8px" }}
            name="days"
            id="daysOfWeek"
          >
            <option selected={dayOfWeek === 1} value="sunday">
              Sunday
            </option>
            <option selected={dayOfWeek === 2} value="monday">
              Monday
            </option>
            <option selected={dayOfWeek === 3} value="tuesday">
              Tuesday
            </option>
            <option selected={dayOfWeek === 4} value="wednesday">
              Wednesday
            </option>
            <option selected={dayOfWeek === 5} value="thursday">
              Thursday
            </option>
            <option selected={dayOfWeek === 6} value="friday">
              Friday
            </option>
            <option selected={dayOfWeek === 7} value="saturday">
              Saturday
            </option>
          </select>
        </div>
      )}
      {recurringReminder && recurringType === Schedule.Yearly && (
        <div style={{ marginTop: "8px", color: themeContext?.textColor }}>
          First time on:
        </div>
      )}
      <div
        style={{
          overflow:
            !recurringReminder || recurringType === Schedule.Yearly
              ? "auto"
              : "hidden",
          height:
            !recurringReminder || recurringType === Schedule.Yearly
              ? ""
              : "0px",
          visibility:
            !recurringReminder || recurringType === Schedule.Yearly
              ? "visible"
              : "hidden",
        }}
      >
        <Calendar
          allowPastDates={false}
          preventDefaultMouseDown={true}
          marginTop={10}
          isMobile={props.isMobile}
          currentDate={selectedDate}
          onDateSelected={onDateSelected}
        />
      </div>

      {timeError && (
        <div style={{ color: "red", marginLeft: "10px" }}>
          Invalid Time - Time should be HH:MM
        </div>
      )}
      {pastTimeError && (
        <div style={{ color: "red", marginLeft: "10px" }}>Time has passed</div>
      )}

      {timeSelection && (
        <div
          ref={parentDivRef}
          onMouseEnter={(e) => {
            setTimeHovered(true);
          }}
          onMouseLeave={(e) => {
            setTimeHovered(false);
          }}
          style={{
            display: "flex",
            flex: "1",

            border: timeHovered
              ? themeContext?.isDarkTheme
                ? `${timeSelectionBorder}px solid pink`
                : `${timeSelectionBorder}px solid blue`
              : themeContext?.isDarkTheme
              ? `${timeSelectionBorder}px solid white`
              : `${timeSelectionBorder}px solid black`,

            justifyContent: "space-between",
            alignItems: "space-between",
            fontSize: "20px",
            fontFamily: "Calibri",
            marginTop: !props.reminder ? "16px" : "16px",
          }}
        >
          <TimePick
            title="Time"
            tooltip={
              recurringReminder
                ? ""
                : "Time Format: 24-Hour Clock  (e.g., 14:30),     Scroll to select times in 30-minute intervals."
            }
            parentDivBorder={timeSelectionBorder}
            focus={!props.reminder}
            reminder={props.reminder}
            width={parentDivWidth}
            isMobile={props.isMobile}
            onFocus={(focus: boolean) => {
              setShowDueDateReminders(!focus);
            }}
            onInvalidTime={() => {
              setValidTime(false);
            }}
            currentTime={!timeSelected ? undefined : props.currentDate!}
            onTimeSelected={(time) => {
              setTimeError(false);
              updateTime(time);
              setShowDueDateReminders(true);
              calculateDueDateReminder(selectedDate, true, {
                hour: time.getHours(),
                minute: time.getMinutes(),
              });
              //onSelectTime();
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {!props.reminder && (
              <Button
                actionTip="Remove time"
                id={"btn_remove_time"}
                label=""
                iconComponent={
                  <Icon
                    fontSize={16}
                    closeIcon={true}
                    color={themeContext?.textColor}
                  />
                }
                textButton={true}
                onClick={() => {
                  setTimeError(false);
                  setTimeSelected(false);
                  setTimeSelection(false);
                  calculateDueDateReminder(selectedDate, false);
                }}
              />
            )}
          </div>
        </div>
      )}

      {!timeSelection && (
        <div style={{ marginTop: "10px" }}>
          {" "}
          <Button
            id="add_due_time"
            label={"ADD TIME"}
            iconComponent={<Icon timeIcon={true} />}
            padding="10px"
            onMouseDown={(e) => e.preventDefault()}
            centerText={true}
            textButton={false}
            onClick={() => {
              setTimeSelection(true);
            }}
          ></Button>
        </div>
      )}

      {!props.reminder && showDueDateReminders && (
        <div style={{ marginTop: "10px" }}>
          {" "}
          <Button
            id="add_due_date_reminders"
            label={dueDateRemindersText}
            maxTextWidth="300px"
            iconComponent={<Icon alarmIcon={true} />}
            padding="10px"
            onMouseDown={(e) => e.preventDefault()}
            centerText={true}
            textButton={false}
            onClick={() => {
              onDueDateReminders();
              // setTimeSelection(true);
            }}
          ></Button>
        </div>
      )}

      <div
        style={{
          display: "flex",
          marginTop: "20px",

          justifyContent: props.currentDate ? "space-between" : "flex-end",
          alignItems: "center",
        }}
      >
        {props.currentDate && (
          <div style={{ display: "flex" }}>
            <Button
              actionTip="Click to remove due date"
              id="clear_selection"
              textButtonColor={"red"}
              label={"NO DATE"}
              iconComponent={<Icon deleteIcon={true} />}
              centerText={true}
              padding={"8px"}
              onMouseDown={(e) => e.preventDefault()}
              textButton={true}
              onClick={onClearSelection}
            ></Button>
          </div>
        )}
        <div style={{ display: "flex" }}>
          <Button
            id="btn_cancel"
            textButtonColor={themeContext?.textColor}
            label={"Cancel"}
            marginRight={8}
            onMouseDown={(e) => e.preventDefault()}
            textButton={true}
            onClick={onClose}
          ></Button>
          <Button
            id="btn_save"
            label={"Save"}
            iconComponent={<Icon sendIcon={true} />}
            onMouseDown={(e) => e.preventDefault()}
            textButton={false}
            onClick={onSave}
          ></Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DueDate;
