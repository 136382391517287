import React, { useEffect, useContext } from "react";
import { ThemeContext } from "../../../Context/Color";
import Icon from "../../Atoms/Icon/Icon";

import PopupMenu from "../PopupMenu/PopupMenu";
import { LocalStorage } from "../../../Util/localStorage";

type ThemePickerProps = {};

const ThemePicker = (props: ThemePickerProps) => {
  useEffect(() => {}, []); // Depend on currentCount and props.count

  const themeContext = useContext(ThemeContext);
  const storage = new LocalStorage();

  const onDarkTheme = () => {
    storage.setValue("THEME", "2");

    themeContext?.setDarkMode(true);
  };

  const onBrightTheme = () => {
    storage.setValue("THEME", "1");
    themeContext?.setDarkMode(false);
  };

  const onSystemDefault = () => {
    storage.setValue("THEME", "0");
    themeContext?.setDarkMode(
      window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
    );
  };

  const themeOptions = [
    {
      label: "Light Theme",
      onClick: onBrightTheme,
      color: themeContext?.textColor!,
      iconComponent: <Icon brightModeIcon={true} />,
    },
    {
      label: "Dark Theme",
      onClick: onDarkTheme,
      color: themeContext?.textColor!,
      iconComponent: <Icon darkModeIcon={true} />,
    },

    {
      label: "System Default",
      onClick: onSystemDefault,
      color: themeContext?.textColor!,
      iconComponent: <Icon themeSelection={true} />,
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "20px",
      }}
    >
      <PopupMenu
        tooltip="Change theme"
        iconComponent={
          <Icon
            fontSize={28}
            darkModeIcon={themeContext?.isDarkTheme}
            brightModeIcon={!themeContext?.isDarkTheme}
          />
        }
        backgroundColor={themeContext?.listPopupColor!}
        textColor="black"
        items={themeOptions}
        textSize={24}
        buttonColor={themeContext?.headerText}
      />
    </div>
  );
};

export default ThemePicker;
