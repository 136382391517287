import Button from "../../Atoms/Button/Button";
import Icon from "../../Atoms/Icon/Icon";

type DoneButtonProps = {
  onComplete: () => void;
};

const DoneButton = (props: DoneButtonProps) => {
  return (
    <Button
      padding="0px"
      iconComponent={<Icon color={"white"} fontSize={18} doneIcon={true} />}
      checkbox={true}
      id="btn_task_completed"
      label={""}
      textButton={true}
      onClick={(e) => {
        e.stopPropagation();
        props.onComplete();
      }}
    ></Button>
  );
};

export default DoneButton;
