import { useContext } from "react";
import { Draggable } from "react-beautiful-dnd";
import { ThemeContext } from "../../../Context/Color";

type SectionHeaderProps = {
  sectionName: string;
  overdue: boolean;
  isMobile: boolean;
  index: number;
  drag_id: string;
};

const SectionHeader = (props: SectionHeaderProps) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Draggable
      key={props.drag_id}
      draggableId={props.drag_id}
      isDragDisabled={true}
      index={props.index}
    >
      {(provided, snapshot): JSX.Element => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          key="section-name"
        >
          <div
            style={{
              display: "flex",
              marginLeft: props.isMobile ? "6px" : "0px",
              marginBottom: "16px",
              marginTop: props.index === 0 ? "4px" : "16px",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                border: "1px solid black",
                backgroundColor: props.overdue
                  ? themeContext?.overDueSectionColor
                  : themeContext?.sectionColor,
                borderRadius: "5px",
                color: props.overdue
                  ? themeContext?.overdueSectionTextColor
                  : themeContext?.sectionTextColor,
                padding: "8px",
              }}
            >
              {props.sectionName}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default SectionHeader;
