export class DueDateReminder {
  exactlyOnTime: boolean = false;
  minutesBefore: boolean = false;
  hoursBefore: boolean = false;
  dueDateReminderChanged: boolean = false;
  daysBefore: boolean = false;
  customDueDateReminder: boolean = false;
  selectedHoursBefore: number = 2;
  selectedMinutesBefore: number = 15;
  selectedDaysBefore: number = 3;
  selectedTime: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    8, // Hour (08:00 AM)
    30 // Minute
  );

  // Method to create a copy of the object
  copy(): DueDateReminder {
    const copy = new DueDateReminder();
    copy.exactlyOnTime = this.exactlyOnTime;
    copy.minutesBefore = this.minutesBefore;
    copy.hoursBefore = this.hoursBefore;
    copy.dueDateReminderChanged = this.dueDateReminderChanged;
    copy.daysBefore = this.daysBefore;
    copy.customDueDateReminder = this.customDueDateReminder;
    copy.selectedHoursBefore = this.selectedHoursBefore;
    copy.selectedMinutesBefore = this.selectedMinutesBefore;
    copy.selectedDaysBefore = this.selectedDaysBefore;
    copy.selectedTime = new Date(this.selectedTime);
    return copy;
  }

  toString(): string {
    return `DueDateReminder(
          exactlyOnTime: ${this.exactlyOnTime}, 
          dueDateReminderChanged: ${this.dueDateReminderChanged}, 
          customDueDateReminder: ${this.customDueDateReminder}, 
          minutesBefore: ${this.minutesBefore}, 
          hoursBefore: ${this.hoursBefore}, 
          daysBefore: ${this.daysBefore}, 
          selectedHoursBefore: ${this.selectedHoursBefore}, 
          selectedMinutesBefore: ${this.selectedMinutesBefore}, 
          selectedDaysBefore: ${this.selectedDaysBefore}, 
          selectedTime: ${this.selectedTime}, 
        )`;
  }
}
