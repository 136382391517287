import React from "react";

type StringPair = [string, string];

type StringTableProps = {
  data: StringPair[];
  title: string;
};

const StringTable: React.FC<StringTableProps> = ({ data, title }) => {
  return (
    <div
      style={{
        fontSize: "16px",
        marginTop: "20px",
        maxWidth: "250px",
        padding: "6px",
        minHeight: "230px",
        maxHeight: "230px",
        overflowY: "auto",
        borderRadius: "8px",
        border: "1px solid lightgray",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ marginBottom: "8px", color: "blue" }}>{title}</div>
      <div style={{ color: "black" }}>
        {data.map(([string1, string2], index) => (
          <div
            style={{
              fontSize: "18px",
              display: "flex",
              alignItems: "space-between",
              justifyContent: "space-between",
              marginBottom: "4px",
            }}
            key={index}
          >
            <div
              style={{
                fontWeight: "bold",
                marginRight: "14px",
              }}
            >
              {string1}
            </div>
            <div>{string2}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StringTable;
