import React, { ReactNode, useContext, useEffect } from "react";
import { ThemeContext } from "../../Context/Color";

interface BackgroundProps {
  children: ReactNode;
}

const Background: React.FC<BackgroundProps> = ({ children }) => {
  const themeContext = useContext(ThemeContext);

  //  let david: int = 2;

  let bDarkMode: boolean = false;

  if (!themeContext) {
    throw new Error("ThemeContext not provided from a ThemeProvider component");
  }

  useEffect(() => {
    if (bDarkMode) document.body.style.backgroundColor = "black";
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, [bDarkMode]);

  return <>{children}</>;
};

export default Background;
