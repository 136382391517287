import { Timestamp } from "firebase/firestore";
import { addDocumentToFirestore, deleteDocumentFromFirestore } from "./Crud";

export interface DeviceData {
  token: string;
  web: boolean;
  android: boolean;
  apple: boolean;
  lastLogin: Timestamp;
}

function toJson(device: DeviceData) {
  return {
    token: device.token,
    web: device.web,
    android: device.android,
    apple: device.apple,
    lastLogin: device.lastLogin,
  };
}

export const deleteDevice = async (
  token: string,
  userID: string
): Promise<boolean> => {
  if (token === "") return true;
  await deleteDocumentFromFirestore(`users/${userID}/devices/`, token);

  return true;
};

export const createDevice = async (
  token: string,
  userID: string
): Promise<string> => {
  const device: DeviceData = {
    token: token,
    web: true,
    android: false,
    apple: false,
    lastLogin: Timestamp.now(),
  };

  return addDocumentToFirestore(
    toJson(device),
    `users/${userID}/devices/`,
    token
  );
};
