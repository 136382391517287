import "./Style.css";
import React, { ReactNode } from "react";

export type OverLayProps = {
  centerX: boolean;
  width?: string;
  height?: string;
  maxHeight?: string;
  closeOnClickOutside: boolean;
  marginTop?: number;
  marginBottom?: number;
  open: boolean;
  onClose: () => void;
  children: ReactNode; // to pass a React component as a prop
};

const Overlay = (props: OverLayProps) => {
  if (!props.open) return null;

  const onClickOutside = () => {
    if (props.closeOnClickOutside) props.onClose();
  };

  return (
    <div onClick={onClickOutside} className="overlay">
      <div
        style={{
          display: "flex",
          overflow: "auto",
          height: props.height && props.height,
          maxHeight: props.height
            ? props.height
            : props.maxHeight
            ? props.maxHeight
            : "100%",

          justifyContent: props.centerX ? "center" : "",
        }}
        className="overylayContainer"
      >
        <div
          style={{
            width: props.width ? props.width : "",
            marginTop: `${props.marginTop}px` || "",
            marginBottom: `${props.marginBottom}px` || "",
            overflow: "auto",
            display: "flex",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="overlayDlg"
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Overlay;
