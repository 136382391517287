import React, { useState } from "react";
import Apple from "../../../assets/apple_icon.png";

type BtnProps = {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  caption: string;
};

const AppleBtn = (props: BtnProps) => {
  const imageStyle = { width: "auto", height: "25px" };

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        onClick={(e) => {
          setIsHovered(false);
          props.onClick(e);
        }}
        style={{
          cursor: "pointer",
          display: "flex",
          border: "1px solid lightgray",
          backgroundColor: isHovered ? "azure" : "white",
          width: "300px",
          borderRadius: "20px",
          justifyContent: "center",
          paddingTop: "8px",
          paddingBottom: "8px",
          alignItems: "center",
        }}
      >
        <div>
          <img alt={props.caption} style={imageStyle} src={Apple}></img>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "8px",
            justifyContent: "center",
          }}
        >
          {props.caption}
        </div>
      </div>
    </div>
  );
};

export default AppleBtn;
