import { useContext, useState } from "react";
import "./Style.css";
import { ThemeContext } from "../../../Context/Color";

type ListRowProps = {
  Icon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  marginBottom?: string;
  fontSize?: string;
  fontSizeSubTitle?: string;
  checkBox?: boolean;
  onClick?: (checkBoxValue: boolean) => void;
  title: string;
  subTitle?: string;
};

const ListRow = (props: ListRowProps) => {
  const [checkBoxValue, setCheckBoxValue] = useState(false);
  const themeContext = useContext(ThemeContext);

  const [hovered, setIsHovered] = useState(false);
  return (
    <div
      onClick={() => {
        if (props.onClick) props.onClick(!checkBoxValue);
        if (props.checkBox) setCheckBoxValue(!checkBoxValue);
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      className={props.onClick ? "list_button" : "list_text"}
      style={{
        display: "flex",
        flexDirection: "row",
        color: themeContext?.textColor,
        justifyContent: "space-between",
        backgroundColor: hovered ? themeContext?.taskHoverColor : "",
        padding: "8px",
        marginBottom: props.marginBottom || "22px",
        fontSize: props.fontSize || "22px",
        fontFamily: "Calibri",
        cursor: props.onClick ? "pointer" : "",
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: "10px" }}>{props.Icon}</div>
        <div>
          <div style={{ fontFamily: "Calibri", marginBottom: "1px" }}>
            {props.title}
          </div>
          {props.subTitle && (
            <div
              style={{
                fontSize: props.fontSizeSubTitle || "18px",
                fontFamily: "Calibri",
                height: "18px",
              }}
            >
              {props.subTitle}
            </div>
          )}
        </div>
      </div>
      {props.trailingIcon && (
        <div style={{ marginLeft: "50px" }}>{props.trailingIcon}</div>
      )}
      {props.checkBox && (
        <div style={{ marginLeft: "50px" }}>
          <input
            style={{ cursor: "pointer" }}
            checked={checkBoxValue}
            type="checkbox"
          ></input>
        </div>
      )}
    </div>
  );
};

export default ListRow;
