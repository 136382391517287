import React, { useState, useEffect } from "react";

type StatsCardProps = {
  name: string;
  count: number;
  dontAnimate?: boolean;
};

const StatsCard = (props: StatsCardProps) => {
  const [currentCount, setCurrentCount] = useState(0);

  useEffect(() => {
    if (props.dontAnimate) setCurrentCount(props.count);
    else {
      // Set the count to start from 1 to props.count, increase every 50ms
      if (currentCount < props.count) {
        // If the current count is less than the final count, set a timeout to increase the count
        const timer = setTimeout(() => setCurrentCount(currentCount + 1), 25);
        return () => clearTimeout(timer);
      }
    }
  }, [currentCount, props.count, props.dontAnimate]); // Depend on currentCount and props.count

  return (
    <div style={{ marginRight: "18px" }}>
      <div style={{ color: "blue", fontSize: "20px" }}>{props.name}</div>
      <div style={{ color: "black" }}>{currentCount}</div>
    </div>
  );
};

export default StatsCard;
