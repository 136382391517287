import { useEffect, useRef, useState } from "react";
import EditBox from "../../UI/Atoms/EditBox/EditBox";
import "./Style.css";
import Icon from "../../UI/Atoms/Icon/Icon";
import Button from "../../UI/Atoms/Button/Button";
import { ChatBot } from "../../Util/ChatBot";

import { createMessages, MessageData } from "../../Data/Message";
import ChatBubble from "../../UI/Molecules/ChatBubble/ChatBubble";
import { ChatSuggestion, customDate, customTime } from "../../Data/Suggestion";
import ChatTip from "../../UI/Molecules/ChatTip/ChatTip";
import Calendar from "../../UI/Organizm/Calendar/Calendar";
import Clock from "../../UI/Organizm/Clock/Clock";
import { useSnackbar } from "../../Context/SnackbarProvider";
import { ChatColors } from "../../Util/chatColors";

type ChatProps = {
  chatColors: ChatColors;
  isMobile: boolean;
  isMobileDevice: boolean;
  darkMode: boolean;
  homeProjectID: string;
  onChatFinished: () => void;
  onUpdteName: (name: string) => void;
  onThemeChange: (dark: boolean) => void;
  timeZone: string;
  userID: string;
  userEmail: string;
  userName: string;
  existingUser: boolean;
};

const ChatDlg = (props: ChatProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);

  const chatBotRef = useRef<ChatBot>(
    new ChatBot(
      props.onUpdteName,
      props.onChatFinished,
      props.timeZone,
      props.userID,
      props.homeProjectID,
      props.existingUser,
      props.userName,
      props.userEmail
    )
  );
  const chatBot = chatBotRef.current;

  const [darkMode, setDarkMode] = useState(props.darkMode);

  const bottomDivRef = useRef<HTMLDivElement>(null); // Ref for the bottom div
  const headerRef = useRef<HTMLDivElement>(null); // Ref for the header div
  const [maxHeight, setMaxHeight] = useState("80vh"); // Default maxHeight

  const chatContainerRef = useRef<HTMLDivElement>(null); // Ref for the chat container

  const [resetValue, setResetValue] = useState(false);
  const { addSnackbar } = useSnackbar();

  const [existingUser] = useState(props.existingUser);

  const [pickDate, setPickDate] = useState(false);

  const [lastUploadedIndex, setLastUploadedIndex] = useState(0);

  const [pickTime, setPickTime] = useState(false);

  const [suggestions, setSuggestions] = useState<ChatSuggestion[]>(
    chatBot.getChatTips()
  );

  useEffect(() => {
    if (pickDate || pickTime) {
      scrollToBottom();
    }
    if (pickTime) {
      setPickDate(false);
    }
  }, [pickDate, pickTime]);

  const [messages, setMessages] = useState<MessageData[]>(
    chatBot.processUserMessage({
      text: "",
      isSentByUser: false,
      highlight: false,
      suggestionID: 0,
      created: new Date(),
    })
  );

  useEffect(() => {
    uploadMessages();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    chatBot.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resetValue) setResetValue(false);
  }, [resetValue]);

  const sendMsg = (
    messageText: string,
    suggestionID: number,
    dateSelected: Date | null,
    exampleString: string | null
  ) => {
    messageText = messageText.trim();
    if (messageText !== "") {
      const newMessages = [...messages];

      // Create a MessageData object for the new message
      const messageData: MessageData = {
        text: messageText,
        isSentByUser: true,
        DateTime: dateSelected ? dateSelected : undefined,
        highlight: false,
        exampleText: exampleString ? exampleString : undefined,
        suggestionID: suggestionID,
        created: new Date(),
      };

      newMessages.push(messageData); // Add the new message to the array
      setMessages(newMessages); // Update state with the new messages

      if (inputRef.current) inputRef.current.value = ""; // Clear the input field
      setResetValue(true); // Trigger reset

      let botResponses: MessageData[] = chatBot.processUserMessage(messageData);

      newMessages.push(...botResponses); // Add the new message to the array
      setMessages(newMessages);

      setSuggestions(chatBot.getChatTips()); // Update chat tips
    }
  };

  useEffect(() => {
    const adjustMaxHeight = () => {
      if (bottomDivRef.current && headerRef.current) {
        const bottomDivHeight = bottomDivRef.current.offsetHeight; // Height of the bottom div
        const availableHeight = window.innerHeight; // Total available height
        setMaxHeight(
          `${
            availableHeight -
            bottomDivHeight -
            6 -
            headerRef.current.offsetHeight -
            100
          }px`
        ); // Set dynamic maxHeight
      }
    };

    adjustMaxHeight();
    window.addEventListener("resize", adjustMaxHeight); // Recalculate on resize
    return () => window.removeEventListener("resize", adjustMaxHeight);
  }, []);

  const onSuggestionClick = (suggestion: ChatSuggestion) => {
    if (suggestion.suggestionID === customDate) {
      chatBot.setDateSelection(true);
      setSuggestions(chatBot.getChatTips());
      setPickDate(true);
      return;
    } else if (suggestion.suggestionID === customTime) {
      chatBot.setTimeSelection(true);
      setSuggestions(chatBot.getChatTips());
      setPickTime(true);
      return;
    }

    chatBot.setTimeSelection(false);
    chatBot.setDateSelection(false);

    setPickDate(false);
    setPickTime(false);
    sendMsg(
      suggestion.text,
      suggestion.suggestionID,
      null,
      suggestion.exampleText ? suggestion.exampleText : null
    );
  };

  const uploadMessages = () => {
    if (chatBot.reminderAdded()) {
      addSnackbar(chatBot.getReminderString(), 3, "", () => {}, true, true);
    }

    let index = lastUploadedIndex;

    let messagesToUpload = messages.slice(index, messages.length);
    if (messagesToUpload.length > 0) {
      createMessages(messagesToUpload, props.userID);

      index = messages.length;
    }

    setLastUploadedIndex(index);
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  // Scroll to the bottom on new messages
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        justifyContent: "space-between",
        height: "100%",
        alignItems: props.isMobile ? "" : "center",
      }}
    >
      <div
        ref={headerRef}
        style={{
          marginTop: "8px",
          display: "flex",
          justifyContent: "space-between",
          width: props.isMobile ? "100%" : "33%",
        }}
      >
        <div
          style={{ fontSize: "24px", paddingLeft: "6px", fontWeight: "bold" }}
        >
          Hi ✋
        </div>
        <div
          style={{
            display: "flex",

            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              marginTop: "2px",
              marginRight: "14px",
            }}
          >
            <Button
              padding="0px"
              fontSize="24px"
              iconComponent={
                <Icon
                  darkModeIcon={!props.darkMode}
                  brightModeIcon={props.darkMode}
                />
              }
              id="replace_theme_btn"
              label=""
              textButton={true}
              onClick={() => {
                props.onThemeChange(!darkMode);
                setDarkMode(!darkMode);
              }}
            />
          </div>
          <Button
            padding="0px"
            fontSize="24px"
            id="skip_btn"
            label={existingUser ? "BACK TO APP 👉" : "SKIP WIZARD 👉"}
            textButton={true}
            onClick={props.onChatFinished}
          />
        </div>
      </div>
      <div
        style={{
          width: props.isMobile ? "100%" : "33%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          className="msg_container"
          ref={chatContainerRef}
          style={{
            overflowY: "auto",
            maxHeight: maxHeight,
            paddingLeft: props.isMobile ? "6px" : "6px",
            paddingRight: props.isMobile ? "6px" : "6px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {" "}
            {messages.map((message, index) => (
              <ChatBubble
                key={index}
                message={message}
                colors={props.chatColors}
              />
            ))}
          </div>
          {pickTime && (
            <Clock
              currentTime={new Date()}
              isMobile={false}
              onTimeSelected={function (
                time: Date,
                AM: boolean,
                formattedTime: string
              ): void {
                sendMsg(formattedTime, 0, time, null);
                setPickTime(false);
              }}
              onInvalidTime={function (): void {}}
            />
          )}
          {pickDate && (
            <Calendar
              isMobile={false}
              onDateSelected={function (day: Date): void {
                sendMsg(day.toDateString(), 0, day, null);
                setPickDate(false);
              }}
              preventDefaultMouseDown={false}
              allowPastDates={false}
            />
          )}
          <div
            style={{
              display: "flex",
              alignItems: chatBot.areSuggestionsInColumn() ? "flex-start" : "",
              justifyContent: chatBot.areSuggestionsInColumn()
                ? "flex-start"
                : "flex-end",
              flexDirection: chatBot.areSuggestionsInColumn()
                ? "column"
                : "row",
              flexWrap: "wrap",
              gap: "6px",
            }}
          >
            {" "}
            {suggestions.map((suggestion, index) => (
              <ChatTip
                colors={props.chatColors}
                key={index}
                suggestion={suggestion}
                onClick={onSuggestionClick}
              />
            ))}
          </div>
        </div>
        <div
          ref={bottomDivRef}
          style={{
            display: "flex",
            paddingLeft: props.isMobile ? "6px" : "0px",
            paddingRight: props.isMobile ? "6px" : "0px",
            marginBottom: "6px",
          }}
        >
          {chatBot.isOpenTextMessage() && (
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                border: "1px solid lightgray",
                marginTop: "8px",
                borderRadius: "8px",
                padding: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",

                  width: "100%",
                }}
              >
                <EditBox
                  marginTop="3px"
                  marginBottom="0px"
                  onEnterPress={() => {
                    sendMsg(inputRef.current!.value, 0, null, null);
                  }}
                  EditRef={inputRef}
                  disableBorder={true}
                  type="text"
                  placeholder={chatBot.getPlaceholderText()}
                  id="new_msg"
                  resetValue={resetValue}
                  focus={!props.isMobileDevice}
                  padding={8}
                  expandVertical={true}
                  fontSize={18}
                />
              </div>
              <div
                style={{
                  marginBottom: "3px",
                  marginLeft: "6px",
                  display: "flex",
                }}
              >
                <Button
                  marginAfterIcon="0px"
                  iconComponent={
                    <Icon
                      color={props.chatColors.newMsgSendButtonColor}
                      fontSize={24}
                      sendIcon={true}
                    />
                  }
                  id="send_msg"
                  label=""
                  textButton={true}
                  onClick={() => {
                    sendMsg(inputRef.current!.value, 0, null, null);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatDlg;
