import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import CloseIcon from "@mui/icons-material/Close";
import FlagIcon from "@mui/icons-material/Flag";
import PushPinIcon from "@mui/icons-material/PushPin";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import ListIcon from "@mui/icons-material/List";
import AlarmIcon from "@mui/icons-material/Alarm";
import DateRangeIcon from "@mui/icons-material/DateRange";
import Person from "@mui/icons-material/Person";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Logout from "@mui/icons-material/Logout";
import Notes from "@mui/icons-material/Notes";
import Back from "@mui/icons-material/ArrowBack";
import Undo from "@mui/icons-material/Undo";
import ContentCopy from "@mui/icons-material/ContentCopy";
import Send from "@mui/icons-material/Send";
import Add from "@mui/icons-material/Add";
import Warning from "@mui/icons-material/Warning";
import Watch from "@mui/icons-material/Watch";
import Forward from "@mui/icons-material/KeyboardArrowRight";
import Backward from "@mui/icons-material/KeyboardArrowLeft";
import History from "@mui/icons-material/History";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import EmailIcon from "@mui/icons-material/Email";
import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";
import LightModeIcon from "@mui/icons-material/LightMode";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import Login from "@mui/icons-material/Login";
import Lock from "@mui/icons-material/Lock";
import Google from "@mui/icons-material/Google";
import Apple from "@mui/icons-material/Apple";
import LocationOn from "@mui/icons-material/LocationOn";
import Repeat from "@mui/icons-material/Repeat";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import TuneIcon from "@mui/icons-material/Tune";

import SmartphoneIcon from "@mui/icons-material/Smartphone";
import ComputerIcon from "@mui/icons-material/Computer";
import TodaySharpIcon from "@mui/icons-material/TodaySharp";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import WeekendIcon from "@mui/icons-material/Weekend";
import SearchIcon from "@mui/icons-material/Search";
import AdbIcon from "@mui/icons-material/Adb";

import Chat from "@mui/icons-material/Chat";

type IconProps = {
  menuIcon?: boolean;
  sendIcon?: boolean;
  restoreIcon?: boolean;
  todayIcon?: boolean;
  signoutIcon?: boolean;
  closeIcon?: boolean;
  moreIcon?: boolean;
  alarmIcon?: boolean;
  flagIcon?: boolean;
  chatIcon?: boolean;
  flagIconOutlined?: boolean;
  copyIcon?: boolean;
  homeIcon?: boolean;
  repeat?: boolean;
  pinIcon?: boolean;
  listIcon?: boolean;
  dateIcon?: boolean;
  color?: string;
  fontSize?: number;
  timeIcon?: boolean;
  userIcon?: boolean;
  dailyAgenda?: boolean;
  editIcon?: boolean;
  doneIcon?: boolean;
  deleteIcon?: boolean;
  showIcon?: boolean;
  hideIcon?: boolean;
  detailsIcon?: boolean;
  backIcon?: boolean;
  newIcon?: boolean;
  warnIcon?: boolean;
  history?: boolean;
  forwardIcon?: boolean;
  backwardIcon?: boolean;
  darkModeIcon?: boolean;
  emailIcon?: boolean;
  themeSelection?: boolean;
  brightModeIcon?: boolean;
  register?: boolean;
  login?: boolean;
  lock?: boolean;
  google?: boolean;
  apple?: boolean;
  location?: boolean;
  top?: boolean;
  view?: boolean;
  desktopIcon?: boolean;
  mobileIcon?: boolean;
  weekend?: boolean;
  search?: boolean;
  script?: boolean;
};

const List = (props: IconProps) => {
  const getIcon = () => {
    if (props.menuIcon) return <MenuIcon fontSize={"inherit"} />;
    else if (props.closeIcon) return <CloseIcon fontSize="inherit" />;
    else if (props.flagIcon) return <FlagIcon fontSize="inherit" />;
    else if (props.flagIconOutlined)
      return <FlagOutlinedIcon fontSize="inherit" />;
    else if (props.homeIcon) return <HomeIcon fontSize="inherit" />;
    else if (props.pinIcon) return <PushPinIcon fontSize="inherit" />;
    else if (props.listIcon) return <ListIcon fontSize="inherit" />;
    else if (props.alarmIcon) return <AlarmIcon fontSize="inherit" />;
    else if (props.dateIcon) return <DateRangeIcon fontSize="inherit" />;
    else if (props.userIcon) return <Person fontSize="inherit" />;
    else if (props.moreIcon) return <MoreHorizIcon fontSize="inherit" />;
    else if (props.editIcon) return <EditIcon fontSize="inherit" />;
    else if (props.deleteIcon) return <DeleteIcon fontSize="inherit" />;
    else if (props.doneIcon) return <DoneIcon fontSize="inherit" />;
    else if (props.showIcon) return <Visibility fontSize="inherit" />;
    else if (props.hideIcon) return <VisibilityOff fontSize="inherit" />;
    else if (props.signoutIcon) return <Logout fontSize="inherit" />;
    else if (props.detailsIcon) return <Notes fontSize="inherit" />;
    else if (props.backIcon) return <Back fontSize="inherit" />;
    else if (props.restoreIcon) return <Undo fontSize="inherit" />;
    else if (props.copyIcon) return <ContentCopy fontSize="inherit" />;
    else if (props.sendIcon) return <Send fontSize="inherit" />;
    else if (props.newIcon) return <Add fontSize="inherit" />;
    else if (props.warnIcon) return <Warning fontSize="inherit" />;
    else if (props.timeIcon) return <Watch fontSize="inherit" />;
    else if (props.forwardIcon) return <Forward fontSize="inherit" />;
    else if (props.backwardIcon) return <Backward fontSize="inherit" />;
    else if (props.history) return <History fontSize="inherit" />;
    else if (props.darkModeIcon) return <DarkModeIcon fontSize="inherit" />;
    else if (props.emailIcon) return <EmailIcon fontSize="inherit" />;
    else if (props.brightModeIcon) return <LightModeIcon fontSize="inherit" />;
    else if (props.themeSelection)
      return <SettingsBrightnessIcon fontSize="inherit" />;
    else if (props.register) return <AppRegistrationIcon fontSize="inherit" />;
    else if (props.login) return <Login fontSize="inherit" />;
    else if (props.lock) return <Lock fontSize="inherit" />;
    else if (props.google) return <Google fontSize="inherit" />;
    else if (props.apple) return <Apple fontSize="inherit" />;
    else if (props.location) return <LocationOn fontSize="inherit" />;
    else if (props.repeat) return <Repeat fontSize="inherit" />;
    else if (props.top) return <KeyboardDoubleArrowUpIcon fontSize="inherit" />;
    else if (props.view) return <TuneIcon fontSize="inherit" />;
    else if (props.desktopIcon) return <ComputerIcon fontSize="inherit" />;
    else if (props.mobileIcon) return <SmartphoneIcon fontSize="inherit" />;
    else if (props.todayIcon) return <TodaySharpIcon fontSize="inherit" />;
    else if (props.dailyAgenda) return <CalendarTodayIcon fontSize="inherit" />;
    else if (props.weekend) return <WeekendIcon fontSize="inherit" />;
    else if (props.search) return <SearchIcon fontSize="inherit" />;
    else if (props.script) return <AdbIcon fontSize="inherit" />;
    else if (props.chatIcon) return <Chat fontSize="inherit" />;
    return <></>;
  };

  return (
    <div
      style={{
        fontSize: props.fontSize ? `${props.fontSize}px` : "",
        color: props.color || "",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {getIcon()}
    </div>
  );
};

export default List;
