import { ReactNode, useContext, useEffect, useState } from "react";
import Button from "../../UI/Atoms/Button/Button";
import Icon from "../../UI/Atoms/Icon/Icon";
import { ThemeContext } from "../../Context/Color";

import {
  Reminder,
  createReminder,
  deleteReminder,
  formatReminderString,
} from "../../Data/Reminder";
import { Timestamp } from "firebase/firestore";

import { useOverlay } from "../../Context/OverlayProvider";
import DueDate from "../DueDate/DueDate";
import { useData } from "../../Context/Data";

import WarnDlg from "../../UI/Organizm/WarnDlg/WarnDlg";
import closeNewTask from "../../Util/closeNewTask";

import Dialog from "../../UI/Organizm/Dialog/Dialog";
import { useSnackbar } from "../../Context/SnackbarProvider";

import { DateFormat } from "../../Util/Date";
import {
  updateDesktopNotifications,
  updateEmailNotifications,
  updatePushNotifications,
} from "../../Data/Task";
import { UserData } from "../../Data/User";
import { DueDateReminder } from "../../Data/DueDateReminder";

type ReminderProps = {
  isMobile: boolean;
  editMode: boolean;
  userTimeZone: string;
  dueDateWithTime: boolean;
  DueDateReminders: DueDateReminder;
  taskHasDueDateReminders: boolean;
  mobile: boolean;
  desktop: boolean;
  email: boolean;
  userID?: string;
  taskID?: string;
  currentReminders: Reminder[];
  onClose: (
    reminders: Reminder[],
    mobile: boolean,
    desktop: boolean,
    email: boolean
  ) => void;
  onSave: (
    reminders: Reminder[],
    mobile: boolean,
    desktop: boolean,
    email: boolean
  ) => void;
  onCancel: () => void;
  updateUI?: (
    reminders: Reminder[],
    mobile: boolean,
    desktop: boolean,
    email: boolean
  ) => void;
};

const Reminders = (props: ReminderProps): JSX.Element => {
  useEffect(() => {
    closeNewTask();
  }, []);

  const { addSnackbar } = useSnackbar();

  const { addOverlay, removeOverlay } = useOverlay();

  const themeContext = useContext(ThemeContext);

  const dataProvider = useData();

  const [mobile, setMobile] = useState(props.mobile);
  const [email, setEmail] = useState(props.email);
  const [desktop, setDesktop] = useState(props.desktop);

  const [reminders, setReminders] = useState<Reminder[]>(
    props.currentReminders
  );

  useEffect(() => {
    if (props.editMode && props.updateUI)
      props.updateUI(reminders, mobile, desktop, email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reminders, mobile, desktop, email]);

  // Function to add a new date to the array
  const addReminder = async (
    reminderTime: Date,
    recurring: boolean,
    daily: boolean,
    weekly: boolean,
    monthly: boolean,
    yearly: boolean,
    dayOfMonth: number,
    dayOfWeek: number,
    recurringMonthLastDay: boolean,
    excludeWeekends: boolean
  ) => {
    // Convert the JavaScript Date object to a Firestore Timestamp

    let timeZone = props.userTimeZone;

    if (timeZone === "Asia/Kolkata (IST)") timeZone = "Asia/Kolkata";

    reminderTime = new DateFormat().syncDateWithUserTimeZone(
      timeZone,
      reminderTime
    );

    reminderTime.setMilliseconds(0);
    const newTimestamp = Timestamp.fromDate(reminderTime);

    const isDuplicate = reminders.some((reminder) => {
      return (
        reminder.time.toMillis() === newTimestamp.toMillis() &&
        recurring === reminder.recurring &&
        daily === reminder.daily &&
        weekly === reminder.weekly &&
        monthly === reminder.monthly &&
        yearly === reminder.yearly &&
        reminder.dayOfMonth === dayOfMonth &&
        reminder.dayOfWeek === dayOfWeek &&
        reminder.recurringMonthLastDay === recurringMonthLastDay &&
        !reminder.deleted
      );
    });

    if (!isDuplicate) {
      const newReminder: Reminder = {
        time: newTimestamp,
        recurring: recurring,
        weekly: weekly,
        monthly: monthly,
        yearly: yearly,
        excludeWeekends: excludeWeekends,
        cronJobID: "",
        daily: daily,
        done: false,
        dayOfMonth: dayOfMonth,
        dayOfWeek: dayOfWeek,
        recurringMonthLastDay: recurringMonthLastDay,
        created: Timestamp.now(),
        taskID:
          !props.editMode || !props.taskID ? "some-task-id" : props.taskID,
        deviceToken: "",
        reminderID: generateReminderID(),
        deleted: false,
        utcOffset: -new Date().getTimezoneOffset(),
        timeZoneName: timeZone,
      };

      if (props.userID && props.editMode) {
        let recID = await createReminder(newReminder, props.userID);
        newReminder.id = recID;
      }

      setReminders([...reminders, newReminder]);
    } else {
      addSnackbar("This reminder already exists");
    }
  };

  const generateReminderID = (): number => {
    return Math.floor(Math.random() * (Math.pow(2, 31) - 1));
  };

  const onOverlayClose = () => {
    removeOverlay();
  };

  const onNew = () => {
    addOverlay(
      <DueDate
        dueDateReminders={new DueDateReminder()}
        firstDayOfWeek={
          dataProvider.getUser() === undefined
            ? 2
            : dataProvider.getUser()!.firstDayOfWeek
        }
        currentDateWithTime={false}
        isMobile={props.isMobile}
        reminder={true}
        onClose={() => {
          onOverlayClose();
        }}
        onSave={(
          date,
          withTime,
          recurring,
          daily,
          weekly,
          monthly,
          yearly,
          dayOfMonth,
          dayOfWeek,
          lastDay,
          excludeWeekends
        ) => {
          addReminder(
            date,
            recurring,
            daily,
            weekly,
            monthly,
            yearly,
            dayOfMonth,
            dayOfWeek,
            lastDay,
            excludeWeekends
          );

          onOverlayClose();
        }}
      />,
      {
        closeOnClickOutside: true,
        centerX: true,
        marginTop: 100,
      },
      true
    );
  };

  const onDeleteReminder = (reminderID: number, reminderRecordID: string) => {
    let newReminders: Reminder[] = [];
    if (!props.editMode) {
      newReminders = reminders.filter(
        (reminder) => reminder.reminderID !== reminderID
      );
    } else {
      if (props.userID) deleteReminder(props.userID, reminderRecordID);
      newReminders = reminders.map((reminder) => {
        if (reminder.reminderID === reminderID) {
          return {
            ...reminder,
            deleted: true,
          };
        }
        return reminder;
      });
    }

    setReminders(newReminders);
  };

  const remindersToText = (): ReactNode => {
    if (reminders.length === 0 && !props.taskHasDueDateReminders) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center", // Centers the content horizontally
            alignItems: "center", // Centers the content vertically
            fontFamily: "calibri",
            fontSize: "22px",
            fontWeight: "bold",
            padding: "20px", // Adds some padding around the text
            color: themeContext?.textColor,
          }}
        >
          Add as many reminders as you want 🚀
        </div>
      );
    }

    const filteredReminders = reminders
      .filter((reminder) => !reminder.deleted)
      .sort((a, b) => {
        const dateA = a.time.toDate();
        const dateB = b.time.toDate();
        return dateA.getTime() - dateB.getTime();
      });

    // Create an array to store the reminder lines and any extra elements
    const reminderElements = [];

    // Add 'Exactly on time' section if it meets the criteria
    if (
      props.taskHasDueDateReminders &&
      props.DueDateReminders.exactlyOnTime &&
      props.dueDateWithTime
    ) {
      reminderElements.push(
        <div
          key="exactly-on-time"
          style={{ display: "flex", marginBottom: "12px", fontSize: "24px" }}
        >
          Exactly on time
        </div>
      );
    }

    if (
      props.taskHasDueDateReminders &&
      props.DueDateReminders.minutesBefore &&
      props.dueDateWithTime
    ) {
      reminderElements.push(
        <div
          key="minutes-before"
          style={{ display: "flex", marginBottom: "12px", fontSize: "24px" }}
        >
          {props.DueDateReminders.selectedMinutesBefore} minutes before
        </div>
      );
    }

    if (
      props.taskHasDueDateReminders &&
      props.DueDateReminders.hoursBefore &&
      props.dueDateWithTime
    ) {
      reminderElements.push(
        <div
          key="hours-before"
          style={{ display: "flex", marginBottom: "12px", fontSize: "24px" }}
        >
          {props.DueDateReminders.selectedHoursBefore} hours before
        </div>
      );
    }

    if (props.taskHasDueDateReminders && props.DueDateReminders.daysBefore) {
      reminderElements.push(
        <div
          key="days-before"
          style={{ display: "flex", marginBottom: "12px", fontSize: "24px" }}
        >
          {props.DueDateReminders.selectedDaysBefore} days before
        </div>
      );
    }

    // Map through filtered reminders and create reminder lines
    const reminderLines = filteredReminders.map((reminder) => {
      let formattedDate = formatReminderString(
        reminder,
        true,
        props.userTimeZone
      );

      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: "24px",
          }}
          key={reminder.id + "_existing"}
        >
          <div
            key="rem_time"
            style={{ display: "flex", color: themeContext?.textColor }}
          >
            {formattedDate}
          </div>
          <div key="rem_icon" style={{ marginTop: "2px" }}>
            <Button
              id={reminder.id!}
              textButton={true}
              label=""
              iconComponent={<Icon fontSize={24} deleteIcon={true} />}
              onClick={() => {
                addOverlay(
                  <WarnDlg
                    onCancel={() => {
                      removeOverlay();
                    }}
                    onApprove={() => {
                      onDeleteReminder(reminder.reminderID, reminder.id!);
                      removeOverlay();
                    }}
                    isMobile={props.isMobile}
                    icon={<Icon deleteIcon={true} />}
                    applyBtnRed={true}
                    title="Are you sure?"
                    subTitle={"This reminder will be permanently deleted."}
                    cancelBtnText="CANCEL"
                    approveBtnText="YES, DELETE IT"
                  />,
                  {
                    closeOnClickOutside: true,
                    centerX: true,
                    marginTop: 100,
                    width: props.isMobile ? "" : "",
                  },
                  true
                );
              }}
            />
          </div>
        </div>
      );
    });

    // Add reminder lines to the elements array
    reminderElements.push(...reminderLines);

    // Return all the elements as a React fragment
    return <>{reminderElements}</>;
  };

  const onRemindersSave = () => {
    // console.log(props.onSave);
    props.onSave(reminders, mobile, desktop, email);
  };

  const onResolutionChange = (mobile: boolean) => {};

  let user: UserData = dataProvider.getUser()!;

  return (
    <Dialog
      onCancel={props.onCancel}
      isMobile={props.isMobile}
      onResolutionChange={onResolutionChange}
      closeBar={true}
      saveCancel={!props.editMode}
      widthDesktop={"600px"}
      widthMobile="100%"
      onClose={onRemindersSave}
      onSave={onRemindersSave}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          padding: "10px",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          centerText={true}
          id="REMINDERS_NEW"
          fontSize="20px"
          label="ADD REMINDER"
          iconComponent={<Icon fontSize={26} newIcon={true} />}
          textButton={false}
          onClick={onNew}
        />
      </div>
      {remindersToText()}

      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}
      >
        <Button
          iconComponent={<Icon mobileIcon={true} />}
          id={"push_notification"}
          label={"Mobile Push Notifications"}
          textButton={true}
          checkBoxRight={true}
          checkBoxRightChecked={mobile}
          onClick={() => {
            setMobile(!mobile);
            updatePushNotifications(!mobile, props.userID!, props.taskID!);
          }}
        />
        {!user.pushNotifications && (
          <div style={{ color: themeContext?.flagColor }}>
            You have not enabled mobile push notifications. Enable them in
            settings.
          </div>
        )}
        <Button
          iconComponent={<Icon desktopIcon={true} />}
          id={"desktop_push_notification"}
          label={"Desktop Push Notifications"}
          textButton={true}
          checkBoxRight={true}
          checkBoxRightChecked={desktop}
          onClick={() => {
            setDesktop(!desktop);
            updateDesktopNotifications(!desktop, props.userID!, props.taskID!);
          }}
        />
        {!user.desktopNotifications && (
          <div style={{ color: themeContext?.flagColor }}>
            You have not enabled desktop notifications. Enable them in settings.
          </div>
        )}
        <Button
          iconComponent={<Icon emailIcon={true} />}
          checkBoxRightChecked={email}
          id={"email_notification"}
          label={"Email"}
          textButton={true}
          checkBoxRight={true}
          onClick={() => {
            setEmail(!email);
            updateEmailNotifications(!email, props.userID!, props.taskID!);
          }}
        />
        {!user.emailNotifications && (
          <div style={{ color: themeContext?.flagColor }}>
            You have not enabled email notifications. Enable them in settings.
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default Reminders;
