import { useEffect, useState } from "react";

const Platform = () => {
  const [isMobile, setIsMobile] = useState(checkIfMobile());
  const [isMobileResolution, setIsMobileResolution] = useState(
    checkLowResolution()
  );

  useEffect(() => {
    setIsMobile(checkIfMobile());

    const checkIfLowResolution = () => {
      const screenWidth = window.innerWidth;
      setIsMobileResolution(screenWidth < 1200);
    };

    checkIfMobile();
    checkIfLowResolution();

    const handleResize = () => {
      checkIfLowResolution();
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { isMobile, isMobileResolution };
};

export function isiPhone() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes("iphone");
}

export function isAndroid() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes("android");
}

function isOneWeekAgo(dateString: string) {
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7); // Subtract 7 days
  const lastShownDate = new Date(dateString);

  return lastShownDate <= oneWeekAgo;
}

function oneWeekSincePromotion() {
  // Retrieve the last shown date from local storage, if it exists
  const lastShownDate = localStorage.getItem("lastPromotionShownDate");

  // If there's no record in local storage, or it's been more than a week,
  // show the promotion and update the last shown date in local storage
  if (!lastShownDate || isOneWeekAgo(lastShownDate)) {
    // Display the promotion (you can add your display logic here)

    // Update the last shown date in local storage
    const currentDate = new Date().toISOString();
    localStorage.setItem("lastPromotionShownDate", currentDate);

    return true;
  }

  // If the promotion was shown less than a week ago, return false
  return false;
}

export function promoteMobileApp() {
  return (isAndroid() || isiPhone()) && oneWeekSincePromotion();
}

export function checkLowResolution() {
  const screenWidth = window.innerWidth;
  return screenWidth < 1200;
}

export function checkIfMobile() {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobileDevice =
    /mobile|android|iphone|ipad|iemobile|wpdesktop|windows phone|blackberry|tablet|nokia/i.test(
      userAgent
    );
  return isMobileDevice;
}

export default Platform;
