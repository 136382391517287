import { Timestamp } from "firebase/firestore";
import {
  setDocumentInFirestore,
  updateDocumentInFirestore,
  updateMultipleDocumentsInFirestore,
} from "./Crud";

import { checkIfMobile } from "../Util/Platform";

export interface UserData {
  email: string;
  lastPlatform?: string;
  remindersEmail?: string;
  uid: string;
  name: string;
  timeZone: string;
  lastTimeZone: string;
  lastDayOfWeek: number;
  introduced: boolean;
  filter: boolean;
  admin: boolean;
  sendDailyAgenda: boolean;
  showTodayMenu: boolean;
  showUpcomingMenu: boolean;
  sendAgendaOnWeekend: boolean;
  dailyAgendaTime: string;
  agendaCronID: string;
  desktopNotifications?: boolean;
  pushNotifications?: boolean;
  listCount?: number;
  taskCount?: number;
  remindersCount?: number;
  emailNotifications?: boolean;
  firstDayOfWeek: number;
  lastActivity?: Timestamp;
  created?: Timestamp;
  updated?: Timestamp;
}

export function fromJson(json: UserData): UserData {
  return {
    created: json.created! ?? new Date(),
    updated: json.updated! ?? new Date(),
    lastActivity: json.lastActivity! ?? null,
    firstDayOfWeek: json["firstDayOfWeek"] || 2,
    lastDayOfWeek: json["lastDayOfWeek"] || 6,
    introduced: json["introduced"] || false,
    dailyAgendaTime: json["dailyAgendaTime"] ?? "09:00",
    agendaCronID: json["agendaCronID"] || "",
    showTodayMenu: json["showTodayMenu"] ?? true,
    showUpcomingMenu: json["showUpcomingMenu"] ?? true,
    sendDailyAgenda: json["sendDailyAgenda"] ?? true,
    sendAgendaOnWeekend: json["sendAgendaOnWeekend"] ?? false,
    desktopNotifications:
      json.desktopNotifications === undefined
        ? true
        : json.desktopNotifications,
    emailNotifications:
      json.emailNotifications === undefined ? true : json.emailNotifications,
    pushNotifications:
      json.pushNotifications === undefined ? true : json.pushNotifications,
    taskCount: json["taskCount"] || 0,
    listCount: json["listCount"] || 0,
    remindersCount: json["remindersCount"] || 0,
    admin: json["admin"] || false,
    filter: json["filter"] || false,
    timeZone: json["timeZone"] || "",
    lastTimeZone: json["lastTimeZone"] || "",
    uid: json["uid"] || "",
    email: json["email"] || "",
    remindersEmail: json["remindersEmail"] || "",
    lastPlatform: json["lastPlatform"] || getPlatform(),
    name: json["name"] || "",
  };
}

function toJson(user: UserData) {
  return {
    created: user.created || Timestamp.now(),
    updated: user.updated || Timestamp.now(),
    firstDayOfWeek: user.firstDayOfWeek || 2,
    lastDayOfWeek: user.lastDayOfWeek || 6,
    introduced: user.introduced || false,
    lastActivity: Timestamp.now(),
    lastPlatform: getPlatform(),
    admin: user.admin || false,
    sendDailyAgenda: user.sendDailyAgenda || true,
    sendAgendaOnWeekend: user.sendAgendaOnWeekend || false,
    showTodayMenu: user.showTodayMenu || true,
    showUpcomingMenu: user.showUpcomingMenu || true,
    dailyAgendaTime: user.dailyAgendaTime || "09:00",
    desktopNotifications: user.desktopNotifications || true,
    emailNotifications: user.emailNotifications || true,
    pushNotifications: user.pushNotifications || true,
    timeZone: user.timeZone || "",
    lastTimeZone: user.lastTimeZone || "",
    filter: user.filter || false,
    uid: user.uid || "",
    email: user.email || "",
    remindersEmail: user.remindersEmail || user.email,
    name: user.name || "",
  }; // You need to handle the parsing or setting null in the place where you call this function
}

export type UserUpdate = Partial<UserData>;

export const createUser = async (
  userRecord: UserData,
  userID: string
): Promise<void> => {
  return setDocumentInFirestore(toJson(userRecord), `users`, userID);
};

export const getPlatform = () => {
  return checkIfMobile() ? "web-mobile" : "web";
};

export const excludeAllUsers = async (userIds: string[]) => {
  // Prepare user data for updateMultipleDocumentsInFirestore
  const usersToUpdate = userIds.map((userId) => ({
    id: userId, // Each user ID
    data: {
      filter: true,
    },
  }));

  try {
    // Update all user documents at once
    await updateMultipleDocumentsInFirestore(usersToUpdate, "users");
    // console.log("Users updated successfully");
  } catch (e) {
    console.error("Error updating users", e);
  }
};

export const updateUserRecCounts = async (
  tasks: number,
  lists: number,
  reminders: number,
  userID: string
) => {
  try {
    await updateUserData(userID, {
      listCount: lists,
      taskCount: tasks,
      remindersCount: reminders,
    });
    return true;
  } catch (error) {
    console.error("Error updating user in Firestore", error);
    throw error;
  }
};

export const updateIntoduced = async (
  introduced: boolean,
  userEmail: string,
  userID: string
) => {
  try {
    await updateUserData(userID, {
      introduced: introduced,
      firstDayOfWeek: 2,
      lastDayOfWeek: 6,
      remindersEmail: userEmail,
      admin: false,
      filter: false,
      lastPlatform: getPlatform(),
    });
    return true;
  } catch (error) {
    console.error("Error updating user in Firestore", error);
    throw error;
  }
};

export const updateUserPlatform = async (platform: string, userID: string) => {
  try {
    updateUserData(userID, {
      lastPlatform: platform,
    });
  } catch (error) {
    throw error;
  }
};

export const updateUserLastActivity = async (
  lastActivity: Date,
  userID: string
) => {
  try {
    updateUserData(userID, {
      lastActivity: Timestamp.fromDate(lastActivity),
    });
  } catch (error) {
    throw error;
  }
};

export const setMenuSettings = async (
  userID: string,
  showToday: boolean,
  showUpcoming: boolean
) => {
  try {
    updateUserData(userID, {
      showTodayMenu: showToday,
      showUpcomingMenu: showUpcoming,
    });
  } catch (error) {
    throw error;
  }
};

export const cancelDailyAgenda = async (userID: string) => {
  try {
    updateUserData(userID, {
      sendDailyAgenda: false,
      sendAgendaOnWeekend: false,
    });
  } catch (error) {
    throw error;
  }
};

export const updateAgendaSettings = async (
  sendAgenda: boolean,
  sendAgendaOnWeekend: boolean,
  agendaTime: string,
  userID: string
) => {
  try {
    updateUserData(userID, {
      sendDailyAgenda: sendAgenda,
      sendAgendaOnWeekend: sendAgendaOnWeekend,
      dailyAgendaTime: agendaTime,
    });
  } catch (error) {
    throw error;
  }
};

export const updateName = async (name: string, userID: string) => {
  try {
    updateUserData(userID, {
      name: name,
      lastPlatform: getPlatform(),
    });
  } catch (error) {
    throw error;
  }
};

export const updateLastTimeZone = async (timeZone: string, userID: string) => {
  try {
    updateUserData(userID, {
      lastTimeZone: timeZone,
    });
  } catch (error) {
    throw error;
  }
};

export const updateTimeZoneOnly = async (timeZone: string, userID: string) => {
  try {
    updateUserData(userID, {
      timeZone: timeZone,
    });
  } catch (error) {
    throw error;
  }
};

export const updateTimeZone = async (timeZone: string, userID: string) => {
  try {
    updateUserData(userID, {
      timeZone: timeZone,
      lastTimeZone: timeZone,
    });
  } catch (error) {
    throw error;
  }
};

export const updateDaysOfWeek = async (
  firstDay: number,
  lastDay: number,
  userID: string
) => {
  try {
    updateUserData(userID, {
      firstDayOfWeek: firstDay,
      lastDayOfWeek: lastDay,
    });
  } catch (error) {
    throw error;
  }
};

export const updateReminderSettings = async (
  email: string,
  email_notificationd: boolean,
  desktop_notifications: boolean,
  mobile_notification: boolean,
  userID: string
) => {
  console.log(mobile_notification);
  try {
    updateUserData(userID, {
      remindersEmail: email,
      emailNotifications: email_notificationd,
      desktopNotifications: desktop_notifications,
      pushNotifications: mobile_notification,
    });
  } catch (error) {
    throw error;
  }
};

export const updateRemindersEmail = async (email: string, userID: string) => {
  try {
    updateUserData(userID, {
      remindersEmail: email,
    });
  } catch (error) {
    throw error;
  }
};

const updateUserData = async (userID: string, doc: UserUpdate) => {
  if (!userID) {
    return;
  }

  doc.updated = Timestamp.now();

  try {
    await updateDocumentInFirestore(doc, `users`, userID);
    //   console.log("Order updated in Firestore");
  } catch (error) {
    console.error("Error updating order in Firestore", error);
    throw error;
  }
};
