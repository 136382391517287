const closeNewTask = () => {
  const element = document.getElementById("new_task_component");

  if (!element) {
    console.warn(`TEXTAREA NOT FOUND - CANNOT BLUR`);
    return;
  }

  element.blur();
};

export default closeNewTask;
