import { useOverlay } from "../../../Context/OverlayProvider";
import Dialog from "../Dialog/Dialog";
import { useContext, useState } from "react";
import "./Style.css";
import { ThemeContext } from "../../../Context/Color";
import Icon from "../../Atoms/Icon/Icon";

type DayDlgProps = {
  firstDayOfWeek: number;
  lastDayOfWeek: number;
  userID: string;
  isMobile: boolean;
  onSave: (firstDay: number, lastDay: number) => void;
  onCancel: () => void;
};

const DayDlg = (props: DayDlgProps) => {
  const themeContext = useContext(ThemeContext);

  const [firstDay, setFirstDay] = useState(props.firstDayOfWeek);
  const [lastDay, setLastDay] = useState(props.lastDayOfWeek);

  const { removeOverlay } = useOverlay();

  const weekDays = [
    { Name: "Sunday", Value: 1 },
    { Name: "Monday", Value: 2 },
    { Name: "Tuesday", Value: 3 },
    { Name: "Wednesday", Value: 4 },
    { Name: "Thursday", Value: 5 },
    { Name: "Friday", Value: 6 },
    { Name: "Saturday", Value: 7 },
  ];

  const handleFirstDayChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    let newValue = parseInt(event.target.value);

    setFirstDay(newValue);
  };

  const handleLastDayChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let newValue = parseInt(event.target.value);

    setLastDay(newValue);
  };

  return (
    <Dialog
      widthDesktop="400px"
      widthMobile=""
      closeBar={true}
      isMobile={props.isMobile}
      onSave={() => {
        props.onSave(firstDay, lastDay);
      }}
      saveCancel={true}
      onCancel={() => {
        removeOverlay();
      }}
      onClose={() => {
        removeOverlay();
      }}
    >
      <div
        style={{
          display: "flex",
          color: themeContext?.textColor,
          flexDirection: "column",
        }}
      >
        <div
          style={{
            fontFamily: "Calibri",
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "20px",
          }}
        >
          Weekdays Settings
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "8px" }}>
            <Icon alarmIcon={true} />
          </div>
          <div>
            This will affect your daily reminders that are without weekends.
          </div>
        </div>
        <div
          style={{
            marginBottom: "16px",
            marginTop: "16px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontWeight: "bold", fontSize: "20px" }}>First day:</div>
          <select
            value={firstDay}
            onChange={handleFirstDayChange}
            style={{ fontSize: "16px", padding: "5px" }}
          >
            {weekDays.map((day) => (
              <option key={day.Value} value={day.Value}>
                {day.Name}
              </option>
            ))}
          </select>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ fontWeight: "bold", fontSize: "20px" }}>
            {"Last day:"}
          </div>
          <select
            value={lastDay}
            onChange={handleLastDayChange}
            style={{ fontSize: "16px", padding: "5px" }}
          >
            {weekDays.map((day) => (
              <option key={day.Value} value={day.Value}>
                {day.Name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </Dialog>
  );
};

export default DayDlg;
