import { useOverlay } from "../../../Context/OverlayProvider";
import Dialog from "../Dialog/Dialog";
import { useState } from "react";
import { Timestamp } from "firebase/firestore";
import "./Style.css";
import ListRow from "../ListRow/ListRow";
import Button from "../../Atoms/Button/Button";
import EditDlg from "../EditDlg/EditDlg";
import { Project, createProject } from "../../../Data/Project";

import { useSnackbar } from "../../../Context/SnackbarProvider";

type AddListsDlgProps = {
  userID: string;
  textColor: string;
  isMobile: boolean;
  onSave: (day: number) => void;
  onCancel: () => void;
};

const AddListsDlg = (props: AddListsDlgProps) => {
  const handleListClick = (checked: boolean, name: string) => {
    if (checked) {
      setSelectedLists((prevLists) => [...prevLists, name]);
    } else {
      setSelectedLists((prevLists) =>
        prevLists.filter((list) => list !== name)
      );
    }
  };

  const { addOverlay, removeOverlay } = useOverlay();

  const { addSnackbar } = useSnackbar();

  const [selectedLists, setSelectedLists] = useState<string[]>([]);

  const listNames = [
    "📝 Notes",
    "🏋️‍♂️ Fitness",
    "🛒 Shopping",
    "📚 Study",
    "🧹 Chores",
    "🎉 Events",
    "📅 Schedule",
    "📌 Tasks",
    "👥 Team",
    "📞 Calls",
    "💡 Ideas",
    "💻 Work",
    "🚀 Goals",
    "🎨 Creative",
    "💰 Finances",
    "👨‍👩‍👧‍👦 Family",
    "🌱 Personal Growth",
    "🎓 Education",
    "🗣️ Communication",
    "👟 Sports",
    "👶 Parenting",
  ];

  const addProject = async (name: string, showSnackBar: boolean) => {
    const p: Project = {
      name: name,
      created: Timestamp.now(),
      updated: Timestamp.now(),
      owner: props.userID,
    };
    createProject(p, props.userID);
    if (showSnackBar) addSnackbar("🤘 List Added!");
    removeOverlay();
  };

  const onAddMyOwn = () => {
    addOverlay(
      <EditDlg
        header="List Name"
        placeHolder=""
        isMobile={props.isMobile}
        saveBtnText="Add List"
        allowEmptyValue={false}
        maxLength={150}
        expandVertical={true}
        initialValue={""}
        cancelBtnText="Cancel"
        emailValidation={false}
        headerSize={24}
        onSave={(listName: string) => {
          addProject(listName, true);
        }}
        onCancel={() => {
          removeOverlay();
        }}
      />,
      {
        closeOnClickOutside: false,
        centerX: true,

        width: "100%",
        marginTop: 8,
      },
      true
    );
  };

  return (
    <Dialog
      widthDesktop="400px"
      widthMobile=""
      closeBar={false}
      isMobile={props.isMobile}
      saveLabel="Add selected lists"
      onSave={() => {
        selectedLists.forEach((item) => {
          addProject(item, false);
        });
        if (selectedLists.length > 0) addSnackbar("🎉 New lists added");
        removeOverlay();
      }}
      saveCancel={true}
      onCancel={() => {
        removeOverlay();
      }}
      onClose={() => {
        removeOverlay();
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", padding: "20px" }}
      >
        <div
          style={{
            fontFamily: "Calibri",
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "20px",
            color: props.textColor,
          }}
        >
          Select Lists
        </div>
        <Button
          id="add_my_own"
          centerText={true}
          label="💡 Add my own"
          textButton={false}
          marginBottom={8}
          onClick={() => {
            onAddMyOwn();
          }}
        />
        {listNames.map((name, index) => (
          <ListRow
            marginBottom="8px"
            fontSize="16px"
            title={name}
            checkBox={true}
            onClick={(checked) => {
              handleListClick(checked, name);
            }}
          />
        ))}
      </div>
    </Dialog>
  );
};

export default AddListsDlg;
