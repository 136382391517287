import Button from "../../UI/Atoms/Button/Button";
import Icon from "../../UI/Atoms/Icon/Icon";
import { useContext, useEffect, useRef, useState } from "react";
import EditBox from "../../UI/Atoms/EditBox/EditBox";
import EditDlg from "../../UI/Organizm/EditDlg/EditDlg";
import { useOverlay } from "../../Context/OverlayProvider";
import Reminders from "../Reminders/Reminders";
import DueDate from "../DueDate/DueDate";

import { DateFormat } from "../../Util/Date";
import { useData } from "../../Context/Data";
import { Reminder } from "../../Data/Reminder";
import { useSnackbar } from "../../Context/SnackbarProvider";
import closeNewTask from "../../Util/closeNewTask";
import WarnDlg from "../../UI/Organizm/WarnDlg/WarnDlg";
import { ThemeContext } from "../../Context/Color";
import MoveDlg from "../../UI/Organizm/MoveDlg/MoveDlg";
import { DueDateReminder } from "../../Data/DueDateReminder";

type NewTaskProps = {
  onTaskSave: (
    TaskName: string,
    listID: string,
    flagged: boolean,
    description: string,
    mobileNotification: boolean,
    desktopNotifications: boolean,
    emailNotifications: boolean,
    dueDateHasTime: boolean,
    reminders: Reminder[],
    dueDateReminder: DueDateReminder,
    dueDate?: Date,
    multiple?: boolean
  ) => Promise<boolean>;
  isMobile: boolean;
  userTimeZone: string;
  selectList: boolean;
  todayView: boolean;
  upcomingView: boolean;
};

const NewTask = (props: NewTaskProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [optionsRow, setOptionsRow] = useState(false);
  const [resetValue, setResetValue] = useState(false);
  const [taskDescription, setTaskDescription] = useState("");
  const { addOverlay, removeOverlay, isActiveOverlay } = useOverlay();
  const [taskFlag, setTaskFlag] = useState(false);
  const [discardDescription, setDiscardDescription] = useState(true);

  const [dueDate, setDueDate] = useState<Date | null>(
    props.todayView || props.upcomingView ? new Date() : null
  );
  const [dueDateHasTime, setDueDateHasTime] = useState(false);
  const [reminders, setReminders] = useState<Reminder[]>([]);

  const [dueDateReminder, setDueDateReminder] = useState(new DueDateReminder());

  const [hightlightSave, sethightlightSave] = useState(false);

  const [mobile, setMobile] = useState(true);
  const [desktop, setDesktop] = useState(true);

  const [email, setEmail] = useState(true);

  const dataProvider = useData();
  const { addSnackbar } = useSnackbar();

  const themeContext = useContext(ThemeContext);

  const [selectedList, setSelectedList] = useState<string>(
    props.selectList ? dataProvider.getHomeProjectID() : ""
  );

  const onNewTask = async () => {
    if (!inputRef || !inputRef.current) return;

    setResetValue(true);

    props.onTaskSave(
      inputRef.current.value,
      selectedList,
      taskFlag,
      taskDescription,
      mobile,
      desktop,
      email,
      dueDateHasTime,
      reminders,
      dueDateReminder,
      dueDate ?? undefined
    );
  };

  const newTaskDraftWithReminders = () => {
    return reminders.length > 0;
  };

  const calendarDateDraft = () => {
    if (!props.upcomingView && !props.todayView) return true;

    return false;
  };

  const newTaskDraft = () => {
    return (
      taskFlag ||
      taskDescription.trim() !== "" ||
      (dueDate !== null && calendarDateDraft()) ||
      !isTaskNameEmpty()
    );
  };

  useEffect(() => {
    setDueDate(props.todayView || props.upcomingView ? new Date() : null);
  }, [props.todayView, props.upcomingView]);

  useEffect(() => {
    setSelectedList(props.selectList ? dataProvider.getHomeProjectID() : "");
  }, [dataProvider, props.selectList]);

  useEffect(() => {
    if (!optionsRow && newTaskDraftWithReminders()) {
      addSnackbar("DRAFT SAVED WITH REMINDERS ⏰");
    } else if (!optionsRow && newTaskDraft()) {
      addSnackbar("DRAFT SAVED 😉");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsRow]);

  const onNameFcous = (e: any) => {
    setOptionsRow(true);
  };

  const onNameBlur = (e: any) => {
    if (!isActiveOverlay()) setOptionsRow(false);
  };

  const isDescriptionEmpty = (): boolean => {
    return taskDescription === "";
  };

  const getShortDescription = (): string => {
    const maxLength = 10;
    if (taskDescription.length > maxLength) {
      return taskDescription.substring(0, maxLength) + "...";
    }
    return taskDescription;
  };
  useEffect(() => {
    if (resetValue) {
      sethightlightSave(false);
      setTaskFlag(false);
      setDueDateReminder(new DueDateReminder());
      setResetValue(false);
      setTaskDescription("");
      setReminders([]);
      setDueDate(props.todayView || props.upcomingView ? new Date() : null);
      setDueDateHasTime(false);
      setMobile(true);
      setDesktop(true);
      setEmail(true);
    }
  }, [props.todayView, props.upcomingView, resetValue]);

  useEffect(() => {
    if (!discardDescription) onDescription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discardDescription]);

  const onOverlayClose = () => {
    removeOverlay();
    setOptionsRow(true);

    if (inputRef.current) inputRef.current.focus();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isTaskNameEmpty = () => {
    if (!inputRef.current || !inputRef.current.value) return true;

    let value: string = inputRef.current.value;

    value = value.trim();
    return value === "";
  };

  const onReminders = () => {
    /* if (isTaskNameEmpty()) {
      addOverlay(
        <InfoDlg
          onOK={onOverlayClose}
          preventDefaultMouseDown={true}
          message="Add a task name to set reminders!  😀"
        />,
        {
          onClose: onOverlayClose,
          closeOnClickOutside: true,
          centerX: true,
          centerY: false,
          marginTop: 100,
        }
      );

      return;
    }*/
    addOverlay(
      <Reminders
        dueDateWithTime={false}
        taskHasDueDateReminders={false}
        DueDateReminders={new DueDateReminder()}
        userTimeZone={props.userTimeZone}
        onClose={(
          reminders: Reminder[],
          mobile: boolean,
          desktop: boolean,
          email: boolean
        ) => {
          onSaveReminders(reminders);
        }}
        onCancel={() => {
          onOverlayClose();
        }}
        onSave={(
          reminders: Reminder[],
          mobile: boolean,
          desktop: boolean,
          email: boolean
        ) => {
          setMobile(mobile);
          setDesktop(desktop);
          setEmail(email);
          onSaveReminders(reminders);
        }}
        isMobile={props.isMobile}
        currentReminders={reminders}
        editMode={false}
        mobile={mobile}
        desktop={desktop}
        email={email}
      />,
      {
        closeOnClickOutside: false,
        centerX: true,
        marginTop: 100,

        width: "100%",
      }
    );
  };

  const onDescriptionChange = () => {
    setDiscardDescription(false);
  };

  const onSaveReminders = (reminders: Reminder[]) => {
    setReminders(reminders);
    onOverlayClose();
  };

  const onDueDateSave = (
    date: Date,
    withTime: boolean,
    recurring: boolean,
    daily: boolean,
    weekly: boolean,
    monthly: boolean,
    yearly: boolean,
    dayOfWeek: number,
    dayOfMonth: number,
    lastDayOfMonth: boolean,
    excludeWeekends: boolean,
    dueDateReminders: DueDateReminder
  ) => {
    setDueDateHasTime(withTime);
    setDueDate(date);
    setDueDateReminder(dueDateReminders);
    onOverlayClose();
  };

  const onDueDate = () => {
    addOverlay(
      <DueDate
        dueDateReminders={new DueDateReminder()}
        reminder={false}
        firstDayOfWeek={
          dataProvider.getUser() === undefined
            ? 2
            : dataProvider.getUser()!.firstDayOfWeek
        }
        currentDateWithTime={dueDateHasTime}
        isMobile={props.isMobile}
        currentDate={dueDate === null ? undefined : dueDate}
        onClose={() => {
          onOverlayClose();
        }}
        onSave={onDueDateSave}
      />,
      {
        onClose: onOverlayClose,
        closeOnClickOutside: true,
        centerX: true,
        marginTop: 100,
      }
    );
  };

  function numberOfLines(str: string): number {
    // Split the string by any newline character and count the resulting array's length
    return str.split(/\r?\n/).length;
  }

  const onDescriptionExit = () => {
    setDiscardDescription(true);
    setOptionsRow(true);
    removeOverlay();
    if (inputRef.current) inputRef.current.focus();
  };

  const handlePaste = (event: React.ClipboardEvent) => {
    let pastedValue = event.clipboardData.getData("Text");
    pastedValue = pastedValue.trim();
    let lines: number = numberOfLines(pastedValue);

    if (lines > 1) {
      addOverlay(
        <WarnDlg
          onCancel={() => {
            removeOverlay();
            if (inputRef.current) inputRef.current.focus();
          }}
          onApprove={() => {
            setResetValue(true);
            removeOverlay();
            props.onTaskSave(
              pastedValue,
              selectedList,
              taskFlag,
              taskDescription,
              mobile,
              desktop,
              email,
              dueDateHasTime,
              [],
              dueDateReminder,
              dueDate ?? undefined,
              true
            );
          }}
          isMobile={props.isMobile}
          icon={<Icon newIcon={true} />}
          applyBtnRed={false}
          title={`Add ${lines} tasks?`}
          subTitle={`Your copied text has ${lines} tasks.`}
          cancelBtnText="ADD SINGLE TASK"
          approveBtnText={`ADD ${lines} TASKS`}
        />,
        {
          closeOnClickOutside: true,
          centerX: true,

          width: props.isMobile ? "" : "",
          marginTop: 100,
        },
        true
      );
    }
    // You can now use pastedValue as needed
  };

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape" && optionsRow) {
        setOptionsRow(false);
        closeNewTask();
      }
    };

    document.addEventListener("keydown", onKeyDown);

    // Cleanup: remove the listener when the component is unmounted or when there are no overlays
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [optionsRow]);

  const getDueDateBtnText = (): string => {
    if (dueDate === null) {
      return props.isMobile ? "" : "Due Date";
    }

    const dateFormatter = new DateFormat();

    return dateFormatter.formatDate(dueDate, dueDateHasTime);
  };

  const getRemindersLabel = (): string => {
    if (reminders.length === 0) return props.isMobile ? "" : "Reminders";

    return "(" + reminders.length.toString() + ")";
  };

  const onDescription = () => {
    addOverlay(
      <EditDlg
        isMobile={props.isMobile}
        header="Task description"
        placeHolder="Enter Description"
        allowEmptyValue={true}
        onInputChange={onDescriptionChange}
        enterNewLine={true}
        saveBtnText="Save"
        cancelBtnText="Cancel"
        expandVertical={true}
        initialValue={taskDescription}
        headerSize={24}
        onSave={(description: string) => {
          setDiscardDescription(true);
          setTaskDescription(description);
          setOptionsRow(true);
          removeOverlay();
          if (inputRef.current) inputRef.current.focus();
        }}
        onCancel={() => {
          onDescriptionExit();
        }}
      />,
      {
        closeOnClickOutside: discardDescription,
        onClose: onDescriptionExit,
        centerX: true,
        marginTop: 100,

        width: "100%",
      }
    );
  };

  const getListName = () => {
    const projects = dataProvider.getLists();

    const project = projects.find((p) => p.id === selectedList);

    return project ? project.name : "";
  };

  const onSelectList = () => {
    addOverlay(
      <MoveDlg
        onProjectClick={(projectID: string, projectName: string) => {
          setSelectedList(projectID);
          if (inputRef.current) inputRef.current.focus();

          addSnackbar("List Updated: " + projectName);
          removeOverlay();
        }}
        listSelect={true}
        isMobile={props.isMobile}
        projects={dataProvider.getLists()}
        taskName={""}
        taskID={""}
        projectID={selectedList}
        projectName={""}
      />,
      {
        closeOnClickOutside: true,
        marginBottom: 30,
        centerX: true,
        marginTop: 100,
      },
      true
    );
  };

  return (
    <div
      onMouseDown={(e) => {}}
      style={{
        marginLeft: props.isMobile ? "10px" : "",
        marginRight: props.isMobile ? "10px" : "",
        paddingRight: props.isMobile ? "" : "20px",
        marginTop: "10px",

        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", flex: 1 }}>
          <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <EditBox
              EditRef={inputRef}
              onPaste={handlePaste}
              id="new_task_component"
              onFocus={onNameFcous}
              onBlur={onNameBlur}
              onInputChange={(value) => {
                sethightlightSave(isTaskNameEmpty() ? false : true);
              }}
              resetValue={resetValue}
              onEnterPress={onNewTask}
              fontSize={20}
              disableBorder={true}
              backgroundWhenClosed={true}
              maxLength={200}
              type="text"
              padding={12}
              expandVertical={true}
              placeholder="+ Add a task..."
              focus={false}
            />
          </div>
        </div>

        {optionsRow && (
          <div
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            style={{
              paddingTop: "10px",
              display: "flex",
              flex: 1,
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "space-between",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  fontSize: props.isMobile ? "20px" : "20px",
                  flexWrap: "wrap",
                  gap: props.isMobile ? "8px" : "",
                }}
              >
                {props.selectList && (
                  <Button
                    id="add_flag"
                    actionTip={"Select List"}
                    actionTipPosition="bottom"
                    label={props.isMobile ? "" : getListName()}
                    padding={"0px"}
                    marginRight={10}
                    onMouseDown={(e) => e.preventDefault()}
                    iconComponent={
                      <Icon color={themeContext?.iconColor} listIcon={true} />
                    }
                    textButton={true}
                    onClick={() => {
                      onSelectList();
                    }}
                  ></Button>
                )}
                <Button
                  id="add_flag"
                  actionTip={
                    !taskFlag
                      ? "Set priority: important"
                      : "Set priority: normal"
                  }
                  actionTipPosition="bottom"
                  label={props.isMobile ? "" : "Important"}
                  padding={"0px"}
                  marginRight={10}
                  onMouseDown={(e) => e.preventDefault()}
                  iconComponent={
                    <Icon
                      color={!taskFlag ? themeContext?.iconColor : "red"}
                      flagIcon={true}
                    />
                  }
                  textButton={true}
                  onClick={() => {
                    setTaskFlag(!taskFlag);
                  }}
                ></Button>

                <Button
                  id="add_reminder"
                  actionTip="Add Reminders"
                  actionTipPosition="bottom"
                  textButtonColor={
                    reminders.length === 0
                      ? undefined
                      : themeContext?.activeItemColor
                  }
                  label={getRemindersLabel()}
                  padding={"0px"}
                  onMouseDown={(e) => e.preventDefault()}
                  iconComponent={
                    <Icon color={themeContext?.iconColor} alarmIcon={true} />
                  }
                  textButton={true}
                  marginRight={10}
                  onClick={() => {
                    onReminders();
                  }}
                ></Button>
                <Button
                  id="add_description"
                  actionTip="Add Description"
                  actionTipPosition="bottom"
                  textButtonColor={
                    isDescriptionEmpty()
                      ? undefined
                      : themeContext?.activeItemColor
                  }
                  label={
                    isDescriptionEmpty()
                      ? props.isMobile
                        ? ""
                        : "Description"
                      : getShortDescription()
                  }
                  onMouseDown={(e) => e.preventDefault()}
                  marginRight={10}
                  padding={"0px"}
                  iconComponent={
                    <Icon
                      color={
                        isDescriptionEmpty()
                          ? themeContext?.iconColor
                          : themeContext?.activeItemColor
                      }
                      detailsIcon={true}
                    />
                  }
                  textButton={true}
                  onClick={() => {
                    onDescription();
                  }}
                ></Button>
                <Button
                  actionTip="Add Due Date"
                  actionTipPosition="bottom"
                  id="add_due_date"
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                  label={getDueDateBtnText()}
                  padding={"0px"}
                  iconComponent={
                    <Icon color={themeContext?.iconColor} dateIcon={true} />
                  }
                  textButton={true}
                  onClick={() => {
                    onDueDate();
                  }}
                ></Button>
              </div>
              <div
                style={{
                  display: "flex",
                  fontSize: props.isMobile ? "20px" : "20px",
                }}
              >
                <Button
                  id="save_task"
                  marginAfterIcon="0px"
                  actionTip="Save Task"
                  actionTipPosition="bottom"
                  textButtonColor={""}
                  backgroundColor={themeContext?.buttonTheme.background}
                  onMouseDown={(e) => e.preventDefault()}
                  label={""}
                  iconComponent={
                    <Icon
                      color={
                        hightlightSave
                          ? themeContext?.buttonTheme.text
                          : themeContext?.iconColor
                      }
                      sendIcon={true}
                    />
                  }
                  textButton={hightlightSave ? false : true}
                  onClick={() => {
                    onNewTask();
                  }}
                ></Button>
              </div>
            </div>
          </div>
        )}
        <div
          onMouseDown={(e) => {
            if (optionsRow) e.preventDefault();
          }}
          style={{
            height: "10px",
          }}
        ></div>
      </div>
    </div>
  );
};

export default NewTask;
