import { useEffect, useState } from "react";

import "./Style.css";
import Button from "../../Atoms/Button/Button";
import Icon from "../../Atoms/Icon/Icon";

type ClockProps = {
  isMobile: boolean;
  currentTime: Date;
  onTimeSelected: (time: Date, AM: boolean, formattedTime: string) => void;
  onInvalidTime: () => void;
};

const Clock = (props: ClockProps) => {
  const [currentHour, setCurrentHour] = useState<number>(() => {
    const hour = props.currentTime?.getHours() || 0;
    return hour % 12 || 12; // Convert to 12-hour format
  });
  const [currentMinute, setCurrentMinute] = useState<number>(0);

  const [hourPick, setHourPick] = useState<boolean>(true);

  const [selectedTime, setSelectedTime] = useState<Date>(new Date());

  const [timeAM, setTimeAM] = useState<boolean>(isAM(props.currentTime));

  useEffect(() => {
    selectedTime.setMinutes(0);
    setSelectedTime(new Date(selectedTime));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function isAM(date: Date) {
    const hours = date.getHours();
    return hours < 12; // Returns true if it's AM, false if it's PM
  }

  function formatTimeWithoutSeconds(date: Date, showAM: boolean) {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const isAM = hours < 12;

    // Convert to 12-hour format
    hours = hours % 12 || 12;

    // Format minutes with leading zero if needed
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    // Determine AM or PM
    const period = showAM ? (isAM ? "AM" : "PM") : "";

    return `${hours}:${formattedMinutes} ${period}`;
  }

  const handleClick = (hour: number) => {
    if (hourPick) {
      let fullHour: number = 0;
      fullHour = timeAM ? hour : hour + 12;
      setCurrentHour(hour);
      selectedTime.setHours(fullHour);
      setSelectedTime(new Date(selectedTime));

      setHourPick(false);
    } else {
      if (hour !== 0) {
        hour = hour * 5;
      }
      setCurrentMinute(hour);
      selectedTime.setMinutes(hour);
      /* props.onTimeSelected(
        selectedTime,
        timeAM,
        formatTimeWithoutSeconds(selectedTime, true)
      );*/
    }
  };

  const generateClock = () => {
    const hours = [];
    const radius = 39; // Half of the clock's size to determine the positioning of hours
    const centerX = 50; // Center of the circle
    const centerY = 50; // Center of the circle

    for (let i = 0; i < 12; i++) {
      const angle = (i * 30 * Math.PI) / 180 - Math.PI / 2; // Adjust for "12" at the top
      const x = centerX + radius * Math.cos(angle);
      const y = centerY + radius * Math.sin(angle);
      hours.push({ hour: i, x, y });
    }

    return hours;
  };

  const getNumber = (hour: number): string => {
    hour = hour === 0 ? 12 : hour;

    if (!hourPick && hour === 12) {
      hour = 0;
    } else if (!hourPick && hour === 1) {
      hour = 5;
    } else if (!hourPick && hour === 2) {
      hour = 10;
    } else if (!hourPick && hour === 3) {
      hour = 15;
    } else if (!hourPick && hour === 4) {
      hour = 20;
    } else if (!hourPick && hour === 5) {
      hour = 25;
    } else if (!hourPick && hour === 6) {
      hour = 30;
    } else if (!hourPick && hour === 7) {
      hour = 35;
    } else if (!hourPick && hour === 8) {
      hour = 40;
    } else if (!hourPick && hour === 9) {
      hour = 45;
    } else if (!hourPick && hour === 10) {
      hour = 50;
    } else if (!hourPick && hour === 11) {
      hour = 55;
    }

    return hourPick
      ? hour.toString()
      : hour < 10
      ? `0${hour}`
      : hour.toString();
  };

  const isSelected = (hour: number) => {
    if (!hourPick && hour !== 0) {
      hour = hour * 5;
    }
    return hourPick ? hour === currentHour : hour === currentMinute;
  };

  const getFormattedHour = () => {
    let items = formatTimeWithoutSeconds(selectedTime, false).split(":");
    if (items.length < 2) return "";

    return items[0];
  };

  const getFormattedMinute = () => {
    let items = formatTimeWithoutSeconds(selectedTime, false).split(":");
    if (items.length < 2) return "";
    return items[1];
  };

  return (
    <div
      className="clock"
      style={{
        display: "flex",
        fontFamily: "Roboto",
        userSelect: "none",
        flexDirection: "column",
        marginBottom: "26px",
        marginTop: "26px",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", fontSize: "44px" }}>
        <div style={{ display: "flex" }}>
          <div
            onClick={() => {
              setHourPick(true);
            }}
            style={{ cursor: "pointer" }}
          >
            {getFormattedHour()}
          </div>
          <div>:</div>
          <div
            onClick={() => {
              setHourPick(false);
            }}
            style={{ cursor: "pointer" }}
          >
            {getFormattedMinute()}{" "}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "6px",

            marginLeft: "16px",
            flexDirection: "column",
          }}
        >
          <div
            onClick={() => {
              if (!timeAM) {
                setSelectedTime(
                  new Date(selectedTime.setHours(selectedTime.getHours() - 12))
                );
              }
              setTimeAM(true);
            }}
            className="clickable"
            style={{
              fontWeight: timeAM ? "bold" : "",
              cursor: "pointer",
              fontSize: "22px",
              borderBottom: timeAM ? "2px solid black" : "",
            }}
          >
            AM
          </div>
          <div
            onClick={() => {
              if (timeAM) {
                setSelectedTime(
                  new Date(selectedTime.setHours(selectedTime.getHours() + 12))
                );
              }
              setTimeAM(false);
            }}
            className="clickable"
            style={{
              fontWeight: !timeAM ? "bold" : "",
              cursor: "pointer",
              fontSize: "22px",
              borderBottom: !timeAM ? "2px solid black" : "",
            }}
          >
            PM
          </div>
        </div>
      </div>
      <div>
        <div style={{ display: "flex", fontSize: "26px" }}>
          <Button
            id="switch_to_hour"
            actionTip={hourPick ? "" : "Switch to hour selection"}
            disabled={hourPick}
            label=""
            textButton={true}
            iconComponent={<Icon backwardIcon={true} />}
            onClick={() => {
              setHourPick(true);
            }}
          />
          <Button
            id="switch_to_minute"
            actionTip={!hourPick ? "" : "Switch to minute selection"}
            label=""
            disabled={!hourPick}
            textButton={true}
            iconComponent={<Icon forwardIcon={true} />}
            onClick={() => {
              setHourPick(false);
            }}
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            marginTop: "12px",
            position: "relative",
            width: "200px",
            height: "200px",
            borderRadius: "50%",
            backgroundColor: "#ededed",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {generateClock().map(({ hour, x, y }) => (
            <div
              className="clickable"
              key={hour}
              onClick={() => handleClick(hour)}
              style={{
                position: "absolute",
                left: `${x}%`,
                top: `${y}%`,
                transform: "translate(-50%, -50%)",
                fontSize: "20px",
                borderRadius: "60%",

                backgroundColor: isSelected(hour) ? "#1976d2" : "",
                padding: "6px",
                border: isSelected(hour) ? "2px solid black" : "",
                fontWeight: isSelected(hour) ? "bold" : "normal",
                color: isSelected(hour) ? "white" : "black",
                cursor: "pointer",
              }}
            >
              <div>{getNumber(hour)}</div>
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "22px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              id="finish_selection"
              textButtonColor="#1f79d3"
              boldText={true}
              label="OK"
              textButton={true}
              onClick={() => {
                props.onTimeSelected(
                  selectedTime,
                  timeAM,
                  formatTimeWithoutSeconds(selectedTime, true)
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clock;
