export enum ReminderType {
  oneTime,
  daily,
  weekly,
  monthly,
  yearly,
}

export class ReminderChat {
  type: ReminderType;
  dailyReminderOnWeekends: boolean;
  reminderName: string;
  reminderDate: Date;
  lastMonthDay: boolean;

  weeklyDay: number;
  monthlyDay: number;

  constructor({
    type = ReminderType.oneTime,
    dailyReminderOnWeekends = false,
    reminderName = "",
    weeklyDay = 1,
    monthlyDay = 1,
    lastMonthDay = false,
    reminderDate = new Date(),
  }: {
    type?: ReminderType;
    dailyReminderOnWeekends?: boolean;
    reminderName?: string;
    weeklyDay?: number;
    monthlyDay?: number;
    lastMonthDay?: boolean;
    reminderDate?: Date;
  }) {
    this.type = type;
    this.dailyReminderOnWeekends = dailyReminderOnWeekends;
    this.reminderName = reminderName;
    this.weeklyDay = weeklyDay;
    this.monthlyDay = monthlyDay;
    this.lastMonthDay = lastMonthDay;
    this.reminderDate = reminderDate;
  }

  getMonthDay(): string {
    if (this.lastMonthDay) {
      return "Last day of the month";
    } else {
      return `${this.monthlyDay} of the month`;
    }
  }

  getWeekDayName(): string {
    switch (this.weeklyDay) {
      case 1:
        return "Sunday";
      case 2:
        return "Monday";
      case 3:
        return "Tuesday";
      case 4:
        return "Wednesday";
      case 5:
        return "Thursday";
      case 6:
        return "Friday";
      case 7:
        return "Saturday";
      default:
        return "";
    }
  }

  // Reset function to reset properties to their default values
  reset(): void {
    this.type = ReminderType.oneTime;
    this.dailyReminderOnWeekends = false;
    this.reminderName = "";
    this.reminderDate = new Date();
    this.weeklyDay = 2;
    this.monthlyDay = 1;
    this.lastMonthDay = false;
  }
}
