import { useOverlay } from "../../../Context/OverlayProvider";
import Dialog from "../Dialog/Dialog";
import { useContext, useState } from "react";
import { ThemeContext } from "../../../Context/Color";
import CheckBoxBtn from "../../Atoms/CheckBox/CheckBox";
import { DueDateReminder } from "../../../Data/DueDateReminder";
import TimePick from "../TimePick/TimePick";

type DueDateReminderDlgProps = {
  isMobile: boolean;
  dueDateReminder: DueDateReminder;
  dueDateHasTime: boolean;

  onSave: (reminders: DueDateReminder) => void;
  onCancel: () => void;
};

const DueDateReminderDlg = (props: DueDateReminderDlgProps) => {
  const themeContext = useContext(ThemeContext);
  const { removeOverlay } = useOverlay();

  const daysBeforeOptions = Array.from({ length: 21 }, (_, i) => i + 1);
  const hoursBeforeOptions = Array.from({ length: 23 }, (_, i) => i + 1);
  const minutesBeforeOptions = [15, 30, 45];

  // State for tracking intervals only used in picklists
  const [reminderTime, setReminderTime] = useState(
    props.dueDateReminder.selectedTime
  );

  const [exactlyOnTime, setExactlyOnTime] = useState(
    props.dueDateReminder.exactlyOnTime
  );
  const [minutesBefore, setMinutesBefore] = useState(
    props.dueDateReminder.minutesBefore
  );
  const [hoursBefore, setHoursBefore] = useState(
    props.dueDateReminder.hoursBefore
  );
  const [daysBefore, setDaysBefore] = useState(
    props.dueDateReminder.daysBefore
  );

  const [selectedMinutes, setSelectedMinutes] = useState(
    props.dueDateReminder.selectedMinutesBefore
  );
  const [selectedHours, setSelectedHours] = useState(
    props.dueDateReminder.selectedHoursBefore
  );
  const [selectedDays, setSelectedDays] = useState(
    props.dueDateReminder.selectedDaysBefore
  );

  const onExactlyOnTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExactlyOnTime(event.target.checked);
  };

  const onMinutesBefore = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMinutesBefore(event.target.checked);
  };

  const onHoursBefore = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHoursBefore(event.target.checked);
  };

  const onDaysBefore = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDaysBefore(event.target.checked);
  };

  const onSave = () => {
    const reminders = new DueDateReminder();
    reminders.exactlyOnTime = exactlyOnTime;
    reminders.minutesBefore = minutesBefore;
    reminders.hoursBefore = hoursBefore;
    reminders.daysBefore = daysBefore;
    reminders.dueDateReminderChanged = true;
    reminders.customDueDateReminder = true;
    reminders.selectedMinutesBefore = selectedMinutes;
    reminders.selectedHoursBefore = selectedHours;
    reminders.selectedDaysBefore = selectedDays;
    reminders.selectedTime = reminderTime;
    props.onSave(reminders);
  };

  return (
    <Dialog
      widthDesktop="400px"
      widthMobile=""
      closeBar={true}
      isMobile={props.isMobile}
      onSave={onSave}
      saveCancel={true}
      onCancel={() => {
        removeOverlay();
      }}
      onClose={() => {
        removeOverlay();
      }}
    >
      <div
        style={{
          display: "flex",
          color: themeContext?.textColor,
          flexDirection: "column",
        }}
      >
        <div
          style={{
            fontFamily: "Calibri",
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "20px",
          }}
        >
          Select Reminders Options
        </div>
        {props.dueDateHasTime && (
          <div style={{ display: "flex" }}>
            <CheckBoxBtn
              onCheck={onExactlyOnTime}
              checked={exactlyOnTime}
              text="Exactly On Time"
              checkBoxID="exactly_on_time"
            />
          </div>
        )}
        {props.dueDateHasTime && (
          <div style={{ display: "flex", marginTop: "4px" }}>
            <CheckBoxBtn
              onCheck={onMinutesBefore}
              checked={minutesBefore}
              text="Minutes Before"
              checkBoxID="minutes_before"
            />
            <div style={{ marginLeft: "8px" }}>
              <select
                disabled={!minutesBefore}
                onChange={(e) => setSelectedMinutes(Number(e.target.value))}
                value={selectedMinutes}
              >
                {minutesBeforeOptions.map((option: number) => (
                  <option key={option} value={option}>
                    {option} minutes
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        {props.dueDateHasTime && (
          <div style={{ display: "flex", marginTop: "4px" }}>
            <CheckBoxBtn
              onCheck={onHoursBefore}
              checked={hoursBefore}
              text="Hours Before"
              checkBoxID="hours_before"
            />
            <div style={{ marginLeft: "8px" }}>
              <select
                disabled={!hoursBefore}
                onChange={(e) => setSelectedHours(Number(e.target.value))}
                value={selectedHours}
              >
                {hoursBeforeOptions.map((option: number) => (
                  <option key={option} value={option}>
                    {option === 1 ? "1 hour" : `${option} hours`}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        <div style={{ display: "flex", marginTop: "4px" }}>
          <CheckBoxBtn
            onCheck={onDaysBefore}
            checked={daysBefore}
            text="Days Before"
            checkBoxID="days_before"
          />
          <div style={{ marginLeft: "8px" }}>
            <select
              disabled={!daysBefore}
              onChange={(e) => setSelectedDays(Number(e.target.value))}
              value={selectedDays}
            >
              {daysBeforeOptions.map((option: number) => (
                <option key={option} value={option}>
                  {option === 1 ? "1 day" : `${option} days`}
                </option>
              ))}
            </select>
          </div>
        </div>
        {daysBefore && (
          <div style={{ marginTop: "16px", marginLeft: "8px" }}>
            <TimePick
              tooltip=""
              textAlign="left"
              title="AT:"
              parentDivBorder={1}
              focus={false}
              reminder={false}
              width={200}
              isMobile={props.isMobile}
              onFocus={(focus: boolean) => {
                //    setValidTime(false);
              }}
              onInvalidTime={() => {
                // Handle invalid time
              }}
              currentTime={reminderTime}
              onTimeSelected={(time) => {
                setReminderTime(time);
              }}
            />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default DueDateReminderDlg;
