import { useOverlay } from "../../../Context/OverlayProvider";
import Dialog from "../Dialog/Dialog";
import { useContext, useState } from "react";
import "./Style.css";
import Icon from "../../Atoms/Icon/Icon";

import { ThemeContext } from "../../../Context/Color";

type ThemeDlgProps = {
  currentTheme: number;
  userID: string;
  isMobile: boolean;
  onSave: (theme: number) => void;
  onCancel: () => void;
};

const ThemeDlg = (props: ThemeDlgProps) => {
  const [theme, setTheme] = useState(props.currentTheme);
  const { removeOverlay } = useOverlay();
  const themeContext = useContext(ThemeContext);

  return (
    <Dialog
      widthDesktop="400px"
      widthMobile=""
      closeBar={true}
      isMobile={props.isMobile}
      onSave={() => {
        props.onSave(theme);
      }}
      saveCancel={true}
      onCancel={() => {
        removeOverlay();
      }}
      onClose={() => {
        removeOverlay();
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          color: themeContext?.textColor,
        }}
      >
        <div
          style={{
            fontFamily: "Calibri",
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "20px",
          }}
        >
          Select Theme
        </div>
        <div>
          <div className="radio_div" onClick={() => setTheme(0)}>
            <input
              type="radio"
              name="theme"
              value={0}
              checked={theme === 0}
              onChange={() => {
                setTheme(0);
              }}
            />
            <div className="icon_div">
              <Icon themeSelection={true} />
            </div>
            System Default
          </div>
          <div className="radio_div" onClick={() => setTheme(1)}>
            <input
              type="radio"
              name="theme"
              value={1}
              checked={theme === 1}
              onChange={() => {
                setTheme(1);
              }}
            />
            <div className="icon_div">
              <Icon brightModeIcon={true} />
            </div>
            Light
          </div>
          <div className="radio_div" onClick={() => setTheme(2)}>
            <input
              type="radio"
              name="theme"
              value={2}
              checked={theme === 2}
              onChange={() => {
                setTheme(2);
              }}
            />
            <div className="icon_div">
              <Icon darkModeIcon={true} />
            </div>
            Dark
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ThemeDlg;
