import { useContext, useEffect, useRef, useState } from "react";
import EditBox from "../../UI/Atoms/EditBox/EditBox";
import "./Style.css";
import Button from "../../UI/Atoms/Button/Button";
import { updateIntoduced, updateName } from "../../Data/User";
import Spinner from "../../UI/Atoms/Spinner/Spinner";
import { JSX } from "react/jsx-runtime";
import AddListsDlg from "../../UI/Organizm/AddListsDlg/AddListsDlg";
import { useOverlay } from "../../Context/OverlayProvider";
import Logo from "../../UI/Atoms/Logo/Logo";
import { ThemeContext } from "../../Context/Color";
import { LocalStorage } from "../../Util/localStorage";

import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import ChatPage from "../ChatPage/ChatPage";
import { ChatColors } from "../../Util/chatColors";
import { useNavigate } from "react-router-dom";
import { useData } from "../../Context/Data";

type IntroProps = {
  isMobile: boolean;
  isMobileDevice: boolean;
  existingUser: boolean;
  userID: string;
  homeProjectID: string;
  userEmail: string;
  userName: string;
  userTimeZone: string;
  onChatFinished: () => void;
};

const IntroDlg = (props: IntroProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const themeContext = useContext(ThemeContext);

  const [oldIntro] = useState(false);

  const navigate = useNavigate();
  const dataProvider = useData();

  const [showConfetti] = useState(!props.existingUser);

  const storage = new LocalStorage();

  const { addOverlay } = useOverlay();

  const [loading] = useState(false);

  const { width, height } = useWindowSize();

  useEffect(() => {
    setChatColors(
      new ChatColors({
        newMsgPlaceholderColor: themeContext?.chatNewMsgPlaceholderColor ?? "",
        newMsgSendButtonColor: themeContext?.chatNewMsgSendButtonColor ?? "",
        tipBackgroundColor: themeContext?.chatTipBackgroundColor ?? "",
        botTextColor: themeContext?.chatBotTextColor ?? "",
        tipExampleColor: themeContext?.chatTipExampleColor ?? "",
        prominentTextColor: themeContext?.chatProminentTextColor ?? "",
        prominentColor: themeContext?.chatProminentColor ?? "",
        tipColorColumn: themeContext?.chatTipColorColumn ?? "",
        highlightColor: themeContext?.chatHighlightColor ?? "",
        userBackgroundColor: themeContext?.chatUserBackgroundColor ?? "",
        userTextColor: themeContext?.chatUserTextColor ?? "",
        botBackgroundColor: themeContext?.chatBotBackgroundColor ?? "",
        tipColor: themeContext?.chatTipColor ?? "",
        snackBarBackground: themeContext?.chatSnackBarBackground ?? "",
        snackBarText: themeContext?.chatSnackBarText ?? "",
      })
    );
  }, [themeContext?.chatBotBackgroundColor, themeContext]);

  const [chatColors, setChatColors] = useState(
    new ChatColors({
      newMsgPlaceholderColor: themeContext?.chatNewMsgPlaceholderColor ?? "",
      newMsgSendButtonColor: themeContext?.chatNewMsgSendButtonColor ?? "",
      tipBackgroundColor: themeContext?.chatTipBackgroundColor ?? "",
      botTextColor: themeContext?.chatBotTextColor ?? "",
      tipExampleColor: themeContext?.chatTipExampleColor ?? "",
      prominentTextColor: themeContext?.chatProminentTextColor ?? "",
      prominentColor: themeContext?.chatProminentColor ?? "",
      tipColorColumn: themeContext?.chatTipColorColumn ?? "",
      highlightColor: themeContext?.chatHighlightColor ?? "",
      userBackgroundColor: themeContext?.chatUserBackgroundColor ?? "",
      userTextColor: themeContext?.chatUserTextColor ?? "",
      botBackgroundColor: themeContext?.chatBotBackgroundColor ?? "",
      tipColor: themeContext?.chatTipColor ?? "",
      snackBarBackground: themeContext?.chatSnackBarBackground ?? "",
      snackBarText: themeContext?.chatSnackBarText ?? "",
    })
  );

  const onTheme = (dark: boolean) => {
    themeContext?.setDarkMode(dark);
    storage.setValue("THEME", dark ? "2" : "1");

    setChatColors(
      new ChatColors({
        newMsgPlaceholderColor: themeContext?.chatNewMsgPlaceholderColor ?? "",
        newMsgSendButtonColor: themeContext?.chatNewMsgSendButtonColor ?? "",
        tipBackgroundColor: themeContext?.chatTipBackgroundColor ?? "",
        botTextColor: themeContext?.chatBotTextColor ?? "",
        tipExampleColor: themeContext?.chatTipExampleColor ?? "",
        prominentTextColor: themeContext?.chatProminentTextColor ?? "",
        prominentColor: themeContext?.chatProminentColor ?? "",
        tipColorColumn: themeContext?.chatTipColorColumn ?? "",
        highlightColor: themeContext?.chatHighlightColor ?? "",
        userBackgroundColor: themeContext?.chatUserBackgroundColor ?? "",
        userTextColor: themeContext?.chatUserTextColor ?? "",
        botBackgroundColor: themeContext?.chatBotBackgroundColor ?? "",
        tipColor: themeContext?.chatTipColor ?? "",
        snackBarBackground: themeContext?.chatSnackBarBackground ?? "",
        snackBarText: themeContext?.chatSnackBarText ?? "",
      })
    );

    //console.log(chatColors.botBackgroundColor);
  };

  const onUpdateName = async (name: string) => {
    let retries = 0;

    while (retries < 7) {
      try {
        const isUpdated = await updateIntoduced(
          true,
          props.userEmail,
          props.userID
        );
        if (isUpdated) {
          updateName(name, props.userID);
          return;
        }
        // If successful, exit the function.
      } catch (error) {
        console.error(
          "Error in onGetStarted, attempt number " + (retries + 1),
          error
        );
        retries++;
        await sleep(500); // Wait for half a second before the next retry.
      }
    }
  };

  const sleep = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const onGetStarted = async () => {};
  const onAddLists = () => {
    addOverlay(
      <AddListsDlg
        textColor={themeContext?.textColor!}
        userID={props.userID}
        isMobile={props.isMobile}
        onSave={(theme: number) => {}}
        onCancel={() => {}}
      />,
      {
        closeOnClickOutside: true,
        centerX: true,

        marginTop: 100,
      },
      true
    );
  };

  const onChatFinished = () => {
    let activeProject = dataProvider.getActiveProject();
    if (dataProvider.isTodayView()) {
      activeProject = "today";
    } else if (dataProvider.isUpcomingView()) {
      activeProject = "upcoming";
    }
    if (activeProject === "") activeProject = props.homeProjectID;
    props.onChatFinished();
    if (props.existingUser) {
      navigate("/list/" + activeProject);
    }
  };

  return (
    <div
      style={{
        backgroundColor: themeContext?.overlayBackground,
        color: themeContext?.textColor,
        justifyContent: props.isMobile ? "" : "",
      }}
      className="main_intro"
    >
      {showConfetti && (
        <Confetti width={width} height={height} recycle={false} />
      )}
      {!oldIntro && (
        <ChatPage
          isMobileDevice={props.isMobileDevice}
          userName={props.userName}
          chatColors={chatColors}
          darkMode={themeContext?.isDarkTheme!}
          onThemeChange={onTheme}
          existingUser={props.existingUser}
          homeProjectID={props.homeProjectID}
          timeZone={props.userTimeZone}
          onUpdteName={onUpdateName}
          onChatFinished={onChatFinished}
          isMobile={props.isMobile}
          userEmail={props.userEmail}
          userID={props.userID}
        />
      )}
      {oldIntro && (
        <Logo
          smallLogo={props.isMobile}
          alignCenterX={true}
          alignCenterY={false}
          color={themeContext?.textColor!}
        />
      )}
      {oldIntro && (
        <div
          style={{
            justifyContent: props.isMobile ? "" : "center",
            alignItems: props.isMobile ? "" : "center",
          }}
          className="intro"
        >
          <div
            style={{ padding: props.isMobile ? "6px" : "40px" }}
            className="content"
          >
            <div className="header">
              {props.userEmail}, It's great to meet you 😀
            </div>
            <EditBox
              type="text"
              placeholder="What's your name? "
              id="name_box"
              focus={true}
              disableBorder={true}
              EditRef={inputRef}
            />
            <div style={{ marginTop: "8px" }}></div>
            <div className="add_lists">
              <Button
                centerText={true}
                fontSize="24px"
                id="button_add_lists"
                label="👋 Add new lists!"
                textButton={true}
                onClick={onAddLists}
              />
            </div>
            <div className="get_started">
              <Button
                centerText={true}
                fontSize="24px"
                id="button_get_started"
                label="Get Started 👉🏿"
                textButton={false}
                onClick={onGetStarted}
              />
            </div>
            {loading && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default IntroDlg;
