import { db } from "../firebase";
import { writeBatch, doc, Timestamp } from "firebase/firestore";
import { verifyCalendarProjectExists } from "./Project";

export const updateProjectAndTask = async (
  order: string[],
  userID: string,
  activeProject: string,
  hasTime: boolean,
  dueDate: Timestamp | null,
  taskID: string
) => {
  const batch = writeBatch(db);

  const handleProjectUpdate = async () => {
    let projectID = activeProject;

    // Check if the active project is "today" or "upcoming"
    if (activeProject === "calendar") {
      projectID = await verifyCalendarProjectExists(activeProject, userID);
    }

    // Prepare the document reference and update data for the project
    const projectDocRef = doc(db, "users", userID, "projects", projectID);
    batch.update(projectDocRef, { order: order });
  };

  const handleTaskUpdate = () => {
    // Prepare the document reference and update data for the task
    const taskDocRef = doc(db, "users", userID, "tasks", taskID);
    batch.update(taskDocRef, {
      dueDateWithTime: hasTime,
      dueDate: dueDate,
    });
  };

  // Call update functions to add operations to the batch
  await handleProjectUpdate();
  handleTaskUpdate();

  // Commit the batch
  await batch.commit();
};
