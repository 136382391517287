import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Register from "../Pages/Register/Register";

import Loader from "../Pages/Loader/Loader";
import { useAuth } from "../Context/Auth";
import Navigation from "../Pages/Navigation/Navigation";
import { useData } from "../Context/Data";

import ReactGA from "react-ga";

const App = () => {
  const auth = useAuth();
  return (
    <Router>
      <Navigator />
      {auth.authFinished ? (
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route
            path="/admin"
            element={<Navigation isAdminMode={true} isChatMode={false} />}
          />
          <Route
            path="/chat"
            element={<Navigation isChatMode={true} isAdminMode={false} />}
          />
          <Route
            path="/list/:id/*"
            element={<Navigation isAdminMode={false} isChatMode={false} />}
          />
        </Routes>
      ) : (
        <Loader />
      )}
    </Router>
  );
};

const Navigator = () => {
  const auth = useAuth();
  const dataProvider = useData();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (dataProvider.getProjectName() !== "") {
      if (dataProvider.getTaskName() !== "") {
        document.title = `Keeplist: ${dataProvider.getTaskName()}`;
      } else {
        document.title = `Keeplist: ${dataProvider.getProjectName()}`;
      }
    } else document.title = "Keeplist | To-do List and Reminders";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider.getProjectName]);

  const onListChange = (newList: string) => {
    dataProvider.setActiveProject(newList);
  };

  function getQueryParamValue(queryString: string, paramName: string) {
    if (!queryString) return null;
    // Split the query string into individual parameters using "&" as the separator
    const queryParams = queryString.split("?")[1].split("&");

    // Iterate through the parameters to find the one with the specified name
    for (const param of queryParams) {
      const [name, value] = param.split("=");

      if (name === paramName) {
        // Return the value if the parameter name matches
        return decodeURIComponent(value);
      }
    }

    // Return null if the parameter is not found
    return null;
  }
  function getStringAfterLastDelimiter(
    inputString: string,
    delimiter: string
  ): string {
    const index = inputString.lastIndexOf(delimiter);
    if (index !== -1) {
      return inputString.substring(index + delimiter.length);
    }
    return ""; // If delimiter not found, return an empty string
  }

  function getStringBeforeDelimiter(
    inputString: string,
    delimiter: string
  ): string {
    const index = inputString.indexOf(delimiter);
    if (index !== -1) {
      return inputString.substring(0, index);
    }
    return inputString; // If delimiter not found, return the original string
  }

  useEffect(() => {
    try {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
      ReactGA.event({
        category: "User",
        action: "WEB APP - PAGE CHANGE",
      });
    } catch (error) {
      console.error("Error sending data to Google Analytics:", error);
    }
  }, [location]);

  useEffect(() => {
    if (
      location.pathname.includes("/admin") ||
      location.pathname.includes("/chat")
    )
      return;
    //  console.log(location.pathname);
    if (auth.authFinished) {
      let listID = location.pathname.replace("/list/", "");
      let bCancelAgenda: boolean =
        getQueryParamValue(location.search, "cancelagenda") === "yes";
      listID = getStringBeforeDelimiter(listID, "/");
      const taskID = getStringAfterLastDelimiter(location.pathname, "/task/");
      if (bCancelAgenda) dataProvider.setCancelDailyAgenda(true);
      if (auth.authGetUID() !== "") {
        if (
          location.search === "" &&
          (!location.pathname.includes("/list") ||
            location.pathname === "/list" ||
            location.pathname === "/list/")
        ) {
          navigate("/list/home");
        } else if (location.search.includes("?list")) {
          let list = getQueryParamValue(location.search, "list");
          let task = getQueryParamValue(location.search, "task");

          if (task === null || task === "") navigate("/list/" + list);
          else navigate("/list/" + list + "/task/" + task);
        } else {
          if (dataProvider.getActiveProject() === "" && listID === "")
            navigate("/list/home");
          if (listID !== dataProvider.getActiveProject()) {
            if (listID !== "home" || dataProvider.getActiveProject() === "")
              onListChange(listID);
          }

          if (location.pathname.includes("/task")) {
            if (taskID !== "" && taskID !== dataProvider.getActiveTask())
              dataProvider.setActiveTask(taskID);
          } else {
            dataProvider.setActiveTask("");
          }
        } // Change the URL to "/list/home" only if it's not currently set to a list
      } else {
        if (!location.pathname.includes("/register")) {
          if (listID === "" || !dataProvider.isNewSession())
            navigate(
              bCancelAgenda ? "/register?cancelagenda=yes" : "/register"
            );
          else if (listID !== "") {
            if (taskID === "") navigate("/register?list=" + listID);
            else navigate("/register?list=" + listID + "&task=" + taskID);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, auth.authFinished, auth.authGetUID, navigate, location.pathname]);

  return null; // Render nothing, this component is just for side effects
};

export default App;
