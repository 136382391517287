import { MessageData } from "../Data/Message";
import { ReminderChat, ReminderType } from "../Data/ReminderChat";
import { parse, isValid } from "date-fns";

import {
  ChatSuggestion,
  customDate,
  customTime,
  dailyReminder,
  dailyReminderNoWeekends,
  dailyReminderYesWeekends,
  friday,
  lastDay,
  monday,
  monthlyReminder,
  nineInMorning,
  oneTimeReminder,
  preferNotToSayName,
  reminderExample,
  saturday,
  setupReminders,
  sevenInEvening,
  skipReminder,
  skipToApp,
  sunday,
  thursday,
  today,
  tomorrow,
  tuesday,
  wednesday,
  weeklyReminder,
  yearlyReminder,
} from "../Data/Suggestion";
import { createReminder, MonthIndexToName, Reminder } from "../Data/Reminder";
import { Timestamp } from "firebase/firestore";
import { createTask, Task } from "../Data/Task";

type TimeOfDay = {
  hour: number;
  minute: number;
};

enum BotState {
  initial,
  userIntroduced,
  userWantsReminders,
  addReminder,
  addReminderAskWhen,
  greetExistingUser,
  addReminderAskWhenTime,
  addReminderAskOnWeekends,
  addReminderAskWeekDay,
  addReminderAskMonthDay,
  finishedAddingReminder,
  reminderSkipped,
  invalidTime,
  invalidDate,
  invalidDateInput,
  invalidMonthDayInput,
  invalidTimeInput,
  finished,
}

export class ChatBot {
  private state: BotState;
  private onUpdateName: (name: string) => void; // Store the callback function
  private onChatFinished: () => void;

  private _preferNoName: boolean = false;

  private _isExistinguser: boolean = false;

  private _timeZone: string = "";

  private _userID: string = "";

  private _homeProjectID: string = "";

  private _exmpleText: string = "";
  private _reminderString: string = "";

  private _existingUserName: string = "";
  private _existingUserEmail: string = "";

  private _customeTimeSelection: boolean = false;
  private _customDateSelection: boolean = false;

  private _reminder: ReminderChat = new ReminderChat({});

  // Constructor now accepts a callback function, but it won't trigger it automatically
  constructor(
    onUpdateName: (name: string) => void,
    onChatFinished: () => void,
    timeZone: string,
    userID: string,
    homeProjectID: string,
    isExistingUser: boolean,
    existingUserName: string,
    existingUserEmail: string
  ) {
    this.state = isExistingUser ? BotState.greetExistingUser : BotState.initial; // Set the initial state
    this.onUpdateName = onUpdateName; // Store the callback function for later use
    this.onChatFinished = onChatFinished;
    this._timeZone = timeZone;
    this._userID = userID;
    this._existingUserName = existingUserName;
    this._existingUserEmail = existingUserEmail;
    this._homeProjectID = homeProjectID;
    this._isExistinguser = isExistingUser;
  }

  public reset(): void {
    this.state = BotState.initial;
    this._exmpleText = "";
    this._preferNoName = false;
    this._reminder.reset();
  }

  public setTimeSelection(customTime: boolean): void {
    this._customeTimeSelection = customTime;
  }

  public setDateSelection(customDate: boolean): void {
    this._customDateSelection = customDate;
  }

  public createBotMessage(text: string, highlight: boolean): MessageData {
    return {
      text: text,
      isSentByUser: false,
      highlight: highlight,
      suggestionID: 0,
      created: new Date(),
      exampleText: "", // Include if relevant
    };
  }

  public getReminderString() {
    const hours = this._reminder.reminderDate
      .getHours()
      .toString()
      .padStart(2, "0"); // Get hours and pad with leading zero if necessary
    const minutes = this._reminder.reminderDate
      .getMinutes()
      .toString()
      .padStart(2, "0"); // Get minutes and pad with leading zero if necessary
    const timeString = `${hours}:${minutes}`;

    let reminderString = "";
    if (this._reminder.type === ReminderType.oneTime) {
      reminderString = `One-Time Reminder: ${
        this._reminder.reminderName
      } on ${this._reminder.reminderDate.toDateString()} at ${this._reminder.reminderDate.toLocaleTimeString(
        [],
        { hour: "2-digit", minute: "2-digit" }
      )}`;
    } else if (this._reminder.type === ReminderType.daily) {
      reminderString = `Daily Reminder: ${
        this._reminder.reminderName
      } at ${this._reminder.reminderDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })}`;
    } else if (this._reminder.type === ReminderType.weekly) {
      reminderString = `Weekly Reminder: ${
        this._reminder.reminderName
      } every ${this.getWeekDayName()} on ${this._reminder.reminderDate.toLocaleTimeString(
        [],
        {
          hour: "2-digit",
          minute: "2-digit",
        }
      )}`;
    } else if (this._reminder.type === ReminderType.monthly) {
      reminderString = `Monthly Reminder: ${
        this._reminder.reminderName
      } every ${this._reminder.getMonthDay()} on ${this._reminder.reminderDate.toLocaleTimeString(
        [],
        {
          hour: "2-digit",
          minute: "2-digit",
        }
      )}`;
    } else if (this._reminder.type === ReminderType.yearly) {
      reminderString = `Yearly Reminder: ${
        this._reminder.reminderName
      } every  ${MonthIndexToName(
        this._reminder.reminderDate.getMonth()
      )} ${this._reminder.reminderDate.getDate()} at  ${timeString}`;
    }

    return reminderString;
  }

  private getWeekDayName(): string {
    if (this._reminder.weeklyDay === 1) return "Sunday";
    else if (this._reminder.weeklyDay === 2) return "Monday";
    else if (this._reminder.weeklyDay === 3) return "Tuesday";
    else if (this._reminder.weeklyDay === 4) return "Wednesday";
    else if (this._reminder.weeklyDay === 5) return "Thursday";
    else if (this._reminder.weeklyDay === 6) return "Friday";
    else if (this._reminder.weeklyDay === 7) return "Saturday";

    return "";
  }

  private parseTime(timeStr: string): TimeOfDay | null {
    try {
      // Try parsing the time with the format HH:mm (e.g., 21:30)
      if (timeStr.includes(":")) {
        const [hour, minute] = timeStr.split(":").map(Number);

        // Add validation to ensure hour and minute are in valid ranges
        if (hour >= 0 && hour < 24 && minute >= 0 && minute < 60) {
          return { hour, minute };
        }
      } else {
        // If no ":" is present, try parsing the time with the format HHmm (e.g., 2130)
        if (timeStr.length === 4) {
          const hour = parseInt(timeStr.substring(0, 2), 10);
          const minute = parseInt(timeStr.substring(2, 4), 10);

          // Add validation to ensure hour and minute are in valid ranges
          if (hour >= 0 && hour < 24 && minute >= 0 && minute < 60) {
            return { hour, minute };
          }
        }
      }
    } catch (e) {
      // If parsing fails, return null
      return null;
    }

    // Return null if input format is not valid
    return null;
  }

  private handleMonthDay(text: string): void {
    try {
      const day = parseInt(text, 10);
      if (day >= 1 && day <= 31) {
        this._reminder.monthlyDay = day;
        this.state = BotState.addReminderAskWhenTime;
      } else {
        this.state = BotState.invalidMonthDayInput;
      }
    } catch (e) {
      this.state = BotState.invalidMonthDayInput;
    }
  }

  private textToDateTime(
    text: string,
    reminderType: ReminderType
  ): Date | null {
    const cleanedText = text.replace(/\s+/g, " ").trim();

    // Define the possible date formats based on the reminderType
    const formats =
      reminderType === ReminderType.oneTime
        ? [
            "MMMM d, yyyy", // Full month, day, and year
            "MMM d, yyyy", // Abbreviated month, day, and year
            "MMMM d", // Full month and day without year
            "MMM d", // Abbreviated month and day without year
            "MMM d yyyy", // Abbreviated month, day, and year
          ]
        : [
            "MMMM d", // Full month and day without year
            "MMM d", // Abbreviated month and day without year
          ];

    // Try parsing the date with each format
    for (const format of formats) {
      const parsedDate = parse(cleanedText, format, new Date());
      if (isValid(parsedDate)) {
        // If the date is valid, return it
        return parsedDate;
      }
    }

    // If none of the formats matched, return null
    return null;
  }

  public getPlaceholderText(): string {
    if (this.state === BotState.initial) return "Type your name here";
    else if (this.state === BotState.addReminder)
      return "What can I help you remember?";
    else if (this.state === BotState.addReminderAskWhen) {
      if (this._reminder.type === ReminderType.oneTime)
        return "At what date should I remind you?  (Example: Jan 25, Feb 13 2026)";
      else if (this._reminder.type === ReminderType.yearly)
        return "What date each year? (Example: Jan 25)";
    } else if (this.state === BotState.addReminderAskWhenTime)
      return "At what time?  (Example: 13:30)";
    return "Type your message here";
  }

  public reminderAdded() {
    return this.state === BotState.finishedAddingReminder;
  }

  public isOpenTextMessage(): boolean {
    if (this._customDateSelection || this._customeTimeSelection) return false;

    return (
      this.state === BotState.initial ||
      this.state === BotState.invalidDate ||
      this.state === BotState.invalidDateInput ||
      this.state === BotState.addReminder ||
      this.state === BotState.addReminderAskWhen ||
      this.state === BotState.addReminderAskWhenTime ||
      this.state === BotState.invalidTime ||
      this.state === BotState.invalidTimeInput ||
      this.state === BotState.addReminderAskMonthDay ||
      this.state === BotState.invalidMonthDayInput
    );
  }

  public areSuggestionsInColumn(): boolean {
    return (
      this.state === BotState.userWantsReminders ||
      this.state === BotState.reminderSkipped ||
      this.state === BotState.finishedAddingReminder ||
      (this.state === BotState.initial && this._isExistinguser) ||
      this.state === BotState.greetExistingUser
    );
  }

  private getRandomOneTimeReminderExample(): string {
    // A list of predefined reminder examples with emojis
    const examples: string[] = [
      "🛒 Buy milk from the store",
      "📞 Call the doctor for an appointment",
      "📄 Submit the assignment",
      "👔 Pick up dry cleaning",
      "✉️ Send an email to the manager",
      "🚶‍♂️ Go for a walk",
      "💻 Attend the online webinar",
      "🐱 Feed the cat",
      "🍳 Prepare dinner for tonight",
      "🌱 Water the plants",
    ];

    // Generate a random index from the list
    const randomIndex = Math.floor(Math.random() * examples.length);

    // Get the random example
    const example: string = examples[randomIndex];
    // Format the output with "One-Time" and "Example:"
    return `Example: ${example}`;
  }

  private getRandomDailyReminderExample(): string {
    // A list of predefined reminder examples with emojis
    const examples: string[] = [
      "📖 Read 10 pages of a book",
      "🏋️ Exercise for 30 minutes",
      "☀️ Meditate for 5 minutes",
      "🐶 Walk the dog",
      "🧹 Clean the living room",
      "📅 Check your daily to-do list",
      "💧 Drink 8 glasses of water",
      "🛏️ Take a 10-minute power nap",
      "🍎 Eat a healthy snack",
      "🚶‍♂️ Take a 15-minute walk outside",
      "🧑‍💻 Work on your personal project for 1 hour",
      "📲 Reply to messages",
      "💡 Organize your workspace",
      "🎨 Spend 30 minutes on a creative hobby",
      "🍽️ Cook a nutritious meal",
      "📚 Learn a new skill for 30 minutes",
      "🌱 Water your plants",
      "🧘‍♀️ Stretch for 10 minutes",
      "🗑️ Take out the trash",
      "👕 Do a load of laundry",
      "💼 Review your work tasks for the day",
      "🎧 Listen to a motivational podcast",
      "🎮 Play a game for 30 minutes",
      "📝 Write down 3 things you're grateful for",
      "🌙 Unwind with a book or podcast before bed",
    ];

    // Generate a random index from the list
    const randomIndex: number = Math.floor(Math.random() * examples.length);
    const example: string = examples[randomIndex];

    // Format the output with "Example:"
    return `Example: ${example}`;
  }

  private getRandomMonthlyReminderExample(): string {
    // A list of predefined reminder examples with emojis
    const examples: string[] = [
      "💳 Pay your credit card bill",
      "📦 Order household supplies",
      "🧹 Clean the refrigerator",
      "📈 Review your budget",
      "🚗 Get your car serviced",
      "📅 Plan your monthly schedule",
      "📝 Write down your monthly goals",
      "📤 Backup your important files",
      "🏦 Deposit money into savings",
      "📊 Analyze your monthly achievements",
    ];

    // Generate a random index from the list
    const randomIndex: number = Math.floor(Math.random() * examples.length);
    const example: string = examples[randomIndex];

    // Format the output with "Example:"
    return `Example: ${example}`;
  }

  private getRandomYearlyReminderExample(): string {
    // A list of predefined reminder examples with emojis
    const examples: string[] = [
      "🎂 Celebrate your birthday",
      "📅 Plan your vacation",
      "💉 Schedule your annual health checkup",
      "📄 Review your insurance policies",
      "📝 Set your New Year's resolutions",
      "🧹 Spring clean your house",
      "🏦 Review your investments",
      "📚 Read a new book series",
      "🌟 Reflect on your achievements",
      "🎄 Plan for holiday gifts",
    ];

    // Generate a random index from the list
    const randomIndex: number = Math.floor(Math.random() * examples.length);
    const example: string = examples[randomIndex];

    // Format the output with "Example:"
    return `Example: ${example}`;
  }

  private getRandomWeeklyReminderExample(): string {
    // A list of predefined reminder examples with emojis
    const examples: string[] = [
      "🛒 Buy groceries for the week",
      "🧹 Deep clean the house",
      "📅 Plan the week ahead",
      "💌 Write a letter to a friend",
      "🏋️ Attend a yoga class",
      "📞 Call your parents",
      "🧺 Do the laundry",
      "🌳 Spend time outdoors",
      "📊 Review your weekly goals",
      "🧑‍🍳 Try a new recipe",
    ];

    // Generate a random index from the list
    const randomIndex: number = Math.floor(Math.random() * examples.length);
    const example: string = examples[randomIndex];

    // Format the output with "Example:"
    return `Example: ${example}`;
  }

  public getChatTips(): ChatSuggestion[] {
    // Example chat tips
    const chatTips: ChatSuggestion[] = [];

    if (this.state === BotState.initial) {
      chatTips.push({
        text: "Prefer not to say 😳",
        suggestionID: preferNotToSayName,
      });
    } else if (this.state === BotState.userIntroduced) {
      chatTips.push({
        text: "😀 No, Just take me to the app",
        suggestionID: skipToApp,
      });

      chatTips.push({
        text: "👍 Yes, Let's add reminders!",
        suggestionID: setupReminders,
        prominent: true,
      });
    } else if (
      this.state === BotState.userWantsReminders ||
      this.state === BotState.reminderSkipped ||
      this.state === BotState.finishedAddingReminder ||
      this.state === BotState.greetExistingUser
    ) {
      chatTips.push({
        text: "One-time reminder",
        exampleText: this.getRandomOneTimeReminderExample(),
        suggestionID: oneTimeReminder,
      });

      chatTips.push({
        text: "Daily Reminder",
        exampleText: this.getRandomDailyReminderExample(),
        suggestionID: dailyReminder,
      });
      chatTips.push({
        text: "Weekly reminder",
        suggestionID: weeklyReminder,
        exampleText: this.getRandomWeeklyReminderExample(),
      });

      chatTips.push({
        text: "Monthly reminder",
        suggestionID: monthlyReminder,
        exampleText: this.getRandomMonthlyReminderExample(),
      });

      chatTips.push({
        text: "Yearly reminder",
        suggestionID: yearlyReminder,
        exampleText: this.getRandomYearlyReminderExample(),
      });
      chatTips.push({
        text: "Back To App 👉",
        suggestionID: skipToApp,
      });
    } else if (this.state === BotState.addReminder) {
      chatTips.push({
        text: "CANCEL",
        suggestionID: skipReminder,
      });
      chatTips.push({
        text: this._exmpleText,
        suggestionID: reminderExample,
      });
    } else if (
      this.state === BotState.addReminderAskWhen ||
      this.state === BotState.invalidDateInput ||
      this.state === BotState.invalidDate
    ) {
      chatTips.push({
        text: "CANCEL",
        suggestionID: skipReminder,
      });

      if (this._reminder.type === ReminderType.oneTime) {
        chatTips.push({
          text: "Today",
          suggestionID: today,
        });
        chatTips.push({
          text: "Tomorrow",
          suggestionID: tomorrow,
        });
      }
      if (!this._customDateSelection) {
        chatTips.push({
          text: "📅 Pick a date",
          suggestionID: customDate,
        });
      }
    } else if (
      this.state === BotState.addReminderAskWhenTime ||
      this.state === BotState.invalidTime ||
      this.state === BotState.invalidTimeInput
    ) {
      chatTips.push({
        text: "CANCEL",
        suggestionID: skipReminder,
      });

      chatTips.push({
        text: "09:00",
        suggestionID: nineInMorning,
      });
      chatTips.push({
        text: "19:00",
        suggestionID: sevenInEvening,
      });
      if (!this._customeTimeSelection) {
        chatTips.push({
          text: "🕒 Pick a time",
          suggestionID: customTime,
        });
      }
    } else if (this.state === BotState.addReminderAskOnWeekends) {
      chatTips.push({
        text: "CANCEL",
        suggestionID: skipReminder,
      });

      chatTips.push({
        text: "Yes 👍",
        suggestionID: dailyReminderYesWeekends,
      });
      chatTips.push({
        text: "No 👎",
        suggestionID: dailyReminderNoWeekends,
      });
    } else if (this.state === BotState.addReminderAskWeekDay) {
      chatTips.push({
        text: "CANCEL",
        suggestionID: skipReminder,
      });

      chatTips.push({
        text: "MONDAY",
        suggestionID: monday,
      });
      chatTips.push({
        text: "TUESDAY",
        suggestionID: tuesday,
      });
      chatTips.push({
        text: "WEDNESDAY",
        suggestionID: wednesday,
      });
      chatTips.push({
        text: "THURSDAY",
        suggestionID: thursday,
      });
      chatTips.push({
        text: "FRIDAY",
        suggestionID: friday,
      });
      chatTips.push({
        text: "SATURDAY",
        suggestionID: saturday,
      });
      chatTips.push({
        text: "SUNDAY",
        suggestionID: sunday,
      });
    } else if (
      this.state === BotState.addReminderAskMonthDay ||
      this.state === BotState.invalidMonthDayInput
    ) {
      chatTips.push({
        text: "CANCEL",
        suggestionID: skipReminder,
      });

      chatTips.push({
        text: "Last Day",
        suggestionID: lastDay,
      });
    }

    return chatTips;
  }

  private async onTimeSelection(): Promise<void> {
    if (
      this._reminder.type === ReminderType.oneTime &&
      this.isDateInThePast(this._reminder.reminderDate, true)
    ) {
      this.state = BotState.invalidTime;
    } else if (
      this._reminder.type === ReminderType.oneTime ||
      this._reminder.type === ReminderType.weekly ||
      this._reminder.type === ReminderType.monthly ||
      this._reminder.type === ReminderType.yearly
    ) {
      this.addReminder();
      this.state = BotState.finishedAddingReminder;
    } else if (this._reminder.type === ReminderType.daily) {
      this.state = BotState.addReminderAskOnWeekends;
    }
  }

  private onAddDailyReminder(includeWeekends: boolean) {
    this._reminder.dailyReminderOnWeekends = includeWeekends;
    this.addReminder();
    this.state = BotState.finishedAddingReminder;
  }

  private updateBotState(userMessage: MessageData): void {
    if (userMessage.text === "") return;

    if (userMessage.DateTime) {
      //  this._reminder.reminderDate = userMessage.DateTime;
      if (this.state === BotState.addReminderAskWhen) {
        this._reminder.reminderDate = userMessage.DateTime;
        this.state = BotState.addReminderAskWhenTime;
      } else if (this.state === BotState.addReminderAskWhenTime) {
        this._reminder.reminderDate.setHours(userMessage.DateTime.getHours());
        this._reminder.reminderDate.setMinutes(
          userMessage.DateTime.getMinutes()
        );
        this._reminder.reminderDate.setSeconds(0);

        this.onTimeSelection();
      }

      return;
    }

    if (userMessage.suggestionID === skipToApp) {
      this.onChatFinished();
    } else if (userMessage.suggestionID === setupReminders) {
      this.state = BotState.userWantsReminders;
    } else if (userMessage.suggestionID === skipReminder) {
      this.state = BotState.reminderSkipped;
    } else if (userMessage.suggestionID === oneTimeReminder) {
      this._reminder.type = ReminderType.oneTime;
      this.state = BotState.addReminder;
    } else if (userMessage.suggestionID === dailyReminder) {
      this._reminder.type = ReminderType.daily;
      this.state = BotState.addReminder;
    } else if (userMessage.suggestionID === weeklyReminder) {
      this._reminder.type = ReminderType.weekly;
      this.state = BotState.addReminder;
    } else if (userMessage.suggestionID === monthlyReminder) {
      this._reminder.type = ReminderType.monthly;
      this.state = BotState.addReminder;
    } else if (userMessage.suggestionID === yearlyReminder) {
      this._reminder.type = ReminderType.yearly;
      this.state = BotState.addReminder;
    } else if (userMessage.suggestionID === reminderExample) {
      this._reminder.reminderName = userMessage.text;
      this.onReminderText();
    } else if (userMessage.suggestionID === today) {
      this._reminder.reminderDate = new Date();
      this.state = BotState.addReminderAskWhenTime;
    } else if (userMessage.suggestionID === tomorrow) {
      this._reminder.reminderDate = new Date();
      this._reminder.reminderDate.setDate(
        this._reminder.reminderDate.getDate() + 1
      );

      this.state = BotState.addReminderAskWhenTime;
    } else if (userMessage.suggestionID === monday) {
      this._reminder.weeklyDay = 2;
      this.state = BotState.addReminderAskWhenTime;
    } else if (userMessage.suggestionID === tuesday) {
      this._reminder.weeklyDay = 3;
      this.state = BotState.addReminderAskWhenTime;
    } else if (userMessage.suggestionID === wednesday) {
      this._reminder.weeklyDay = 4;
      this.state = BotState.addReminderAskWhenTime;
    } else if (userMessage.suggestionID === thursday) {
      this._reminder.weeklyDay = 5;
      this.state = BotState.addReminderAskWhenTime;
    } else if (userMessage.suggestionID === friday) {
      this._reminder.weeklyDay = 6;
      this.state = BotState.addReminderAskWhenTime;
    } else if (userMessage.suggestionID === saturday) {
      this._reminder.weeklyDay = 7;
      this.state = BotState.addReminderAskWhenTime;
    } else if (userMessage.suggestionID === sunday) {
      this._reminder.weeklyDay = 1;
      this.state = BotState.addReminderAskWhenTime;
    } else if (userMessage.suggestionID === nineInMorning) {
      this._reminder.reminderDate.setHours(9);
      this._reminder.reminderDate.setMinutes(0);
      this._reminder.reminderDate.setSeconds(0);
      this.onTimeSelection();
    } else if (userMessage.suggestionID === sevenInEvening) {
      this._reminder.reminderDate.setHours(19);
      this._reminder.reminderDate.setMinutes(0);
      this._reminder.reminderDate.setSeconds(0);
      this.onTimeSelection();
    } else if (
      userMessage.suggestionID === dailyReminderYesWeekends ||
      userMessage.suggestionID === dailyReminderNoWeekends
    ) {
      this.onAddDailyReminder(
        userMessage.suggestionID === dailyReminderNoWeekends
      );
    } else if (userMessage.suggestionID === lastDay) {
      this._reminder.lastMonthDay = true;

      this.state = BotState.addReminderAskWhenTime;
    } else if (this.state === BotState.initial) {
      this._preferNoName = userMessage.suggestionID === preferNotToSayName;

      let personName =
        userMessage.suggestionID === preferNotToSayName ? "" : userMessage.text;
      this.onUpdateName(personName);

      this.state = BotState.userIntroduced;
    } else if (this.state === BotState.addReminder) {
      this._reminder.reminderName = userMessage.text;
      this.onReminderText();
    } else if (
      this.state === BotState.addReminderAskMonthDay ||
      this.state === BotState.invalidMonthDayInput
    ) {
      this.handleMonthDay(userMessage.text);
    } else if (this.isDateSelection()) {
      if (!this.isTextDateFormat(userMessage.text)) {
        this.state = BotState.invalidDateInput;
      } else {
        let customDate = this.textToDateTime(
          userMessage.text,
          this._reminder.type
        )!;

        if (this.isDateInThePast(customDate, false))
          this.state = BotState.invalidDate;
        else {
          this._reminder.reminderDate = customDate;
          this.state = BotState.addReminderAskWhenTime;
        }
      }
    } else if (this.isTimeSelection()) {
      if (this.parseTime(userMessage.text) === null) {
        this.state = BotState.invalidTimeInput;
      } else {
        let customTime: TimeOfDay | null = this.parseTime(userMessage.text);
        let selectedDate = this._reminder.reminderDate;
        selectedDate.setHours(customTime!.hour);
        selectedDate.setMinutes(customTime!.minute);
        selectedDate.setSeconds(0);

        this._reminder.reminderDate = selectedDate;

        this.onTimeSelection();
      }
    }
  }

  private onReminderText() {
    if (this._reminder.type === ReminderType.oneTime) {
      this.state = BotState.addReminderAskWhen;
    } else if (this._reminder.type === ReminderType.daily) {
      this.state = BotState.addReminderAskWhenTime;
    } else if (this._reminder.type === ReminderType.weekly) {
      this.state = BotState.addReminderAskWeekDay;
    } else if (this._reminder.type === ReminderType.monthly) {
      this.state = BotState.addReminderAskMonthDay;
    } else if (this._reminder.type === ReminderType.yearly) {
      this.state = BotState.addReminderAskWhen;
    }
  }

  private async addReminder() {
    const currentDate = new Date(); // Get the current date

    const ts = Timestamp.fromDate(currentDate); // Convert the Date object to a Timestamp

    const t: Task = {
      created: ts,
      updated: ts,
      description: "",
      dueDate: null,
      dueDateWithTime: false,
      systemCreated: false,
      priority: 3,
      project: this._homeProjectID,
      desktopNotifications: true,
      emailNotifications: true,
      pushNotifications: true,
      deleted: false,
      name: this._reminder.reminderName,
    };
    let TaskID: string = await createTask(t, this._userID);

    const newReminder: Reminder = {
      time: Timestamp.fromDate(this._reminder.reminderDate),
      recurring: this._reminder.type !== ReminderType.oneTime,
      weekly: this._reminder.type === ReminderType.weekly,
      monthly: this._reminder.type === ReminderType.monthly,
      yearly: this._reminder.type === ReminderType.yearly,
      excludeWeekends: this._reminder.dailyReminderOnWeekends,
      cronJobID: "",
      daily: this._reminder.type === ReminderType.daily,
      done: false,
      dayOfMonth: this._reminder.monthlyDay,
      dayOfWeek: this._reminder.weeklyDay,
      recurringMonthLastDay: this._reminder.lastMonthDay,
      created: Timestamp.now(),
      taskID: TaskID,
      deviceToken: "",
      reminderID: this.generateReminderID(),
      deleted: false,
      utcOffset: -new Date().getTimezoneOffset(),
      timeZoneName: this._timeZone,
    };

    createReminder(newReminder, this._userID);

    this._reminder.reset();
  }

  private generateReminderID = (): number => {
    return Math.floor(Math.random() * (Math.pow(2, 31) - 1));
  };

  private isTimeSelection(): boolean {
    return (
      this.state === BotState.addReminderAskWhenTime ||
      this.state === BotState.invalidTime ||
      this.state === BotState.invalidTimeInput
    );
  }

  private isDateSelection(): boolean {
    return (
      this.state === BotState.addReminderAskWhen ||
      this.state === BotState.invalidDateInput ||
      this.state === BotState.invalidDate
    );
  }

  private isDateInThePast(date: Date, checkTime: boolean): boolean {
    if (this._reminder.type === ReminderType.yearly) return false;

    const currentDate = new Date();

    // Set both currentDate and date to midnight to avoid time comparison
    if (!checkTime) {
      currentDate.setHours(0, 0, 0, 0);
      date.setHours(0, 0, 0, 0);
    }

    return date < currentDate;
  }

  private isTextDateFormat(dateString: string): boolean {
    return this.textToDateTime(dateString, this._reminder.type) !== null;
  }

  private parseFirstName(fullName: string): string {
    return fullName.split(" ")[0];
  }

  private existingUserGreeting(): string {
    // Greet good morning, good afternoon, good evening, good night, according to hours

    let firstName = "";

    if (this._existingUserName === "") {
      firstName = this._existingUserEmail;
    } else {
      firstName = this.parseFirstName(this._existingUserName);
    }

    const hour = new Date().getHours();
    if (hour < 12) {
      return `🌞 Good Morning, ${firstName}!`;
    } else if (hour < 17) {
      return `🌤️ Good Afternoon, ${firstName}!`;
    } else if (hour < 21) {
      return `🌆 Good Evening, ${firstName}!`;
    }
    return `🌙 Good Night, ${firstName}!`;
  }

  // Method to process user messages and return bot responses
  public processUserMessage(userMessage: MessageData): MessageData[] {
    this.updateBotState(userMessage);
    // Example response creation
    const botResponses: MessageData[] = [];

    if (this.state === BotState.initial && this._isExistinguser) {
      this.state = BotState.greetExistingUser;
    }

    if (userMessage.exampleText !== undefined) {
      this._exmpleText = userMessage.exampleText.replace(/^Example:\s*/, "");
    }

    if (this.state === BotState.initial) {
      botResponses.push(
        this.createBotMessage("Welcome to Keeplist 🚀 What's your name?", true)
      );
    } else if (this.state === BotState.greetExistingUser) {
      botResponses.push(
        this.createBotMessage(this.existingUserGreeting(), true)
      );
      botResponses.push(
        this.createBotMessage("What kind of reminder do you want to add?", true)
      );
    } else if (this.state === BotState.userIntroduced) {
      if (this._preferNoName) {
        botResponses.push(this.createBotMessage("Nice to meet you!", true));
      } else {
        botResponses.push(
          this.createBotMessage(
            `Nice to meet you, ${this.parseFirstName(userMessage.text)}!`,
            true
          )
        );
      }

      botResponses.push(
        this.createBotMessage(
          `Keeplist is great for setting reminders ⏰ Would you like to try it out?`,
          true
        )
      );
      //  this.state = BotState.userWantsReminders;
    } else if (this.state === BotState.userWantsReminders) {
      botResponses.push(
        this.createBotMessage(
          `Great! What kind of reminder do you want to add?`,
          true
        )
      );
    } else if (this.state === BotState.reminderSkipped) {
      botResponses.push(
        this.createBotMessage("Alright! Any more reminders?", true)
      );
    } else if (this.state === BotState.addReminderAskWhen) {
      if (this._reminder.type === ReminderType.oneTime) {
        botResponses.push(
          this.createBotMessage(
            "At what date should I remind you?  (Example: Jan 25, Feb 13 2026)",
            true
          )
        );
      } else if (this._reminder.type === ReminderType.yearly) {
        botResponses.push(
          this.createBotMessage("What date each year? (Example: Jan 25)", true)
        );
      }
    } else if (this.state === BotState.addReminderAskWhenTime) {
      botResponses.push(
        this.createBotMessage("Great! At what time? (Example: 13:30)", true)
      );
    } else if (this.state === BotState.addReminder) {
      botResponses.push(
        this.createBotMessage("What can I help you remember?", true)
      );
    } else if (this.state === BotState.invalidDateInput) {
      this._customDateSelection = false;
      botResponses.push(
        this.createBotMessage(
          "Please type a valid date 😀 (Example: Jan 25, Feb 13 2026",
          true
        )
      );
    } else if (this.state === BotState.invalidDate) {
      this._customDateSelection = false;
      botResponses.push(
        this.createBotMessage(
          "You selected a past date 😅 Please select a future date!",
          true
        )
      );
    } else if (this.state === BotState.finishedAddingReminder) {
      this._reminderString = this.getReminderString();
      botResponses.push(
        this.createBotMessage("Reminder Added  🎉" + this._reminderString, true)
      );
      botResponses.push(this.createBotMessage("Any more reminders?", true));
    } else if (this.state === BotState.invalidTime) {
      this._customeTimeSelection = false;
      botResponses.push(
        this.createBotMessage(
          "You selected a past time 😅 Please select a future time!",
          true
        )
      );
    } else if (this.state === BotState.invalidTimeInput) {
      this._customeTimeSelection = false;
      botResponses.push(
        this.createBotMessage(
          "Please type a valid time 😀 (Example: 13:30)",
          true
        )
      );
    } else if (this.state === BotState.addReminderAskOnWeekends) {
      this._customeTimeSelection = false;
      botResponses.push(
        this.createBotMessage(
          "Do you need the daily reminder also on weekends?",
          true
        )
      );
    } else if (this.state === BotState.addReminderAskWeekDay) {
      botResponses.push(
        this.createBotMessage(
          "Which day of the week should I remind you?",
          true
        )
      );
    } else if (this.state === BotState.addReminderAskMonthDay) {
      botResponses.push(
        this.createBotMessage(
          "Which day of the monday should I remind you? Please enter a number between 1 and 31.",
          true
        )
      );
    } else if (this.state === BotState.invalidMonthDayInput) {
      botResponses.push(
        this.createBotMessage(
          "Please enter a valid day of the month between 1 and 31.",
          true
        )
      );
    }

    return botResponses;
  }
}
