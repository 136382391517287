import "./Style.css";

import { useData } from "../../../Context/Data";

import Button from "../../Atoms/Button/Button";
import Icon from "../../Atoms/Icon/Icon";
import Tooltip from "@mui/material/Tooltip";

type UserDetailsProps = {
  onUserClick: () => void;
  textColor?: string;
  fontSize?: string;
  pictureSize?: string;
  width?: string;
  picture?: string | null;
  padding?: string | null;
};

const UserDetails = (props: UserDetailsProps) => {
  const getUserDetails = (): string => {
    if (dataProvider.getUser() === undefined) return "";
    if (dataProvider.getUser()?.name !== "")
      return dataProvider.getUser()?.name!;
    return dataProvider.getUser()?.email!;
  };

  const dataProvider = useData();

  return (
    <Tooltip enterDelay={1000} arrow title="Open user menu">
      <div style={{ padding: props.padding || "" }} className="user_details">
        {props.picture && (
          <img
            alt="user"
            onClick={props.onUserClick}
            style={{
              borderRadius: "50%",
              width: props.pictureSize || "50px",
              height: props.pictureSize || "50px",
            }}
            src={props.picture}
          ></img>
        )}
        <Button
          textButtonColor={props.textColor ? props.textColor : ""}
          id="btn_user"
          fontSize={props.fontSize}
          label={getUserDetails()}
          textButton={true}
          width={props.width}
          onClick={props.onUserClick}
          iconComponent={
            !props.picture ? <Icon fontSize={28} userIcon={true} /> : null
          }
        ></Button>
      </div>
    </Tooltip>
  );
};

export default UserDetails;
