import React, { useEffect, useState } from "react";

import "./Style.css"; // Make sure this imports the CSS file where you defined your animation

type StatsCountTableProps = {
  data: { [name: string]: number };
  title: string;
};

const StatsCountTable: React.FC<StatsCountTableProps> = ({ data, title }) => {
  const [displayCount, setDisplayCount] = useState(0);

  // Sort countries by count in descending order
  const sortedCountries = Object.entries(data).sort((a, b) => b[1] - a[1]);

  useEffect(() => {
    if (displayCount < sortedCountries.length) {
      const timer = setTimeout(() => {
        setDisplayCount(displayCount + 1);
      }, 50); // Controls the speed of the row reveal

      return () => clearTimeout(timer);
    }
  }, [displayCount, sortedCountries.length]);

  return (
    <div
      style={{
        fontSize: "16px",
        marginTop: "20px",
        maxWidth: "250px",
        padding: "6px",
        minHeight: "230px",
        maxHeight: "230px",
        overflowY: "auto",
        borderRadius: "8px",
        border: "1px solid lightgray",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ marginBottom: "8px", color: "blue" }}>{title}</div>
      <div style={{ color: "black" }}>
        {sortedCountries.slice(0, displayCount).map(([country, count]) => (
          <div
            key={country}
            className="rowAnimation"
            style={{ marginBottom: "2px" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ marginRight: "10px" }}>
                <strong>{country}</strong>
              </div>
              <div style={{ fontSize: "18px" }}>
                {Number.isInteger(count)
                  ? count
                  : count.toFixed(2).replace(/\.00$/, "")}{" "}
                <span style={{}}>
                  (
                  {(
                    (count /
                      sortedCountries.reduce(
                        (acc, [_, count]) => acc + count,
                        0
                      )) *
                    100
                  )
                    .toFixed(1)
                    .replace(/\.0$/, "")}
                  %)
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatsCountTable;
