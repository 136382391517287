import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../Context/Color";

type ChcekBoxProps = {
  onCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  text: string;
  checkBoxID: string;
};

const CheckBoxBtn: React.FC<ChcekBoxProps> = ({
  onCheck,
  checked,
  text,
  checkBoxID,
}) => {
  const themeContext = useContext(ThemeContext);

  const onCheckBoxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckBoxStatus(event.target.checked);
    onCheck(event);
  };

  const [checkBoxStatus, setCheckBoxStatus] = useState(checked);

  return (
    <div>
      {" "}
      <input
        style={{ marginRight: "6px", cursor: "pointer" }}
        type="checkbox"
        id={checkBoxID}
        checked={checkBoxStatus}
        onChange={onCheckBoxClick}
      />
      <label
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{ cursor: "pointer", color: themeContext?.textColor }}
        htmlFor={checkBoxID}
      >
        {text}{" "}
      </label>
    </div>
  );
};

export default CheckBoxBtn;
