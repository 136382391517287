import { addDocumentToFirestore } from "./Crud";

export interface MessageData {
  text: string;
  isSentByUser: boolean;
  highlight: boolean;
  suggestionID: number;
  created: Date;
  DateTime?: Date;
  exampleText?: string;
}

// Adding the toJson method to the MessageData interface
export function toJson(message: MessageData): Record<string, any> {
  return {
    text: message.text,
    isSentByUser: message.isSentByUser,
    highlight: message.highlight,
    suggestionID: message.suggestionID,
    created: message.created.toISOString(), // Convert Date to ISO string
    DateTime: message.DateTime ? message.DateTime.toISOString() : new Date(), // Optional DateTime field
    exampleText: message.exampleText ? message.exampleText : "", // Optional exampleText field
  };
}

export const createMessages = async (
  msgs: MessageData[],
  userID: string
): Promise<string[]> => {
  if (msgs.length === 0) {
    throw new Error("No messages to upload");
  }

  const latestCreated = new Date();

  // Map through the messages and upload each one
  const uploadPromises = msgs.map((msg) => {
    const m = toJson(msg);

    //add a second to latest created
    latestCreated.setSeconds(latestCreated.getSeconds() + 1);
    m.created = latestCreated;

    return addDocumentToFirestore(m, `users/${userID}/chats`);
  });

  // Wait for all uploads to finish
  const results = await Promise.all(uploadPromises);

  // Return the results, which could be the document IDs or any other relevant data
  return results;
};
