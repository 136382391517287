import CloseDlgRow from "../../Molecules/CloseDlgRow/CloseDlgRow";
import SaveCancel from "../SaveCancel/SaveCancel";
import Platform from "../../../Util/Platform";
import { ThemeContext } from "../../../Context/Color";

import "./Style.css";
import { useContext, useEffect, useRef, useState } from "react";

type DialogProps = {
  children: React.ReactNode;
  backgroundColor?: string;
  closeBar: boolean;
  padding?: string;
  isMobile: boolean;
  onResolutionChange?: (mobile: boolean) => void;
  saveCancel: boolean;
  onClose: () => void;
  onSave?: () => void;
  onCancel?: () => void;
  widthDesktop?: string;
  widthMobile?: string;
  height?: string;
  saveLabel?: string;
  cancelLabel?: string;
};

const Dialog = (props: DialogProps) => {
  const themeContext = useContext(ThemeContext);

  const getWidth = (mobile: boolean) => {
    if (mobile && props.widthMobile) return props.widthMobile;
    else if (!mobile && props.widthDesktop) return props.widthDesktop;
    return "";
  };

  const { isMobileResolution } = Platform();
  const hasCalledInitially = useRef(false); // To track the initial call
  const [dlgWidth, setDlgWidth] = useState(getWidth(props.isMobile));

  useEffect(() => {
    let isMobileRes: boolean = false;

    if (!hasCalledInitially.current) {
      isMobileRes = props.isMobile;
      if (props.onResolutionChange) props.onResolutionChange(props.isMobile);
      hasCalledInitially.current = true; // Mark that the initial call has been made
    } else {
      isMobileRes = isMobileResolution;
      if (props.onResolutionChange)
        props.onResolutionChange(isMobileResolution);
    }

    setDlgWidth(getWidth(isMobileRes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileResolution, props]);

  return (
    <div
      style={{
        height: "100%",
        alignItems: "center",

        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          borderRadius: "15px",

          backgroundColor:
            props.backgroundColor || themeContext?.overlayBackground,
          flexDirection: "column",
          width: dlgWidth,
        }}
      >
        {props.closeBar && <CloseDlgRow onClose={props.onClose} />}

        <div
          className="dialog_scroll_area"
          style={{
            display: "flex",
            padding: props.padding || "10px",
            flexDirection: "column",
            overflow: "auto",
            maxHeight: "100%",
            height: "100%",
          }}
        >
          {props.children}
        </div>
        {props.saveCancel && props.onSave && props.onCancel && (
          <div
            style={{
              overflow: "none",
              display: "flex",
              padding: props.padding || "10px",
            }}
          >
            <SaveCancel
              saveLabel={props.saveLabel}
              cancelLabel={props.cancelLabel}
              onSave={props.onSave}
              onCancel={props.onCancel}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Dialog;
