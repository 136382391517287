import { useRef, useState } from "react";
import Button from "../../Atoms/Button/Button";
import EditBox from "../../Atoms/EditBox/EditBox";
import Header from "../../Atoms/Header/Header";
import { ThemeContext } from "../../../Context/Color";
import { useContext } from "react";

import "./Style.css";
import ErrorBox from "../../Atoms/ErrorBox/ErrorBox";
import Dialog from "../Dialog/Dialog";
import Icon from "../../Atoms/Icon/Icon";
import Spinner from "../../Atoms/Spinner/Spinner";

type EditDlgProps = {
  header: string;
  isMobile: boolean;
  maxLength?: number;
  headerSize: number;
  placeHolder: string;
  enterNewLine?: boolean;
  padding?: number;
  saveBtnText: string;
  initialValue?: string;
  cancelBtnText: string;
  allowEmptyValue: boolean;
  onInputChange?: (value: string) => void;
  onSave: (email: string) => void;
  onCancel: () => void;
  emailValidation?: boolean;
  expandVertical?: boolean;
};

const EditDlg = (props: EditDlgProps) => {
  const [error, setError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Invalid");
  const editRef = useRef<HTMLInputElement>(null);

  const themeContext = useContext(ThemeContext);

  const onLengthExceeded = () => {
    setErrorMsg(`Max length is ${props.maxLength}`);
    setError(true);
  };

  const onLengthOK = () => {
    setError(false);
  };

  const onEnter = () => {
    if (props.enterNewLine) return;

    onSave();
  };

  const onSave = () => {
    setSaving(true);
    let editValue: string = editRef.current?.value.trim()!;
    if (!props.allowEmptyValue && editValue === "") {
      setSaving(false);
      return;
    }
    if (
      props.emailValidation &&
      editRef.current != null &&
      !validateEmail(editValue)
    ) {
      setSaving(false);
      setError(true);
      setErrorMsg("🤔 Email is badly formatted");
      return;
    }

    if (editRef.current != null) props.onSave(editValue);
  };

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <Dialog
      closeBar={false}
      isMobile={props.isMobile}
      widthMobile="100%"
      widthDesktop="450px"
      backgroundColor={themeContext?.pageBackground.background}
      saveCancel={false}
      onClose={() => {}}
    >
      <Header text={props.header} fontSize={props.headerSize} />
      <EditBox
        type={"text"}
        placeholder={props.placeHolder}
        id={"ForgotEdit"}
        initialValue={props.initialValue}
        fontSize={18}
        disableBorder={true}
        maxLength={props.maxLength}
        onLengthOK={onLengthOK}
        padding={props.padding || 12}
        onLengthExceeded={onLengthExceeded}
        onEnterPress={props.enterNewLine ? undefined : onEnter}
        onInputChange={props.onInputChange ? props.onInputChange : undefined}
        focus={true}
        readOnly={false}
        expandVertical={props.expandVertical}
        EditRef={editRef}
      ></EditBox>
      {error && (
        <div style={{ marginTop: "8px" }}>
          <ErrorBox text={errorMsg} />
        </div>
      )}
      <div className="btns">
        {!saving && (
          <Button
            textButton={true}
            id={"cancel"}
            label={props.cancelBtnText}
            onClick={props.onCancel}
          ></Button>
        )}
        {!saving && (
          <Button
            textButton={false}
            iconComponent={<Icon sendIcon={true} />}
            id={"save"}
            label={props.saveBtnText}
            onClick={onSave}
          ></Button>
        )}
        {saving && <Spinner />}
      </div>
    </Dialog>
  );
};

export default EditDlg;
