import { useOverlay } from "../../../Context/OverlayProvider";
import Dialog from "../Dialog/Dialog";
import { useContext, useState } from "react";
import "./Style.css";
import { ThemeContext } from "../../../Context/Color";
import Icon from "../../Atoms/Icon/Icon";
import Button from "../../Atoms/Button/Button";
import ListRow from "../ListRow/ListRow";
import EditDlg from "../EditDlg/EditDlg";

type ReminderOptionsProps = {
  mobile: boolean;
  desktop: boolean;
  remindersEmail: string;
  email: boolean;
  userID: string;
  isMobile: boolean;
  onSave: (
    mobile: boolean,
    desktop: boolean,
    email: boolean,
    remindersEmail: string
  ) => void;
  onCancel: () => void;
};

const ReminderOptionsDlg = (props: ReminderOptionsProps) => {
  const themeContext = useContext(ThemeContext);

  const [remindersEmail, setRemindersEmail] = useState(props.remindersEmail);

  const { addOverlay, removeOverlay } = useOverlay();

  const [mobile, setMobile] = useState(props.mobile);
  const [desktop, setDesktop] = useState(props.desktop);

  const [email, setEmail] = useState(props.email);

  const onEditRemindersEmais = () => {
    addOverlay(
      <EditDlg
        isMobile={props.isMobile}
        header="Email for reminders"
        placeHolder=""
        saveBtnText="Save"
        allowEmptyValue={false}
        maxLength={150}
        expandVertical={true}
        initialValue={remindersEmail}
        cancelBtnText="Cancel"
        emailValidation={true}
        headerSize={24}
        onSave={(email: string) => {
          removeOverlay();
          setRemindersEmail(email);
        }}
        onCancel={() => {
          removeOverlay();
        }}
      />,
      {
        closeOnClickOutside: false,
        centerX: true,
        width: "100%",
        marginTop: 8,
      },
      true
    );
  };

  return (
    <Dialog
      widthDesktop="400px"
      widthMobile=""
      closeBar={true}
      isMobile={props.isMobile}
      onSave={() => {
        props.onSave(mobile, desktop, email, remindersEmail);
      }}
      saveCancel={true}
      onCancel={() => {
        removeOverlay();
      }}
      onClose={() => {
        props.onSave(mobile, desktop, email, remindersEmail);
      }}
    >
      <div
        style={{
          display: "flex",
          color: themeContext?.textColor,
          flexDirection: "column",
        }}
      >
        <div
          style={{
            fontFamily: "Calibri",
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "20px",
          }}
        >
          On what devices would you like to receive reminders?
        </div>
        <div style={{ display: "flex" }}>
          <div>
            If a device is enabled, it can still be disabled in the task level.
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
            fontSize: "18px",
          }}
        >
          <Button
            iconComponent={<Icon mobileIcon={true} />}
            id={"push_notification"}
            label={"Mobile Push Notifications"}
            textButton={true}
            checkBoxRight={true}
            checkBoxRightChecked={mobile}
            onClick={() => {
              setMobile(!mobile);
            }}
          />
          <Button
            iconComponent={<Icon desktopIcon={true} />}
            id={"desktop_push_notification"}
            label={"Desktop Push Notifications"}
            textButton={true}
            checkBoxRight={true}
            checkBoxRightChecked={desktop}
            onClick={() => {
              setDesktop(!desktop);
            }}
          />
          <Button
            iconComponent={<Icon emailIcon={true} />}
            checkBoxRightChecked={email}
            id={"email_notification"}
            label={"Email"}
            textButton={true}
            checkBoxRight={true}
            onClick={() => {
              setEmail(!email);
            }}
          />
          <ListRow
            title={"Email for reminders"}
            fontSize="16px"
            onClick={onEditRemindersEmais}
            trailingIcon={<Icon fontSize={18} editIcon={true} />}
            subTitle={remindersEmail}
            Icon={<Icon fontSize={18} userIcon={true} />}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ReminderOptionsDlg;
